/*********************************
 METRONIC SQUARE STYLE COMPONENTS 
*********************************/
/*--------------------------------------------------
    [TRANSITION]
----------------------------------------------------*/
/* Cubic Bezier Transition */
/*--------------------------------------------------
    [RGBA Opacity]
----------------------------------------------------*/
/***
Rounded corners reset
***/
/* Reset rounded corners for all elements */
div,
input,
select,
textarea,
span,
img,
table,
label,
td,
th,
p,
a,
button,
ul,
code,
pre,
li {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

/* except below bootstrap elements */
.img-circle {
  border-radius: 50% !important;
}

.img-rounded {
  border-radius: 6px !important;
}

/***
General reset
***/
/* Set default body */
body {
  color: #333333;
  font-family: "Open Sans", sans-serif;
  padding: 0px !important;
  margin: 0px !important;
  font-size: 13px;
  direction: ltr;
}

/*
 Internet Explorer 10 doesn't differentiate device width from viewport width, and thus doesn't 
 properly apply the media queries in Bootstrap's CSS. To address this, 
 you can optionally include the following CSS and JavaScript to work around this problem until Microsoft issues a fix.
*/
@-webkit-viewport {
  width: device-width;
}
@-moz-viewport {
  width: device-width;
}
@-ms-viewport {
  width: device-width;
}
@-o-viewport {
  width: device-width;
}
@viewport {
  width: device-width;
}
/*
 Internet Explorer 10 doesn't differentiate device width from viewport width, 
 and thus doesn't properly apply the media queries in Bootstrap's CSS. To address this, following CSS code applied 
*/
@-ms-viewport {
  width: auto !important;
}
.md-shadow-z-1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.18);
}

.md-shadow-z-1-i {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.18) !important;
}

.md-shadow-z-1-hover {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.14), 0 1px 2px rgba(0, 0, 0, 0.22);
}

.md-shadow-z-2 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.22);
}

.md-shadow-z-2-i {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.22) !important;
}

.md-shadow-z-2-hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.26);
}

.md-shadow-z-3 {
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.18), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.md-shadow-z-3-i {
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.18), 0 6px 6px rgba(0, 0, 0, 0.24) !important;
}

.md-shadow-z-3-hover {
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.22), 0 6px 6px rgba(0, 0, 0, 0.26);
}

.md-shadow-z-4 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.26), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.md-shadow-z-4-i {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.26), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
}

.md-shadow-z-5 {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.28), 0 15px 12px rgba(0, 0, 0, 0.22);
}

.md-shadow-z-5-i {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.28), 0 15px 12px rgba(0, 0, 0, 0.22) !important;
}

.md-shadow-none {
  box-shadow: none !important;
}

.md-click-circle {
  display: block;
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: scale(0);
  -moz-transform: scale(0);
  transform: scale(0);
}
.md-click-circle.md-click-animate {
  -webkit-animation: mdClickEffect 0.65s linear;
  -moz-animation: mdClickEffect 0.65s linear;
  animation: mdClickEffect 0.65s linear;
}

@-webkit-keyframes mdClickEffect {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
  }
  100% {
    opacity: 0 !important;
    -webkit-transform: scale(2.5);
  }
}
@-moz-keyframes mdClickEffect {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
  }
  100% {
    opacity: 0 !important;
    -moz-transform: scale(2.5);
  }
}
@keyframes mdClickEffect {
  0% {
    opacity: 1;
    -webkit-transform: scale(0);
  }
  100% {
    opacity: 0 !important;
    transform: scale(2.5);
  }
}
.form-group.form-md-line-input {
  position: relative;
  margin: 0 0 35px 0;
  padding-top: 20px;
}
.form-horizontal .form-group.form-md-line-input {
  padding-top: 10px;
  margin-bottom: 20px;
  margin: 0 -15px 20px -15px;
}
.form-horizontal .form-group.form-md-line-input > label {
  padding-top: 5px;
  font-size: 13px;
  color: #888888;
  opacity: 1 ;
  filter: alpha(opacity=100) ;
}
.form-group.form-md-line-input .form-control {
  background: none;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  color: #333;
  box-shadow: none;
  padding-left: 0;
  padding-right: 0;
  font-size: 16px;
}
.form-group.form-md-line-input .form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.form-group.form-md-line-input .form-control:-ms-input-placeholder {
  color: #999;
}
.form-group.form-md-line-input .form-control::-webkit-input-placeholder {
  color: #999;
}
.form-group.form-md-line-input .form-control.form-control-static {
  border-bottom: 0;
}
.form-group.form-md-line-input .form-control.input-sm {
  font-size: 14px;
  padding: 6px 0;
}
.form-group.form-md-line-input .form-control.input-lg {
  font-size: 20px;
  padding: 14px 0;
}
.form-group.form-md-line-input .form-control ~ label,
.form-group.form-md-line-input .form-control ~ .form-control-focus {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  pointer-events: none;
}
.form-horizontal .form-group.form-md-line-input .form-control ~ label, .form-horizontal
.form-group.form-md-line-input .form-control ~ .form-control-focus {
  width: auto;
  left: 15px;
  right: 15px;
}
.form-group.form-md-line-input .form-control ~ label:after,
.form-group.form-md-line-input .form-control ~ .form-control-focus:after {
  content: '';
  position: absolute;
  z-index: 5;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  width: 0;
  visibility: hidden;
  transition: 0.2s ease all;
}
.form-group.form-md-line-input .form-control ~ label {
  top: 0;
  margin-bottom: 0;
  font-size: 13px;
  color: #888888;
  opacity: 1 ;
  filter: alpha(opacity=100) ;
}
.form-group.form-md-line-input .form-control.edited:not([readonly]) ~ label,
.form-group.form-md-line-input .form-control.edited:not([readonly]) ~ .form-control-focus, .form-group.form-md-line-input .form-control:focus:not([readonly]) ~ label,
.form-group.form-md-line-input .form-control:focus:not([readonly]) ~ .form-control-focus, .form-group.form-md-line-input .form-control.focus:not([readonly]) ~ label,
.form-group.form-md-line-input .form-control.focus:not([readonly]) ~ .form-control-focus {
  color: #888888;
}
.form-group.form-md-line-input .form-control.edited:not([readonly]) ~ label:after,
.form-group.form-md-line-input .form-control.edited:not([readonly]) ~ .form-control-focus:after, .form-group.form-md-line-input .form-control:focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input .form-control:focus:not([readonly]) ~ .form-control-focus:after, .form-group.form-md-line-input .form-control.focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input .form-control.focus:not([readonly]) ~ .form-control-focus:after {
  visibility: visible;
  left: 0;
  width: 100%;
  background: #45B6AF;
}
.form-group.form-md-line-input .form-control.edited:not([readonly]) ~ label, .form-group.form-md-line-input .form-control:focus:not([readonly]) ~ label, .form-group.form-md-line-input .form-control.focus:not([readonly]) ~ label {
  opacity: 1 ;
  filter: alpha(opacity=100) ;
}
.form-group.form-md-line-input .form-control.edited:not([readonly]) ~ .help-block, .form-group.form-md-line-input .form-control:focus:not([readonly]) ~ .help-block, .form-group.form-md-line-input .form-control.focus:not([readonly]) ~ .help-block {
  color: #45B6AF;
  opacity: 1 ;
  filter: alpha(opacity=100) ;
}
.form-group.form-md-line-input .form-control[readonly], .form-group.form-md-line-input .form-control[disabled], fieldset[disabled] .form-group.form-md-line-input .form-control {
  background: none;
  cursor: not-allowed;
  border-bottom: 1px dashed #e5e5e5;
}
.form-group.form-md-line-input.form-md-floating-label .form-control ~ label {
  font-size: 16px;
  top: 25px;
  transition: 0.2s ease all;
  color: #999;
}
.form-group.form-md-line-input.form-md-floating-label .form-control[readonly] ~ label, .form-group.form-md-line-input.form-md-floating-label .form-control.edited ~ label, .form-group.form-md-line-input.form-md-floating-label .form-control.form-control-static ~ label, .form-group.form-md-line-input.form-md-floating-label .form-control:focus:not([readonly]) ~ label, .form-group.form-md-line-input.form-md-floating-label .form-control.focus:not([readonly]) ~ label {
  top: 0;
  font-size: 13px;
}
.form-group.form-md-line-input.form-md-floating-label .form-control.input-sm ~ label {
  font-size: 14px;
  top: 24px;
}
.form-group.form-md-line-input.form-md-floating-label .form-control.input-sm[readonly] ~ label, .form-group.form-md-line-input.form-md-floating-label .form-control.input-sm.form-control-static ~ label, .form-group.form-md-line-input.form-md-floating-label .form-control.input-sm.edited ~ label, .form-group.form-md-line-input.form-md-floating-label .form-control.input-sm:focus:not([readonly]) ~ label, .form-group.form-md-line-input.form-md-floating-label .form-control.input-sm.focus:not([readonly]) ~ label {
  top: 0;
  font-size: 13px;
}
.form-group.form-md-line-input.form-md-floating-label .form-control.input-lg ~ label {
  font-size: 20px;
  top: 30px;
}
.form-group.form-md-line-input.form-md-floating-label .form-control.input-lg[readonly] ~ label, .form-group.form-md-line-input.form-md-floating-label .form-control.input-lg.edited ~ label, .form-group.form-md-line-input.form-md-floating-label .form-control.input-lg.form-control-static ~ label, .form-group.form-md-line-input.form-md-floating-label .form-control.input-lg:focus:not([readonly]) ~ label, .form-group.form-md-line-input.form-md-floating-label .form-control.input-lg.focus:not([readonly]) ~ label {
  top: 0;
  font-size: 13px;
}
.form-group.form-md-line-input.form-md-floating-label .input-icon > label {
  padding-left: 34px;
}
.form-group.form-md-line-input.form-md-floating-label .input-icon.right > label {
  padding-left: 0;
  padding-right: 34px;
}
.form-group.form-md-line-input.form-md-floating-label .input-group.left-addon label {
  padding-left: 34px;
}
.form-group.form-md-line-input.form-md-floating-label .input-group.right-addon label {
  padding-right: 34px;
}
.form-group.form-md-line-input + .input-icon {
  padding-top: 0;
}
.form-group.form-md-line-input .help-block {
  position: absolute;
  margin: 2px 0 0 0;
  opacity: 0 ;
  filter: alpha(opacity=0) ;
  font-size: 13px;
}
.form-group.form-md-line-input > .input-icon > i {
  left: 0;
  bottom: 0;
  margin: 9px 2px 10px 10px;
  color: #888888;
}
.form-group.form-md-line-input > .input-icon.input-icon-lg > i {
  top: 6px;
}
.form-group.form-md-line-input > .input-icon.input-icon-sm > i {
  top: -1px;
}
.form-group.form-md-line-input > .input-icon .form-control {
  padding-left: 34px;
}
.form-group.form-md-line-input > .input-icon > label {
  margin-top: -20px;
}
.form-group.form-md-line-input > .input-icon.right .form-control {
  padding-left: 0;
  padding-right: 34px;
}
.form-group.form-md-line-input > .input-icon.right > i {
  left: auto;
  right: 8px;
  margin: 11px 2px 10px 10px;
}
.form-group.form-md-line-input + .input-group {
  padding-top: 0;
}
.form-group.form-md-line-input .input-group {
  padding-top: 0;
}
.form-group.form-md-line-input .input-group > label {
  margin-top: -20px;
}
.form-group.form-md-line-input .input-group .input-group-addon {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  background: none;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}
.form-group.form-md-line-input .input-group + .input-group-control {
  padding-top: 0;
}
.form-group.form-md-line-input .input-group .input-group-control {
  padding-top: 0;
  position: relative;
  display: table-cell;
  vertical-align: bottom;
}
.form-group.form-md-line-input .input-group .input-group-control > label {
  margin-top: -20px;
}
.form-group.form-md-line-input .input-group .input-group-btn .btn {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}
.form-group.form-md-line-input .input-group .input-group-btn.btn-left .btn {
  margin-right: 10px;
}
.form-group.form-md-line-input .input-group .input-group-btn.btn-right .btn {
  margin-left: 10px;
}
.form-group.form-md-line-input .input-group .help-block {
  margin-top: 35px;
}
.form-group.form-md-line-input .input-group.input-group-sm .help-block {
  margin-top: 30px;
}
.form-group.form-md-line-input .input-group.input-group-lg .help-block {
  margin-top: 47px;
}
.form-group.form-md-line-input.has-success .form-control.edited:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-success .form-control.edited:not([readonly]) ~ .form-control-focus:after, .form-group.form-md-line-input.has-success .form-control.form-control-static ~ label:after,
.form-group.form-md-line-input.has-success .form-control.form-control-static ~ .form-control-focus:after, .form-group.form-md-line-input.has-success .form-control:focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-success .form-control:focus:not([readonly]) ~ .form-control-focus:after, .form-group.form-md-line-input.has-success .form-control.focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-success .form-control.focus:not([readonly]) ~ .form-control-focus:after {
  background: #3c763d;
}
.form-group.form-md-line-input.has-success .form-control.edited:not([readonly]) ~ label, .form-group.form-md-line-input.has-success .form-control.form-control-static ~ label, .form-group.form-md-line-input.has-success .form-control:focus:not([readonly]) ~ label, .form-group.form-md-line-input.has-success .form-control.focus:not([readonly]) ~ label {
  color: #3c763d;
}
.form-group.form-md-line-input.has-success .form-control.edited:not([readonly]) ~ i, .form-group.form-md-line-input.has-success .form-control.form-control-static ~ i, .form-group.form-md-line-input.has-success .form-control:focus:not([readonly]) ~ i, .form-group.form-md-line-input.has-success .form-control.focus:not([readonly]) ~ i {
  color: #3c763d;
}
.form-group.form-md-line-input.has-success .form-control.edited:not([readonly]) ~ .help-block, .form-group.form-md-line-input.has-success .form-control.form-control-static ~ .help-block, .form-group.form-md-line-input.has-success .form-control:focus:not([readonly]) ~ .help-block, .form-group.form-md-line-input.has-success .form-control.focus:not([readonly]) ~ .help-block {
  color: #3c763d;
}
.form-group.form-md-line-input.has-success .input-group-addon {
  color: #3c763d;
}
.form-horizontal .form-group.form-md-line-input.has-success > label {
  color: #3c763d;
}
.form-group.form-md-line-input.has-warning .form-control.edited:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-warning .form-control.edited:not([readonly]) ~ .form-control-focus:after, .form-group.form-md-line-input.has-warning .form-control.form-control-static ~ label:after,
.form-group.form-md-line-input.has-warning .form-control.form-control-static ~ .form-control-focus:after, .form-group.form-md-line-input.has-warning .form-control:focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-warning .form-control:focus:not([readonly]) ~ .form-control-focus:after, .form-group.form-md-line-input.has-warning .form-control.focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-warning .form-control.focus:not([readonly]) ~ .form-control-focus:after {
  background: #8a6d3b;
}
.form-group.form-md-line-input.has-warning .form-control.edited:not([readonly]) ~ label, .form-group.form-md-line-input.has-warning .form-control.form-control-static ~ label, .form-group.form-md-line-input.has-warning .form-control:focus:not([readonly]) ~ label, .form-group.form-md-line-input.has-warning .form-control.focus:not([readonly]) ~ label {
  color: #8a6d3b;
}
.form-group.form-md-line-input.has-warning .form-control.edited:not([readonly]) ~ i, .form-group.form-md-line-input.has-warning .form-control.form-control-static ~ i, .form-group.form-md-line-input.has-warning .form-control:focus:not([readonly]) ~ i, .form-group.form-md-line-input.has-warning .form-control.focus:not([readonly]) ~ i {
  color: #8a6d3b;
}
.form-group.form-md-line-input.has-warning .form-control.edited:not([readonly]) ~ .help-block, .form-group.form-md-line-input.has-warning .form-control.form-control-static ~ .help-block, .form-group.form-md-line-input.has-warning .form-control:focus:not([readonly]) ~ .help-block, .form-group.form-md-line-input.has-warning .form-control.focus:not([readonly]) ~ .help-block {
  color: #8a6d3b;
}
.form-group.form-md-line-input.has-warning .input-group-addon {
  color: #8a6d3b;
}
.form-horizontal .form-group.form-md-line-input.has-warning > label {
  color: #8a6d3b;
}
.form-group.form-md-line-input.has-error .form-control.edited:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-error .form-control.edited:not([readonly]) ~ .form-control-focus:after, .form-group.form-md-line-input.has-error .form-control.form-control-static ~ label:after,
.form-group.form-md-line-input.has-error .form-control.form-control-static ~ .form-control-focus:after, .form-group.form-md-line-input.has-error .form-control:focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-error .form-control:focus:not([readonly]) ~ .form-control-focus:after, .form-group.form-md-line-input.has-error .form-control.focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-error .form-control.focus:not([readonly]) ~ .form-control-focus:after {
  background: #a94442;
}
.form-group.form-md-line-input.has-error .form-control.edited:not([readonly]) ~ label, .form-group.form-md-line-input.has-error .form-control.form-control-static ~ label, .form-group.form-md-line-input.has-error .form-control:focus:not([readonly]) ~ label, .form-group.form-md-line-input.has-error .form-control.focus:not([readonly]) ~ label {
  color: #a94442;
}
.form-group.form-md-line-input.has-error .form-control.edited:not([readonly]) ~ i, .form-group.form-md-line-input.has-error .form-control.form-control-static ~ i, .form-group.form-md-line-input.has-error .form-control:focus:not([readonly]) ~ i, .form-group.form-md-line-input.has-error .form-control.focus:not([readonly]) ~ i {
  color: #a94442;
}
.form-group.form-md-line-input.has-error .form-control.edited:not([readonly]) ~ .help-block, .form-group.form-md-line-input.has-error .form-control.form-control-static ~ .help-block, .form-group.form-md-line-input.has-error .form-control:focus:not([readonly]) ~ .help-block, .form-group.form-md-line-input.has-error .form-control.focus:not([readonly]) ~ .help-block {
  color: #a94442;
}
.form-group.form-md-line-input.has-error .input-group-addon {
  color: #a94442;
}
.form-horizontal .form-group.form-md-line-input.has-error > label {
  color: #a94442;
}
.form-group.form-md-line-input.has-info .form-control.edited:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-info .form-control.edited:not([readonly]) ~ .form-control-focus:after, .form-group.form-md-line-input.has-info .form-control.form-control-static ~ label:after,
.form-group.form-md-line-input.has-info .form-control.form-control-static ~ .form-control-focus:after, .form-group.form-md-line-input.has-info .form-control:focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-info .form-control:focus:not([readonly]) ~ .form-control-focus:after, .form-group.form-md-line-input.has-info .form-control.focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-info .form-control.focus:not([readonly]) ~ .form-control-focus:after {
  background: #31708f;
}
.form-group.form-md-line-input.has-info .form-control.edited:not([readonly]) ~ label, .form-group.form-md-line-input.has-info .form-control.form-control-static ~ label, .form-group.form-md-line-input.has-info .form-control:focus:not([readonly]) ~ label, .form-group.form-md-line-input.has-info .form-control.focus:not([readonly]) ~ label {
  color: #31708f;
}
.form-group.form-md-line-input.has-info .form-control.edited:not([readonly]) ~ i, .form-group.form-md-line-input.has-info .form-control.form-control-static ~ i, .form-group.form-md-line-input.has-info .form-control:focus:not([readonly]) ~ i, .form-group.form-md-line-input.has-info .form-control.focus:not([readonly]) ~ i {
  color: #31708f;
}
.form-group.form-md-line-input.has-info .form-control.edited:not([readonly]) ~ .help-block, .form-group.form-md-line-input.has-info .form-control.form-control-static ~ .help-block, .form-group.form-md-line-input.has-info .form-control:focus:not([readonly]) ~ .help-block, .form-group.form-md-line-input.has-info .form-control.focus:not([readonly]) ~ .help-block {
  color: #31708f;
}
.form-group.form-md-line-input.has-info .input-group-addon {
  color: #31708f;
}
.form-horizontal .form-group.form-md-line-input.has-info > label {
  color: #31708f;
}

.form-inline .form-md-line-input {
  margin: 0;
  padding-top: 0;
  margin-right: 20px;
}
.form-inline .form-md-line-input > .input-icon {
  padding: 0;
}

.form-horizontal .form-group.form-md-line-input .input-icon .form-control {
  padding-left: 33px;
}
.form-horizontal .form-group.form-md-line-input .input-icon > i {
  top: 0;
}
.form-horizontal .form-group.form-md-line-input .input-icon.right .form-control {
  padding-left: 0;
  padding-right: 33px;
}
.form-horizontal .form-group.form-md-line-input .input-icon > .form-control-focus {
  left: 0 !important;
  right: 0 !important;
}
.form-horizontal .form-group.form-md-line-input .input-group {
  padding-top: 0;
}
.form-horizontal .form-group.form-md-line-input .input-group > .input-group-btn .btn {
  margin-bottom: 0 !important;
}
.form-horizontal .form-group.form-md-line-input .input-group > .input-group-control {
  padding-top: 0;
}
.form-horizontal .form-group.form-md-line-input .input-group > .input-group-control > .form-control-focus {
  left: 0 !important;
  right: 0 !important;
}
.form-horizontal .form-group.form-md-line-input .input-group > .form-control-focus {
  left: 0 !important;
  right: 0 !important;
}

.md-checkbox {
  position: relative;
  /* handling click events */
  /* when checkbox is checked */
}
.md-checkbox.md-checkbox-inline {
  display: inline-block;
}
.form-inline .md-checkbox.md-checkbox-inline {
  margin-right: 20px;
  top: 3px;
}
.md-checkbox input[type=checkbox] {
  visibility: hidden;
  position: absolute;
}
.md-checkbox label {
  cursor: pointer;
  padding-left: 30px;
}
.md-checkbox label > span {
  display: block;
  position: absolute;
  left: 0;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.md-checkbox label > span.inc {
  background: #fff;
  left: -20px;
  top: -20px;
  height: 60px;
  width: 60px;
  opacity: 0;
  border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
}
.md-checkbox label > .box {
  top: 0px;
  border: 2px solid #666;
  height: 20px;
  width: 20px;
  z-index: 5;
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.md-checkbox label > .check {
  top: -4px;
  left: 6px;
  width: 10px;
  height: 20px;
  border: 2px solid #45B6AF;
  border-top: none;
  border-left: none;
  opacity: 0;
  z-index: 5;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition-delay: 0.3s;
  -moz-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.md-checkbox label > span.inc {
  -webkit-animation: growCircle 0.3s ease;
  -moz-animation: growCircle 0.3s ease;
  animation: growCircle 0.3s ease;
}
.md-checkbox input[type=checkbox]:checked ~ label > .box {
  opacity: 0;
  -webkit-transform: scale(0) rotate(-180deg);
  -moz-transform: scale(0) rotate(-180deg);
  transform: scale(0) rotate(-180deg);
}
.md-checkbox input[type=checkbox]:checked ~ label > .check {
  opacity: 1;
  -webkit-transform: scale(1) rotate(45deg);
  -moz-transform: scale(1) rotate(45deg);
  transform: scale(1) rotate(45deg);
}
.md-checkbox input[type=checkbox]:disabled ~ label,
.md-checkbox input[type=checkbox][disabled] ~ label {
  cursor: not-allowed;
  opacity: 0.7 ;
  filter: alpha(opacity=70) ;
}
.md-checkbox input[type=checkbox]:disabled ~ label > .box,
.md-checkbox input[type=checkbox][disabled] ~ label > .box {
  cursor: not-allowed;
  opacity: 0.7 ;
  filter: alpha(opacity=70) ;
}
.md-checkbox input[type=checkbox]:disabled:checked ~ label > .check,
.md-checkbox input[type=checkbox][disabled]:checked ~ label > .check {
  cursor: not-allowed;
  opacity: 0.7 ;
  filter: alpha(opacity=70) ;
}
.md-checkbox.has-error label {
  color: #F3565D;
}
.md-checkbox.has-error label > .box {
  border-color: #F3565D;
}
.md-checkbox.has-error label > .check {
  border-color: #F3565D;
}
.md-checkbox.has-info label {
  color: #89C4F4;
}
.md-checkbox.has-info label > .box {
  border-color: #89C4F4;
}
.md-checkbox.has-info label > .check {
  border-color: #89C4F4;
}
.md-checkbox.has-success label {
  color: #45B6AF;
}
.md-checkbox.has-success label > .box {
  border-color: #45B6AF;
}
.md-checkbox.has-success label > .check {
  border-color: #45B6AF;
}
.md-checkbox.has-warning label {
  color: #dfba49;
}
.md-checkbox.has-warning label > .box {
  border-color: #dfba49;
}
.md-checkbox.has-warning label > .check {
  border-color: #dfba49;
}

.form-md-checkboxes > label {
  font-size: 13px;
  color: #888888;
  opacity: 1 ;
  filter: alpha(opacity=100) ;
}
.form-md-checkboxes.has-error > label {
  color: #F3565D;
}
.form-md-checkboxes.has-info > label {
  color: #89C4F4;
}
.form-md-checkboxes.has-success > label {
  color: #45B6AF;
}
.form-md-checkboxes.has-warning > label {
  color: #dfba49;
}

.md-checkbox-list {
  margin: 10px 0;
}
.form-horizontal .md-checkbox-list {
  margin-top: 0px;
}
.md-checkbox-list .md-checkbox {
  display: block;
  margin-bottom: 10px;
}

.md-checkbox-inline {
  margin: 10px 0;
}
.form-horizontal .md-checkbox-inline {
  margin-top: 5px;
}
.md-checkbox-inline .md-checkbox {
  display: inline-block;
  margin-right: 20px;
}
.md-checkbox-inline .md-checkbox:last-child {
  margin-right: 0;
}

/* bubble animation */
@-webkit-keyframes growCircle {
  0%, 100% {
    -webkit-transform: scale(0);
    opacity: 1;
  }
  70% {
    background: #eee;
    -webkit-transform: scale(1.25);
  }
}
@-moz-keyframes growCircle {
  0%, 100% {
    -moz-transform: scale(0);
    opacity: 1;
  }
  70% {
    background: #eee;
    -moz-transform: scale(1.25);
  }
}
@keyframes growCircle {
  0%, 100% {
    transform: scale(0);
    opacity: 1;
  }
  70% {
    background: #eee;
    transform: scale(1.25);
  }
}
.md-radio {
  position: relative;
  /* handling click events */
  /* when radio is checked */
}
.md-radio input[type=radio] {
  visibility: hidden;
  position: absolute;
}
.md-radio label {
  cursor: pointer;
  padding-left: 30px;
}
.md-radio label > span {
  display: block;
  position: absolute;
  left: 0;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.md-radio label > span.inc {
  background: #fff;
  left: -20px;
  top: -20px;
  height: 60px;
  width: 60px;
  opacity: 0;
  border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
}
.md-radio label > .box {
  top: 0px;
  border: 2px solid #666;
  height: 20px;
  width: 20px;
  border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  z-index: 5;
}
.md-radio label > .check {
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  background: #45B6AF;
  opacity: 0;
  z-index: 6;
  border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  transform: scale(0);
}
.md-radio label > span.inc {
  -webkit-animation: growCircleRadio 0.3s ease;
  -moz-animation: growCircleRadio 0.3s ease;
  animation: growCircleRadio 0.3s ease;
}
.md-radio input[type=radio]:checked ~ label > .check {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}
.md-radio input[type=radio]:disabled ~ label,
.md-radio input[type=radio][disabled] ~ label {
  cursor: not-allowed;
  opacity: 0.7 ;
  filter: alpha(opacity=70) ;
}
.md-radio input[type=radio]:disabled ~ label > .box,
.md-radio input[type=radio][disabled] ~ label > .box {
  cursor: not-allowed;
  opacity: 0.7 ;
  filter: alpha(opacity=70) ;
}
.md-radio input[type=radio]:disabled:checked ~ label > .check,
.md-radio input[type=radio][disabled]:checked ~ label > .check {
  cursor: not-allowed;
  opacity: 0.7 ;
  filter: alpha(opacity=70) ;
}
.md-radio.has-error label {
  color: #F3565D;
}
.md-radio.has-error label > .box {
  border-color: #F3565D;
}
.md-radio.has-error label > .check {
  background: #F3565D;
}
.md-radio.has-info label {
  color: #89C4F4;
}
.md-radio.has-info label > .box {
  border-color: #89C4F4;
}
.md-radio.has-info label > .check {
  background: #89C4F4;
}
.md-radio.has-success label {
  color: #45B6AF;
}
.md-radio.has-success label > .box {
  border-color: #45B6AF;
}
.md-radio.has-success label > .check {
  background: #45B6AF;
}
.md-radio.has-warning label {
  color: #dfba49;
}
.md-radio.has-warning label > .box {
  border-color: #dfba49;
}
.md-radio.has-warning label > .check {
  background: #dfba49;
}

.form-md-radios > label {
  font-size: 13px;
  color: #888888;
  opacity: 1 ;
  filter: alpha(opacity=100) ;
}
.form-md-radios.has-error > label {
  color: #F3565D;
}
.form-md-radios.has-info > label {
  color: #89C4F4;
}
.form-md-radios.has-success > label {
  color: #45B6AF;
}
.form-md-radios.has-warning > label {
  color: #dfba49;
}

.md-radio-list {
  margin: 10px 0;
}
.form-horizontal .md-radio-list {
  margin-top: 0px;
}
.md-radio-list .md-radio {
  display: block;
  margin-bottom: 10px;
}

.md-radio-inline {
  margin: 10px 0;
}
.form-horizontal .md-radio-inline {
  margin-top: 5px;
}
.md-radio-inline .md-radio {
  display: inline-block;
  margin-right: 20px;
}
.md-radio-inline .md-radio:last-child {
  margin-right: 0;
}

/* bubble animation */
@-webkit-keyframes growCircleRadio {
  0%, 100% {
    -webkit-transform: scale(0);
    opacity: 1;
  }
  70% {
    background: #eee;
    -webkit-transform: scale(1.25);
  }
}
@-moz-keyframes growCircleRadio {
  0%, 100% {
    -moz-transform: scale(0);
    opacity: 1;
  }
  70% {
    background: #eee;
    -moz-transform: scale(1.25);
  }
}
@keyframes growCircleRadio {
  0%, 100% {
    transform: scale(0);
    opacity: 1;
  }
  70% {
    background: #eee;
    transform: scale(1.25);
  }
}
/***
General typography
***/
/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
}

h1 {
  font-size: 33px;
}

h2 {
  font-size: 27px;
}

h3 {
  font-size: 23px;
}

h4 {
  font-size: 17px;
}

h5 {
  font-size: 13px;
}

h6 {
  font-size: 12px;
}

/* Headings helper text */
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  color: #444444;
}

/* Block headings */
h1.block,
h2.block,
h3.block,
h4.block,
h5.block,
h6.block {
  padding-top: 10px;
  padding-bottom: 10px;
}

/* Links */
a {
  text-shadow: none;
  color: #5b9bd1;
}
a:hover {
  color: #3175af;
}
a:focus, a:hover, a:active {
  outline: 0;
}

/* Horizontal break */
hr {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #eee;
  border-bottom: 0;
}

/* Unstyled List */
.list-unstyled li > .list-unstyled {
  margin-left: 25px;
}

/* Code */
code {
  border: 1px solid #e1e1e1;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

/* Disabled Navigation Link */
.disabled-link .disable-target {
  opacity: 0.5 !important;
  filter: alpha(opacity=50) !important;
}
.disabled-link .disable-target:hover {
  cursor: not-allowed !important;
}
.disabled-link:hover {
  cursor: not-allowed !important;
}

/***
Utilities
***/
/* Links */
a:hover {
  cursor: pointer;
}

/* Primary Link */
.primary-link {
  color: #65A0D0;
  font-weight: 600;
}
.primary-link:hover {
  color: #5194ca;
}

/* Rounded Element */
.rounded-2 {
  border-radius: 2px !important;
}

.rounded-3 {
  border-radius: 3px !important;
}

.rounded-4 {
  border-radius: 4px !important;
}

/* Circle Element */
.circle {
  border-radius: 25px !important;
}

.circle-right {
  border-radius: 0 25px 25px 0 !important;
}

.circle-left {
  border-radius: 25px 0 0 25px !important;
}

.circle-bottom {
  border-radius: 0 0 25px 25px !important;
}

.circle-top {
  border-radius: 25px 25px 0 0 !important;
}

/* General utilities */
.display-none,
.display-hide {
  display: none;
}

.hidden {
  display: none !important;
}

.primary-font {
  font-family: "Open Sans", sans-serif !important;
}

.bold {
  font-weight: 700 !important;
}

.thin {
  font-weight: 300 !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.fix-margin {
  margin-left: 0px !important;
}

.border {
  border: 1px solid red;
}

.font-hg {
  font-size: 23px;
}

.font-lg {
  font-size: 18px;
}

.font-md {
  font-size: 14px;
}

.font-sm {
  font-size: 13px;
}

.font-xs {
  font-size: 11px;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.text-align-reverse {
  text-align: right;
}

/* Margin and padding utilities */
.no-space {
  margin: 0px !important;
  padding: 0px !important;
}

.no-margin {
  margin: 0;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-15 {
  margin-top: 15px !important;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-bottom-25 {
  margin-bottom: 25px !important;
}

.margin-bottom-30 {
  margin-bottom: 30px !important;
}

.margin-bottom-40 {
  margin-bottom: 40px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

/* IE8 & IE9 mode utilities */
.visible-ie8 {
  display: none;
}

.ie8 .visible-ie8 {
  display: inherit !important;
}

.visible-ie9 {
  display: none;
}

.ie9 .visible-ie9 {
  display: inherit !important;
}

.hidden-ie8 {
  display: inherit;
}

.ie8 .hidden-ie8 {
  display: none !important;
}

.hidden-ie9 {
  display: inherit;
}

.ie9 .hidden-ie9 {
  display: none !important;
}

/***
Responsive Utils
***/
@media (max-width: 1024px) {
  .hidden-1024 {
    display: none;
  }
}
@media (max-width: 480px) {
  .hidden-480 {
    display: none;
  }
}
@media (max-width: 320px) {
  .hidden-320 {
    display: none;
  }
}
/***
Demo Utils
***/
.scrollspy-example {
  position: relative;
  height: 200px;
  margin-top: 10px;
  overflow: auto;
}

.util-btn-margin-bottom-5 .btn {
  margin-bottom: 5px !important;
}

.util-btn-group-margin-bottom-5 .btn-group {
  margin-bottom: 5px !important;
}

/***
Users
***/
.user-info {
  margin-bottom: 10px !important;
}
.user-info img {
  float: left;
  margin-right: 5px;
}
.user-info .details {
  display: inline-block;
}
.user-info .label {
  font-weight: 300;
  font-size: 11px;
}

/***
Top News Blocks(new in v1.2.2) 
***/
.top-news {
  color: #fff;
  margin: 8px 0;
}
.top-news em {
  font-size: 13px;
  margin-bottom: 8px;
  margin-bottom: 0;
  font-style: normal;
  display: block;
}
.top-news em i {
  font-size: 14px;
}
.top-news span {
  font-size: 18px;
  margin-bottom: 5px;
  display: block;
  text-align: left;
}
.top-news a {
  display: block;
  text-align: left;
  padding: 10px;
  position: relative;
  margin-bottom: 10px;
}
.top-news a .top-news-icon {
  right: 8px;
  bottom: 15px;
  opacity: 0.3;
  font-size: 35px;
  position: absolute;
  filter: alpha(opacity=30);
  /*For IE8*/
}
.top-news .btn + .btn {
  margin-left: 0;
}

/***
Block Images(new in v1.2.2)
***/
.blog-images {
  margin-bottom: 0;
}
.blog-images li {
  padding: 0;
  margin: 0;
  display: inline;
}
.blog-images li a:hover {
  text-decoration: none;
}
.blog-images li img {
  width: 50px;
  height: 50px;
  opacity: 0.6;
  margin: 0 2px 8px;
}
.blog-images li img:hover {
  opacity: 1;
  box-shadow: 0 0 0 4px #72c02c;
  transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  -webkit-transition: all 0.4s ease-in-out 0s;
}

/***
Sidebar Tags
***/
.sidebar-tags li {
  padding: 0;
}
.sidebar-tags li a {
  color: #555;
  font-size: 12px;
  padding: 3px 5px;
  background: #f7f7f7;
  margin: 0 2px 5px 0;
  display: inline-block;
}
.sidebar-tags li a:hover, .sidebar-tags li a:hover i {
  background: #EEE;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.sidebar-tags li a i {
  color: #777;
}

/***
Custom vertical inline menu
***/
.ver-inline-menu {
  padding: 0;
  margin: 0;
  list-style: none;
}
.ver-inline-menu li {
  position: relative;
  margin-bottom: 1px;
}
.ver-inline-menu li i {
  width: 37px;
  height: 37px;
  display: inline-block;
  color: #b9cbd5;
  font-size: 15px;
  padding: 12px 10px 10px 8px;
  margin: 0 8px 0 0;
  text-align: center;
  background: #e0eaf0 !important;
}
.ver-inline-menu li a {
  font-size: 14px;
  font-weight: 300;
  color: #557386;
  display: block;
  background: #f0f6fa;
  border-left: solid 2px #c4d5df;
}
.ver-inline-menu li:hover a {
  background: #e0eaf0;
  text-decoration: none;
}
.ver-inline-menu li:hover i {
  color: #fff;
  background: #c4d5df !important;
}
.ver-inline-menu li.active a {
  border-left: solid 2px #0c91e5;
}
.ver-inline-menu li.active i {
  background: #0c91e5 !important;
}
.ver-inline-menu li.active a, .ver-inline-menu li.active i {
  color: #fff;
  background: #169ef4;
  text-decoration: none;
}
.ver-inline-menu li.active a, .ver-inline-menu li:hover a {
  font-size: 14px;
}
.ver-inline-menu li.active:after {
  content: '';
  display: inline-block;
  border-bottom: 6px solid transparent;
  border-top: 6px solid transparent;
  border-left: 6px solid #169ef4;
  position: absolute;
  top: 12px;
  right: -5px;
}

@media (max-width: 767px) {
  .ver-inline-menu > li.active:after {
    display: none;
  }
}
/***
Separated List
***/
.list-separated {
  margin-top: 10px;
  margin-bottom: 15px;
}
.list-separated > div:last-child {
  border-right: 0;
}
@media (max-width: 767px) {
  .list-separated {
    /* 767px */
  }
  .list-separated > div {
    margin-bottom: 20px;
  }
}

/***
Number & Chart Stats
***/
.number-stats {
  margin: 10px 0;
}
.number-stats .stat-number {
  display: inline-block;
  margin: 0 5px;
}
.number-stats .stat-number .title {
  font-size: 13px;
  margin-bottom: 3px;
  color: #B8C3C7;
}
.number-stats .stat-number .number {
  font-size: 27px;
  line-height: 27px;
  color: #7D8C9D;
}
.number-stats .stat-chart {
  display: inline-block;
  margin: 0 5px;
}
.number-stats > div {
  border-right: 1px solid #f5f5f5;
}
.number-stats > div:last-child {
  border-right: 0;
}
.number-stats .stat-left {
  float: right;
}
.number-stats .stat-left .stat-number {
  float: right;
  text-align: right;
}
.number-stats .stat-left .stat-chart {
  float: right;
}
.number-stats .stat-right {
  float: left !important;
}
.number-stats .stat-right .stat-number {
  float: left;
  text-align: left;
}
.number-stats .stat-right .stat-chart {
  float: left;
}
.number-stats .stat-number {
  float: left;
  text-align: left;
}
.number-stats .stat-chart {
  float: left;
}

/***
General User Record Listing
***/
.general-item-list > .item {
  padding: 10px 0;
  border-bottom: 1px solid #F1F4F7;
}
.general-item-list > .item:last-child {
  border-bottom: 0;
}
.general-item-list > .item > .item-head {
  margin-bottom: 5px;
}
.general-item-list > .item > .item-head:before, .general-item-list > .item > .item-head:after {
  content: " ";
  display: table;
}
.general-item-list > .item > .item-head:after {
  clear: both;
}
.general-item-list > .item > .item-head > .item-details {
  display: inline-block;
  float: left;
}
.general-item-list > .item > .item-head > .item-details > .item-pic {
  height: 35px;
  margin-right: 10px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}
.general-item-list > .item > .item-head > .item-details > .item-name {
  display: inline-block;
  margin-right: 10px;
}
.general-item-list > .item > .item-head > .item-details > .item-label {
  color: #C0C9CC;
}
.general-item-list > .item > .item-head > .item-status {
  color: #C0C9CC;
  top: 10px;
  position: relative;
  display: inline-block;
  float: right;
}
.general-item-list > .item > .item-head > .item-status > .badge {
  margin-top: -2px;
}
.general-item-list > .item > .item-body {
  color: #96a5aa;
}

/***
File dropzone
***/
.file-drop-zone {
  border: 2px dashed #ddd;
  padding: 30px;
  text-align: center;
}
.file-drop-zone.file-drop-zone-over {
  border-color: #aaa;
}

/***
Fontawesome Icons
***/
[class^="fa-"]:not(.fa-stack),
[class^="glyphicon-"],
[class^="icon-"],
[class*=" fa-"]:not(.fa-stack),
[class*=" glyphicon-"],
[class*=" icon-"] {
  display: inline-block;
  font-size: 14px;
  *margin-right: .3em;
  line-height: 14px;
  -webkit-font-smoothing: antialiased;
}

/* Make font awesome icons fixed width */
li [class^="fa-"],
li [class^="glyphicon-"],
li [class^="icon-"],
li [class*=" fa-"],
li [class*=" glyphicon-"],
li [class*=" icon-"] {
  display: inline-block;
  width: 1.25em;
  text-align: center;
}

li [class^="glyphicon-"],
li [class*=" glyphicon-"] {
  top: 2px;
}

li [class^="icon-"],
li [class*=" icon-"] {
  top: 1px;
  position: relative;
}

li [class^="fa-"].icon-large,
li [class^="glyphicon-"].icon-large,
li [class^="icon-"].icon-large,
li [class*=" fa-"].icon-large,
li [class*=" glyphicon-"].icon-large,
li [class*=" icon-"].icon-large {
  /* increased font size for icon-large */
  width: 1.5625em;
}

/* Icon sizing */
.fa-sm:not(.fa-stack),
.icon-sm {
  font-size: 12px;
}

.fa-lg:not(.fa-stack),
.icon-lg {
  font-size: 16px !important;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

/* Icon coloring */
.icon-state-default {
  color: #c6c6c6;
}

.icon-state-success {
  color: #45B6AF;
}

.icon-state-info {
  color: #89C4F4;
}

.icon-state-warning {
  color: #dfba49;
}

.icon-state-danger {
  color: #F3565D;
}

/***
Font Awesome 4.x Demo
***/
.fa-item {
  font-size: 14px;
  padding: 10px 10px 10px 20px;
}
.fa-item i {
  font-size: 16px;
  display: inline-block;
  width: 20px;
  color: #333;
}
.fa-item:hover {
  cursor: pointer;
  background: #eee;
}

/***
Simple Line Icons Demo
***/
.simplelineicons-demo .item-box {
  display: inline-block;
  font-size: 16px;
  margin: 0 -0.22em 1em 0;
  padding-left: 1em;
  width: 100%;
}
.simplelineicons-demo .item-box .item {
  background-color: #fff;
  color: #33383e;
  border-radius: 8px;
  display: inline-block;
  padding: 10px;
  width: 100%;
}
.simplelineicons-demo .item-box .item span {
  font-size: 22px;
}

@media only screen and (min-width: 768px) {
  .simplelineicons-demo .item-box {
    width: 33.333%;
  }
}
/*** 
Glyphicons Demo
***/
.glyphicons-demo ul {
  padding-left: 0;
  padding-bottom: 1px;
  margin-bottom: 20px;
  list-style: none;
  overflow: hidden;
}

.bs-glyphicon-class {
  text-align: center;
}

.bs-glyphicons {
  padding-left: 0;
  padding-bottom: 1px;
  margin-bottom: 20px;
  list-style: none;
  overflow: hidden;
}

.glyphicons-demo ul li {
  float: left;
  width: 25%;
  height: 115px;
  padding: 10px;
  margin: 0 -1px -1px 0;
  font-size: 14px;
  line-height: 1.4;
  text-align: center;
  border: 1px solid #ddd;
}

.glyphicons-demo .glyphicon {
  display: block;
  margin: 5px auto 10px;
  font-size: 24px;
  color: #444;
}

.glyphicons-demo ul li:hover {
  background-color: rgba(86, 61, 124, 0.1);
}

@media (min-width: 768px) {
  .glyphicons-demo ul li {
    width: 12.5%;
  }
}
/***
Customized Bootstrap Alerts
***/
.alert {
  border-width: 1px;
}
.alert.alert-borderless {
  border: 0;
}

/***
Bootstrap Alerts
***/
.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
}
.alert-success hr {
  border-top-color: #c9e2b3;
}
.alert-success .alert-link {
  color: #2b542c;
}

.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f;
}
.alert-info hr {
  border-top-color: #a6e1ec;
}
.alert-info .alert-link {
  color: #245269;
}

.alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
}
.alert-warning hr {
  border-top-color: #f7e1b5;
}
.alert-warning .alert-link {
  color: #66512c;
}

.alert-danger {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}
.alert-danger hr {
  border-top-color: #e4b9c0;
}
.alert-danger .alert-link {
  color: #843534;
}

/***
Custom Bootstrap Badges
***/
.badge {
  font-size: 11px !important;
  font-weight: 300;
  text-align: center;
  height: 18px;
  padding: 3px 6px 3px 6px;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  border-radius: 12px !important;
  text-shadow: none !important;
  text-align: center;
  vertical-align: middle;
}
.badge.badge-roundless {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}
.badge.badge-empty {
  display: inline-block;
  padding: 0;
  min-width: 8px;
  height: 8px;
  width: 8px;
}

/* Badge variants */
.badge-default {
  background-color: #c6c6c6;
  background-image: none;
}

.badge-primary {
  background-color: #428bca;
  background-image: none;
}

.badge-info {
  background-color: #89C4F4;
  background-image: none;
}

.badge-success {
  background-color: #45B6AF;
  background-image: none;
}

.badge-danger {
  background-color: #F3565D;
  background-image: none;
}

.badge-warning {
  background-color: #dfba49;
  background-image: none;
}

/* Fix badge position for navs */
.nav.nav-pills > li > a > .badge,
.nav.nav-stacked > li > a > .badge {
  margin-top: -2px;
}

/***
Dropdown Menu Badges
***/
.dropdown-menu > li > a > .badge {
  position: absolute;
  margin-top: 1px;
  right: 3px;
  display: inline;
  font-size: 11px;
  font-weight: 300;
  text-shadow: none;
  height: 18px;
  padding: 3px 6px 3px 6px;
  text-align: center;
  vertical-align: middle;
  -webkit-border-radius: 12px !important;
  -moz-border-radius: 12px !important;
  border-radius: 12px !important;
}
.dropdown-menu.badge-roundless {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

/***
Customized Bootstrap Buttons
***/
/* Default buttons */
.btn {
  border-width: 0;
  padding: 7px 14px;
  font-size: 14px;
  outline: none !important;
  background-image: none !important;
  filter: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
}
.btn.dropdown-toggle, .btn-group .btn.dropdown-toggle, .btn:hover, .btn:disabled, .btn[disabled], .btn:focus, .btn:active, .btn.active {
  outline: none !important;
  background-image: none !important;
  filter: none;
  text-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.btn + .btn {
  margin-left: 5px;
}

.btn-default {
  border-width: 1px;
  padding: 6px 13px;
}

.btn-block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

/* Bootstrap buttons */
.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active {
  color: #333;
  background-color: #ededed;
  border-color: #b3b3b3;
}
.open .btn-default.dropdown-toggle {
  color: #333;
  background-color: #ededed;
  border-color: #b3b3b3;
}
.btn-default:active, .btn-default.active {
  background-image: none;
  background-color: #e0e0e0;
}
.btn-default:active:hover, .btn-default.active:hover {
  background-color: #e6e6e6;
}
.open .btn-default.dropdown-toggle {
  background-image: none;
}
.btn-default.disabled, .btn-default.disabled:hover, .btn-default.disabled:focus, .btn-default.disabled:active, .btn-default.disabled.active, .btn-default[disabled], .btn-default[disabled]:hover, .btn-default[disabled]:focus, .btn-default[disabled]:active, .btn-default[disabled].active, fieldset[disabled] .btn-default, fieldset[disabled] .btn-default:hover, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default:active, fieldset[disabled] .btn-default.active {
  background-color: #fff;
  border-color: #ccc;
}
.btn-default .badge {
  color: #fff;
  background-color: #333;
}
.btn-default > i {
  color: #aaa;
}
.btn-default > i[class^="icon-"],
.btn-default > i[class*="icon-"] {
  color: #8c8c8c;
}

.btn-primary {
  color: #fff;
  background-color: #428bca;
  border-color: #357ebd;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active {
  color: #fff;
  background-color: #3379b5;
  border-color: #2a6496;
}
.open .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #3379b5;
  border-color: #2a6496;
}
.btn-primary:active, .btn-primary.active {
  background-image: none;
  background-color: #2d6ca2;
}
.btn-primary:active:hover, .btn-primary.active:hover {
  background-color: #3071a9;
}
.open .btn-primary.dropdown-toggle {
  background-image: none;
}
.btn-primary.disabled, .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled]:active, .btn-primary[disabled].active, fieldset[disabled] .btn-primary, fieldset[disabled] .btn-primary:hover, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:active, fieldset[disabled] .btn-primary.active {
  background-color: #428bca;
  border-color: #357ebd;
}
.btn-primary .badge {
  color: #428bca;
  background-color: #fff;
}

.btn-success {
  color: #fff;
  background-color: #45B6AF;
  border-color: #3ea49d;
}
.btn-success:hover, .btn-success:focus, .btn-success:active, .btn-success.active {
  color: #fff;
  background-color: #3b9c96;
  border-color: #307f7a;
}
.open .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #3b9c96;
  border-color: #307f7a;
}
.btn-success:active, .btn-success.active {
  background-image: none;
  background-color: #348a84;
}
.btn-success:active:hover, .btn-success.active:hover {
  background-color: #37918b;
}
.open .btn-success.dropdown-toggle {
  background-image: none;
}
.btn-success.disabled, .btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled:active, .btn-success.disabled.active, .btn-success[disabled], .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled]:active, .btn-success[disabled].active, fieldset[disabled] .btn-success, fieldset[disabled] .btn-success:hover, fieldset[disabled] .btn-success:focus, fieldset[disabled] .btn-success:active, fieldset[disabled] .btn-success.active {
  background-color: #45B6AF;
  border-color: #3ea49d;
}
.btn-success .badge {
  color: #45B6AF;
  background-color: #fff;
}

.btn-info {
  color: #fff;
  background-color: #89C4F4;
  border-color: #72b8f2;
}
.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active {
  color: #fff;
  background-color: #68b4f1;
  border-color: #43a1ed;
}
.open .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #68b4f1;
  border-color: #43a1ed;
}
.btn-info:active, .btn-info.active {
  background-image: none;
  background-color: #51a8ef;
}
.btn-info:active:hover, .btn-info.active:hover {
  background-color: #5aadf0;
}
.open .btn-info.dropdown-toggle {
  background-image: none;
}
.btn-info.disabled, .btn-info.disabled:hover, .btn-info.disabled:focus, .btn-info.disabled:active, .btn-info.disabled.active, .btn-info[disabled], .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info[disabled]:active, .btn-info[disabled].active, fieldset[disabled] .btn-info, fieldset[disabled] .btn-info:hover, fieldset[disabled] .btn-info:focus, fieldset[disabled] .btn-info:active, fieldset[disabled] .btn-info.active {
  background-color: #89C4F4;
  border-color: #72b8f2;
}
.btn-info .badge {
  color: #89C4F4;
  background-color: #fff;
}

.btn-warning {
  color: #fff;
  background-color: #dfba49;
  border-color: #dbb233;
}
.btn-warning:hover, .btn-warning:focus, .btn-warning:active, .btn-warning.active {
  color: #fff;
  background-color: #daae2b;
  border-color: #bb9521;
}
.open .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #daae2b;
  border-color: #bb9521;
}
.btn-warning:active, .btn-warning.active {
  background-image: none;
  background-color: #c89f23;
}
.btn-warning:active:hover, .btn-warning.active:hover {
  background-color: #d0a625;
}
.open .btn-warning.dropdown-toggle {
  background-image: none;
}
.btn-warning.disabled, .btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled:active, .btn-warning.disabled.active, .btn-warning[disabled], .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled]:active, .btn-warning[disabled].active, fieldset[disabled] .btn-warning, fieldset[disabled] .btn-warning:hover, fieldset[disabled] .btn-warning:focus, fieldset[disabled] .btn-warning:active, fieldset[disabled] .btn-warning.active {
  background-color: #dfba49;
  border-color: #dbb233;
}
.btn-warning .badge {
  color: #dfba49;
  background-color: #fff;
}

.btn-danger {
  color: #fff;
  background-color: #F3565D;
  border-color: #f13e46;
}
.btn-danger:hover, .btn-danger:focus, .btn-danger:active, .btn-danger.active {
  color: #fff;
  background-color: #f1353d;
  border-color: #ec111b;
}
.open .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #f1353d;
  border-color: #ec111b;
}
.btn-danger:active, .btn-danger.active {
  background-image: none;
  background-color: #ef1d26;
}
.btn-danger:active:hover, .btn-danger.active:hover {
  background-color: #f0262f;
}
.open .btn-danger.dropdown-toggle {
  background-image: none;
}
.btn-danger.disabled, .btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled:active, .btn-danger.disabled.active, .btn-danger[disabled], .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled]:active, .btn-danger[disabled].active, fieldset[disabled] .btn-danger, fieldset[disabled] .btn-danger:hover, fieldset[disabled] .btn-danger:focus, fieldset[disabled] .btn-danger:active, fieldset[disabled] .btn-danger.active {
  background-color: #F3565D;
  border-color: #f13e46;
}
.btn-danger .badge {
  color: #F3565D;
  background-color: #fff;
}

/* Button sizes */
.btn > i {
  font-size: 14px;
}

.btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  vertical-align: middle;
}
.btn-lg > i {
  font-size: 18px;
}

.btn-sm,
.btn-xs {
  padding: 4px 10px 5px 10px;
  font-size: 13px;
  line-height: 1.5;
}
.btn-sm > i,
.btn-xs > i {
  font-size: 13px;
}

.btn-xs {
  padding: 1px 5px;
}
.btn-xs > i {
  font-size: 12px;
}

/* Small button in button group */
.btn-group .input-sm .btn-default {
  padding-top: 3px;
  padding-bottom: 3px;
}

/* Arrow link */
.btn-arrow-link {
  vertical-align: middle;
  display: inline-block;
  font-size: 13px;
}
.btn-arrow-link > i {
  font-size: 14px;
  line-height: 14px;
  vertical-align: top;
  margin: 2px 3px 2px 3px;
  color: #999;
}
.btn-arrow-link.btn-arrow-link-lg {
  font-size: 14px;
}
.btn-arrow-link.btn-arrow-link-lg > i {
  font-size: 16px;
  line-height: 16px;
  margin: 2px 3px 1px 3px;
}

/* Circle Buttons */
.btn-circle {
  border-radius: 25px !important;
}

.btn-circle-right {
  border-radius: 0 25px 25px 0 !important;
}

.btn-circle-left {
  border-radius: 25px 0 0 25px !important;
}

.btn-circle-bottom {
  border-radius: 0 0 25px 25px !important;
}

.btn-circle-top {
  border-radius: 25px 25px 0 0 !important;
}

.btn-icon-only {
  height: 34px;
  width: 34px;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}
.btn-icon-only > [class^="icon-"],
.btn-icon-only > i {
  text-align: center;
  margin-top: 2px;
}

.btn-sm > [class^="glyphicon-"],
.btn-sm > [class*=" glyphicon-"],
.btn-sm > i {
  font-size: 13px;
}

.btn-xs > [class^="glyphicon-"],
.btn-xs > [class*=" glyphicon-"],
.btn-xs > i {
  font-size: 11px;
}

.btn-group.btn-group-circle > .btn:first-child {
  border-radius: 25px 0 0 25px !important;
}
.btn-group.btn-group-circle > .btn:last-child {
  border-radius: 0 25px 25px 0 !important;
}

.btn-group.btn-group-devided > .btn {
  margin-right: 5px;
}
.btn-group.btn-group-devided > .btn:last-child {
  margin-right: 0;
}

.btn-group-vertical.btn-group-vertical-circle > .btn:first-child {
  border-radius: 25px 25px 0 0 !important;
}
.btn-group-vertical.btn-group-vertical-circle > .btn:last-child {
  border-radius: 0 0 25px 25px !important;
}

/***
Image Carousel
***/
.carousel.image-carousel .carousel-inner {
  padding-top: 0;
  padding-bottom: 0;
}
.carousel.image-carousel .carousel-control i {
  position: absolute;
  top: 40%;
}
.carousel.image-carousel.image-carousel-hoverable .carousel-control i {
  display: none;
}
.carousel.image-carousel.image-carousel-hoverable:hover .carousel-control i {
  display: inline-block;
}
.carousel.image-carousel .carousel-control.left i {
  left: 10px;
}
.carousel.image-carousel .carousel-control.right i {
  right: 10px;
}
.carousel.image-carousel .carousel-indicators {
  margin-top: 10px;
  bottom: -7px;
}
.carousel.image-carousel .carousel-indicators li {
  background-color: #666;
}
.carousel.image-carousel .carousel-indicators li.active {
  background-color: #666;
}
.carousel.image-carousel .carousel-caption {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 15px 15px 25px 15px;
  background: #333333;
  background: rgba(0, 0, 0, 0.75);
}
.carousel.image-carousel .carousel-caption h4,
.carousel.image-carousel .carousel-caption h3,
.carousel.image-carousel .carousel-caption h2,
.carousel.image-carousel .carousel-caption p {
  text-align: left;
  line-height: 20px;
  color: #ffffff;
}
.carousel.image-carousel .carousel-caption h4,
.carousel.image-carousel .carousel-caption h3,
.carousel.image-carousel .carousel-caption h2 {
  margin: 0 0 5px;
}
.carousel.image-carousel .carousel-caption h4 a,
.carousel.image-carousel .carousel-caption h3 a,
.carousel.image-carousel .carousel-caption h2 a {
  color: #aaa;
}
.carousel.image-carousel .carousel-caption p {
  margin-bottom: 0;
}
.carousel.image-carousel .carousel-caption .item {
  margin: 0;
}

/***
Charts and statistics
***/
.chart,
.pie,
.bars {
  overflow: hidden;
  height: 300px;
  width: 100%;
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px;
}

/***
Statistic lists
***/
.item-list.table .percent {
  width: 30px;
  float: right;
  margin-right: 10px;
  margin-top: 3px;
}

/***
Chart tooltips
***/
.chart-tooltip {
  clear: both;
  z-index: 100;
  background-color: #736e6e !important;
  padding: 5px !important;
  color: #fff;
}
.chart-tooltip .label {
  clear: both;
  display: block;
  margin-bottom: 2px;
}

/***
Mini chart containers
***/
.bar-chart,
.line-chart {
  display: none;
}

/***
Chats
***/
.chats {
  margin: 0;
  padding: 0;
  margin-top: -15px;
}
.chats li {
  list-style: none;
  padding: 5px 0;
  margin: 10px auto;
  font-size: 12px;
}
.chats li .body {
  display: block;
}
.chats li .avatar {
  height: 45px;
  width: 45px;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important;
}
.chats li.in .avatar {
  float: left;
  margin-right: 10px;
}
.chats li.out .avatar {
  float: right;
  margin-left: 10px;
}
.chats li .name {
  color: #3590c1;
  font-size: 13px;
  font-weight: 400;
}
.chats li .datetime {
  color: #333;
  font-size: 13px;
  font-weight: 400;
}
.chats li .message {
  display: block;
  padding: 5px;
  position: relative;
}
.chats li.in .message {
  text-align: left;
  border-left: 2px solid #1BBC9B;
  margin-left: 65px;
  background: #fafafa;
}
.chats li.in .message .arrow {
  display: block;
  position: absolute;
  top: 5px;
  left: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #1BBC9B;
}
.chats li.out .message {
  border-right: 2px solid #F3565D;
  margin-right: 65px;
  background: #fafafa;
  text-align: right;
}
.chats li.out .message .arrow {
  display: block;
  position: absolute;
  top: 5px;
  right: -8px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid #F3565D;
}
.chats li.out .name,
.chats li.out .datetime {
  text-align: right;
}

.chat-form {
  margin-top: 15px;
  padding: 10px;
  background-color: #e9eff3;
  overflow: hidden;
  clear: both;
}
.chat-form .input-cont {
  margin-right: 40px;
}
.chat-form .input-cont .form-control {
  border: 1px solid #ddd;
  width: 100%  !important;
  margin-top: 0;
  background-color: #fff !important;
}
.chat-form .input-cont .form-control:focus {
  border: 1px solid #4b8df9 !important;
}
.chat-form .btn-cont {
  margin-top: -42px;
  position: relative;
  float: right;
  width: 44px;
}
.chat-form .btn-cont .arrow {
  position: absolute;
  top: 17px;
  right: 43px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #4d90fe;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.chat-form .btn-cont .btn {
  margin-top: 8px;
}
.chat-form .btn-cont:hover .arrow {
  border-right-color: #0362fd;
}
.chat-form .btn-cont:hover .btn {
  background-color: #0362fd;
}

/***
Customized Bootstrap Close Icon 
***/
.close {
  display: inline-block;
  margin-top: 0px;
  margin-right: 0px;
  width: 9px;
  height: 9px;
  background-repeat: no-repeat !important;
  text-indent: -10000px;
  outline: none;
  background-image: url("../img/remove-icon-small.png") !important;
}

/***
Customized Bootstrap Dropdowns
***/
/***
Dropdowns
***/
.dropup.open > .dropdown-toggle,
.dropdown.open > .dropdown-toggle {
  border-color: #ddd;
}

/***
Dropdown Menu
***/
.dropdown-menu {
  min-width: 175px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  list-style: none;
  text-shadow: none;
  padding: 0px;
  background-color: #ffffff;
  margin: 10px  0px 0px 0px;
  box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
  border: 1px solid #eee;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}
.dropdown-menu li.divider {
  background: #f1f3f6;
}
.dropdown-menu li.dropdown-header {
  padding: 8px 14px 2px 14px;
}
.dropdown-menu li > a {
  padding: 8px 14px;
  color: #555;
  text-decoration: none;
  display: block;
  clear: both;
  font-weight: 300;
  line-height: 18px;
  white-space: nowrap;
}
.dropdown-menu li > a > [class^="fa-"],
.dropdown-menu li > a > [class*=" fa-"] {
  color: #888;
}
.dropdown-menu li > a > [class^="icon-"],
.dropdown-menu li > a > [class*=" icon-"] {
  color: #666;
}
.dropdown-menu li > a > [class^="glyphicon-"],
.dropdown-menu li > a > [class*=" glyphicon-"] {
  color: #888;
}
.dropdown-menu li > a:hover,
.dropdown-menu .active > a,
.dropdown-menu .active > a:hover {
  text-decoration: none;
  background-image: none;
  background-color: #f6f6f6;
  color: #555;
  filter: none;
}
.dropdown-menu.bottom-up {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}
.dropdown-menu > li:first-child:hover > a {
  border-radius: 4px 4px 0px 0px;
}
.dropdown-menu > li:last-child:hover > a {
  border-radius: 0px 0px 4px 4px;
}

.dropdown > .dropdown-menu,
.dropdown-toggle > .dropdown-menu,
.btn-group > .dropdown-menu {
  margin-top: 10px;
}
.dropdown > .dropdown-menu:before,
.dropdown-toggle > .dropdown-menu:before,
.btn-group > .dropdown-menu:before {
  position: absolute;
  top: -8px;
  left: 9px;
  right: auto;
  display: inline-block !important;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #e0e0e0;
  border-left: 8px solid transparent;
  content: '';
}
.dropdown > .dropdown-menu:after,
.dropdown-toggle > .dropdown-menu:after,
.btn-group > .dropdown-menu:after {
  position: absolute;
  top: -7px;
  left: 10px;
  right: auto;
  display: inline-block !important;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #fff;
  border-left: 7px solid transparent;
  content: '';
}
.dropdown > .dropdown-menu.pull-left:before,
.dropdown-toggle > .dropdown-menu.pull-left:before,
.btn-group > .dropdown-menu.pull-left:before {
  left: auto;
  right: 9px;
}
.dropdown > .dropdown-menu.pull-left:after,
.dropdown-toggle > .dropdown-menu.pull-left:after,
.btn-group > .dropdown-menu.pull-left:after {
  left: auto;
  right: 10px;
}
.dropdown > .dropdown-menu.pull-right:before,
.dropdown-toggle > .dropdown-menu.pull-right:before,
.btn-group > .dropdown-menu.pull-right:before {
  left: auto;
  right: 9px;
}
.dropdown > .dropdown-menu.pull-right:after,
.dropdown-toggle > .dropdown-menu.pull-right:after,
.btn-group > .dropdown-menu.pull-right:after {
  left: auto;
  right: 10px;
}
.dropdown.dropup > .dropdown-menu,
.dropdown-toggle.dropup > .dropdown-menu,
.btn-group.dropup > .dropdown-menu {
  margin-top: 0px;
  margin-bottom: 10px;
}
.dropdown.dropup > .dropdown-menu:after, .dropdown.dropup > .dropdown-menu:before,
.dropdown-toggle.dropup > .dropdown-menu:after,
.dropdown-toggle.dropup > .dropdown-menu:before,
.btn-group.dropup > .dropdown-menu:after,
.btn-group.dropup > .dropdown-menu:before {
  display: none !important;
}

/* Dropdown submenu support for Bootsrap 3 */
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 5px;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
}
.dropdown-submenu > a:after {
  position: absolute;
  display: inline-block;
  font-size: 14px;
  right: 7px;
  top: 7px;
  font-family: FontAwesome;
  height: auto;
  content: "\f105";
  font-weight: 300;
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.dropdown-submenu:hover > a:after {
  border-left-color: #ffffff;
}
.dropdown-submenu.pull-left {
  float: none;
}
.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
}
.dropup .dropdown-submenu > .dropdown-menu {
  top: auto;
  bottom: 0;
  margin-top: 0;
  margin-bottom: -2px;
}

.nav.pull-right > li > .dropdown-menu,
.nav > li > .dropdown-menu.pull-right {
  right: 0;
  left: auto;
}
.nav.pull-right > li > .dropdown-menu:before,
.nav > li > .dropdown-menu.pull-right:before {
  right: 12px;
  left: auto;
}
.nav.pull-right > li > .dropdown-menu:after,
.nav > li > .dropdown-menu.pull-right:after {
  right: 13px;
  left: auto;
}
.nav.pull-right > li > .dropdown-menu .dropdown-menu,
.nav > li > .dropdown-menu.pull-right .dropdown-menu {
  right: 100%;
  left: auto;
  margin-right: -1px;
  margin-left: 0;
}

@media (max-width: 767px) {
  /* 767px */
  .navbar-nav .open .dropdown-menu {
    position: absolute;
    float: left;
    width: auto;
    margin-top: 0;
    background-color: #ffffff;
    border: 1px solid #efefef;
    box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
  }
  .navbar-nav .open .dropdown-menu > li > a {
    padding: 6px 0 6px 13px;
    color: #333;
  }
  .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-nav .open .dropdown-menu > li > a:active {
    background-color: #eee;
  }
}
/***
Dropdown Checkboxes
***/
.dropdown-content {
  padding: 5px;
}
.dropdown-content form {
  margin: 0;
}

.dropdown.inline .dropdown-menu {
  display: inline-block;
  position: relative;
}

.dropdown-radiobuttons,
.dropdown-checkboxes {
  padding: 5px;
}
.dropdown-radiobuttons label,
.dropdown-checkboxes label {
  display: block;
  font-weight: 300;
  color: #333;
  margin-bottom: 4px;
  margin-top: 4px;
}
.dropdown-radiobuttons label .radio,
.dropdown-checkboxes label .radio {
  margin-right: 3px;
}

/*--------------------------------------------------
    [Dropdown Menu v2]
----------------------------------------------------*/
.dropdown-menu-v2 {
  top: 100%;
  right: 0;
  z-index: 1001;
  display: none;
  float: left;
  min-width: 225px;
  padding: 20px 0;
  margin: 25px 0 0;
  font-size: 13px;
  text-align: left;
  list-style: none;
  position: absolute;
  background: #242b31;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  transition-duration: 300ms;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.7, 1, 0.7, 1);
}
.dropdown-menu-v2:before {
  top: -10px;
  right: 0;
  width: 0;
  height: 0;
  content: ' ';
  position: absolute;
  border-style: solid;
  display: inline-block;
  border-width: 0 0 15px 15px;
  border-color: transparent transparent #242b31 transparent;
}
.dropdown-menu-v2 > li > a {
  clear: both;
  color: #606e7a;
  display: block;
  font-weight: 600;
  padding: 8px 20px;
  position: relative;
  white-space: nowrap;
  line-height: 1.42857143;
  text-transform: uppercase;
}
.dropdown-menu-v2 > li > a:hover, .dropdown-menu-v2 > li > a:focus {
  background: #21282e;
  text-decoration: none;
  color: #009dc7;
}
.dropdown-menu-v2 .active > a,
.dropdown-menu-v2 .active > a:hover,
.dropdown-menu-v2 .active > a:focus,
.dropdown-menu-v2 .active > a,
.dropdown-menu-v2 .active > a:hover,
.dropdown-menu-v2 .active > a:focus,
.dropdown-menu-v2 .active > a,
.dropdown-menu-v2 .active > a:hover,
.dropdown-menu-v2 .active > a:focus {
  outline: 0;
  text-decoration: none;
  background-color: #21282e;
  color: #009dc7;
}
.dropdown-menu-v2 .disabled > a,
.dropdown-menu-v2 .disabled > a:hover,
.dropdown-menu-v2 .disabled > a:focus,
.dropdown-menu-v2 .disabled > a,
.dropdown-menu-v2 .disabled > a:hover,
.dropdown-menu-v2 .disabled > a:focus,
.dropdown-menu-v2 .disabled > a,
.dropdown-menu-v2 .disabled > a:hover,
.dropdown-menu-v2 .disabled > a:focus {
  color: #009dc7;
}
.dropdown-menu-v2 .disabled > a:hover,
.dropdown-menu-v2 .disabled > a:focus,
.dropdown-menu-v2 .disabled > a:hover,
.dropdown-menu-v2 .disabled > a:focus {
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
  background-image: none;
}
.dropdown-menu-v2 .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background: #2e353c;
}
.dropdown-menu-v2 .badge {
  top: 8px;
  right: 10px;
  position: absolute;
}

.dropdown-menu-v2.pull-right {
  right: 0;
  left: auto;
}

.open > .dropdown-menu-v2 {
  display: block;
}

.pull-right > .dropdown-menu-v2 {
  right: 0;
  left: auto;
}

/***
System feeds
***/
.feeds {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.feeds li {
  background-color: #fafafa;
  color: #82949a;
  margin-bottom: 7px;
}
.feeds li:before, .feeds li:after {
  display: table;
  line-height: 0;
  content: "";
}
.feeds li:after {
  clear: both;
}
.feeds li:last-child {
  margin-bottom: 0px;
}
.feeds li .col1 {
  float: left;
  width: 100%;
  clear: both;
}
.feeds li .col1 > .cont {
  float: left;
  margin-right: 75px;
  overflow: hidden;
}
.feeds li .col1 > .cont > .cont-col1 {
  float: left;
  margin-right: -100%;
}
.feeds li .col1 > .cont > .cont-col1 > .label {
  display: inline-block;
  padding: 5px 4px 6px 5px;
  vertical-align: middle;
  text-align: center;
}
.feeds li .col1 > .cont > .cont-col1 > .label > i {
  text-align: center;
  font-size: 14px;
}
.feeds li .col1 > .cont > .cont-col2 {
  float: left;
  width: 100%;
}
.feeds li .col1 > .cont > .cont-col2 > .desc {
  margin-left: 35px;
  padding-top: 4px;
  padding-bottom: 5px;
  overflow: hidden;
}
.feeds li .col2 {
  float: left;
  width: 75px;
  margin-left: -75px;
}
.feeds li .col2 > .date {
  padding: 4px 9px 5px 4px;
  text-align: right;
  font-style: italic;
  color: #c1cbd0;
}

/***
Form Layouts
****/
/* Static info */
.static-info {
  margin-bottom: 10px;
}
.static-info .name {
  font-size: 14px;
}
.static-info .value {
  font-size: 14px;
  font-weight: 600;
}
.static-info.align-reverse .name, .static-info.align-reverse .value {
  text-align: right;
}

/* Help blocks */
.help-block {
  margin-top: 5px;
  margin-bottom: 5px;
}

.help-inline {
  font-size: 13px;
  color: #737373;
  display: inline-block;
  padding: 5px;
}

.form-inline input {
  margin-bottom: 0px !important;
}

/* Control Label */
.control-label {
  margin-top: 1px;
}
.control-label .required {
  color: #e02222;
  font-size: 12px;
  padding-left: 2px;
}

.form {
  padding: 0 !important;
}
.form .form-body {
  padding: 10px;
}
.portlet.light .form .form-body {
  padding-left: 0;
  padding-right: 0;
}
.form .form-actions {
  padding: 20px 10px;
  margin: 0;
  background-color: #f5f5f5;
  border-top: 1px solid #e5e5e5;
  *zoom: 1;
}
.portlet.light .form .form-actions {
  background: none;
  padding-left: 0;
  padding-right: 0;
}
.form .form-actions.noborder {
  border-top: 0;
}
.portlet .form .form-actions {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -ms-border-radius: 0 0 4px 4px;
  -o-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}
.form .form-actions:before, .form .form-actions:after {
  content: " ";
  display: table;
}
.form .form-actions:after {
  clear: both;
}
.form .form-actions.right {
  padding-left: 0;
  padding-right: 10px;
  text-align: right;
}
.portlet.light .form .form-actions.right {
  padding-right: 0;
}
.form .form-actions.left {
  padding-left: 10px;
  padding-right: 0;
  text-align: left;
}
.portlet.light .form .form-actions.left {
  padding-left: 0;
}
.form .form-actions.nobg {
  background-color: transparent;
}
.form .form-actions.top {
  margin-top: 0;
  margin-bottom: 20px;
  border-top: 0;
  border-bottom: 1px solid #e5e5e5;
}
.portlet.light .form .form-actions.top {
  background: none;
}
.form .form-actions .btn-set {
  display: inline-block;
}
@media (max-width: 767px) {
  .form .form-actions {
    /* 767px */
  }
  .form .form-actions .btn-set {
    margin-bottom: 3px;
    margin-top: 3px;
    float: left !important;
  }
}
.form .form-section {
  margin: 30px 0px 25px 0px;
  padding-bottom: 5px;
  border-bottom: 1px solid #eee;
}
.form .form-section:first-child {
  margin-top: 5px;
}

/* Checkboxes */
.checkbox,
.form-horizontal .checkbox {
  padding: 0;
}
.checkbox > label,
.form-horizontal .checkbox > label {
  padding-left: 0;
}

.checkbox-list > label {
  display: block;
}
.checkbox-list > label.checkbox-inline {
  display: inline-block;
}
.checkbox-list > label.checkbox-inline:first-child {
  padding-left: 0;
}

/* Radio buttons */
.radio-list > label {
  display: block;
}
.radio-list > label.radio-inline {
  display: inline-block;
}
.radio-list > label.radio-inline:first-child {
  padding-left: 0;
}

/* Radio buttons in horizontal forms */
.form-horizontal .radio-list .radio {
  padding-top: 1px;
}
.form-horizontal .radio-list > label {
  margin-bottom: 0;
}
.form-horizontal .radio > span {
  margin-top: 2px;
}

/* Rows seperated form layout */
.form .form-row-seperated .portlet-body {
  padding: 0;
}
.form .form-row-seperated .form-group {
  margin: 0;
  border-bottom: 1px solid #efefef;
  padding: 15px 0;
}
.form .form-row-seperated .form-group.last {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 13px;
}
.form .form-row-seperated .form-group .help-block {
  margin-bottom: 0;
}
.form .form-row-seperated .form-body {
  padding: 0;
}
.form .form-row-seperated .form-actions {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

/* Form bordered */
.form .form-bordered .form-group {
  margin: 0;
  border-bottom: 1px solid #efefef;
}
.form .form-bordered .form-group > div {
  padding: 15px;
  border-left: 1px solid #efefef;
}
@media (max-width: 991px) {
  .form .form-bordered .form-group > div {
    /* 991px */
    border-left: 0;
  }
}
.form .form-bordered .form-group.last {
  border-bottom: 0;
}
.form .form-bordered .form-group .control-label {
  padding-top: 20px;
}
@media (max-width: 991px) {
  .form .form-bordered .form-group .control-label {
    /* 991px */
    padding-top: 10px;
  }
}
.form .form-bordered .form-group .help-block {
  margin-bottom: 0;
}
.form .form-bordered .form-group .form-control {
  margin: 0;
}
.form .form-bordered .form-body {
  margin: 0;
  padding: 0;
}
.form .form-bordered .form-actions {
  margin-top: 0;
  padding-left: 16px !important;
  padding-right: 16px !important;
}
@media (max-width: 991px) {
  .form .form-bordered .form-actions {
    /* 991px */
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

/* Horizontal bordered form */
.form .form-horizontal.form-bordered.form-row-stripped .form-group:nth-child(even) {
  background-color: #fcfcfc;
}
.form .form-horizontal.form-bordered.form-row-stripped .form-control {
  background: #fff !important;
}
.form .form-horizontal.form-bordered.form-label-stripped .form-group:nth-child(even) {
  background-color: #fcfcfc;
}
.form .form-horizontal.form-bordered.form-label-stripped .form-group:nth-child(even) > div {
  background-color: #ffffff;
}
.form .form-horizontal.form-bordered.form-label-stripped .form-group:nth-child(even) {
  background-color: #fcfcfc;
}

/***
Forms
***/
/* Input placeholder font color */
.form-control::-moz-placeholder {
  color: #b3b3b3;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #b3b3b3;
}
.form-control::-webkit-input-placeholder {
  color: #b3b3b3;
}

/* Form inputs */
.form-control {
  font-size: 14px;
  font-weight: normal;
  color: #333;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  box-shadow: none;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}
.form-control:focus {
  border-color: #999;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  cursor: not-allowed;
  background-color: #eeeeee;
}
.form-control.height-auto {
  height: auto;
}
.form-control.form-control-solid {
  background-color: #F1F3F8;
  border-color: #F1F3F8;
  color: #A6B2BA;
}
.form-control.form-control-solid:focus {
  border-color: #e3e7f1;
}
.form-control.form-control-solid::-moz-placeholder {
  color: #acb7be;
  opacity: 1;
}
.form-control.form-control-solid:-ms-input-placeholder {
  color: #acb7be;
}
.form-control.form-control-solid::-webkit-input-placeholder {
  color: #acb7be;
}

/* Form uneditable input */
.uneditable-input {
  padding: 6px 12px;
  min-width: 206px;
  font-size: 14px;
  font-weight: normal;
  height: 34px;
  color: #333;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

/* Form input sizing */
.input-mini {
  width: 45px !important;
}

.input-xsmall {
  width: 80px !important;
}

.input-small {
  width: 145px !important;
}

.input-medium {
  width: 240px !important;
}

.input-large {
  width: 320px !important;
}

.input-xlarge {
  width: 420px !important;
}

.input-inline {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.input-fixed {
  overflow: hidden;
}

.form-group .input-inline {
  margin-right: 5px;
}

.input-sm {
  height: 28px;
  padding: 5px 10px;
  font-size: 13px;
}

select.input-sm {
  height: 28px;
  line-height: 28px;
  padding: 2px 10px;
}

@media (max-width: 768px) {
  /* 768px */
  .input-large {
    width: 250px !important;
  }

  .input-xlarge {
    width: 300px !important;
  }
}
/* Input  groups */
.input-group .btn-default {
  border-color: #e5e5e5;
}
.input-group .input-group-addon {
  border-color: #e5e5e5;
  background: #e5e5e5;
  min-width: 39px;
}
.input-group .input-group-addon > i {
  color: #999;
}

/* Input spinner */
input[type="text"].spinner,
input[type="password"].spinner,
input[type="datetime"].spinner,
input[type="datetime-local"].spinner,
input[type="date"].spinner,
input[type="month"].spinner,
input[type="time"].spinner,
input[type="week"].spinner,
input[type="number"].spinner,
input[type="email"].spinner,
input[type="url"].spinner,
input[type="search"].spinner,
input[type="tel"].spinner,
input[type="color"].spinner {
  background-image: url("../img/input-spinner.gif") !important;
  background-repeat: no-repeat;
  background-position: right 8px;
}

/* Form labels */
label {
  font-weight: 400;
  font-size: 14px;
}

/* Static form control */
.form-control-static {
  margin: 2px 0;
  display: inline-block;
}

/* Feedback states */
.has-success .help-block,
.has-success .help-inline,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline {
  color: #3c763d;
}
.has-success .form-control {
  border-color: #d6e9c6;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-success .form-control:focus {
  border-color: #bbdba1;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-success .input-group-addon {
  color: #3c763d;
  border-color: #d6e9c6;
  background-color: #dff0d8;
}
.has-success .input-group-addon > i {
  color: #3c763d;
}
.has-success .form-control-feedback {
  color: #3c763d;
}

.has-warning .help-block,
.has-warning .help-inline,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline {
  color: #8a6d3b;
}
.has-warning .form-control {
  border-color: #faebcc;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-warning .form-control:focus {
  border-color: #f5d89e;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-warning .input-group-addon {
  color: #8a6d3b;
  border-color: #faebcc;
  background-color: #fcf8e3;
}
.has-warning .input-group-addon > i {
  color: #8a6d3b;
}
.has-warning .form-control-feedback {
  color: #8a6d3b;
}

.has-error .help-block,
.has-error .help-inline,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline {
  color: #a94442;
}
.has-error .form-control {
  border-color: #ebccd1;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-error .form-control:focus {
  border-color: #dca7b0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.has-error .input-group-addon {
  color: #a94442;
  border-color: #ebccd1;
  background-color: #f2dede;
}
.has-error .input-group-addon > i {
  color: #a94442;
}
.has-error .form-control-feedback {
  color: #a94442;
}

/* Circle Inputs */
.input-circle {
  border-radius: 25px !important;
}

.input-circle-right {
  border-radius: 0 25px 25px 0 !important;
}

.input-circle-left {
  border-radius: 25px 0 0 25px !important;
}

.input-circle-bottom {
  border-radius: 0 0 25px 25px !important;
}

.input-circle-top {
  border-radius: 25px 25px 0 0 !important;
}

/***
Custom icon buttons
***/
.icon-btn {
  height: 60px;
  min-width: 80px;
  margin: 5px 5px 0 0;
  border: 1px solid #ddd;
  padding: 12px 0px 0px 0px;
  background-color: #fafafa;
  background-image: none;
  filter: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  display: inline-block;
  color: #646464;
  text-shadow: none;
  text-align: center;
  cursor: pointer;
  position: relative;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.icon-btn:hover {
  text-decoration: none;
  border-color: #999;
  color: #444;
  text-shadow: 0 1px 0px white;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.icon-btn:hover > .badge {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.icon-btn > div {
  margin-top: 5px;
  margin-bottom: 20px;
  color: #000;
  font-size: 12px;
  font-weight: 300;
}
.icon-btn > .badge {
  position: absolute;
  font-size: 11px;
  font-weight: 300;
  top: -5px;
  right: -5px;
  padding: 3px 6px 3px 6px;
  color: white;
  text-shadow: none;
  border-width: 0;
  border-style: solid;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.icon-btn > i {
  font-size: 18px;
}
.ie8 .icon-btn:hover {
  filter: none;
}

/***
Input icons
***/
.input-icon {
  position: relative;
  left: 0;
}
.input-icon > .form-control {
  padding-left: 33px;
}
.input-group .input-icon > .form-control {
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  -ms-border-radius: 4px 0 0 4px;
  -o-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
.input-icon > i {
  color: #ccc;
  display: block;
  position: absolute;
  margin: 11px 2px 4px 10px;
  z-index: 3;
  width: 16px;
  font-size: 16px;
  text-align: center;
}
.modal .input-icon > i {
  z-index: 10055;
}
.has-success .input-icon > i {
  color: #45B6AF;
}
.has-warning .input-icon > i {
  color: #dfba49;
}
.has-info .input-icon > i {
  color: #89C4F4;
}
.has-error .input-icon > i {
  color: #F3565D;
}
.input-icon.right {
  left: auto;
  right: 0;
}
.input-icon.right > .form-control {
  padding-right: 33px;
  padding-left: 12px;
}
.input-group .input-icon.right > .form-control {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  -ms-border-radius: 0 4px 4px 0;
  -o-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.input-icon.right > i {
  right: 8px;
  float: right;
}
.input-icon.input-icon-lg > i {
  margin-top: 16px;
}
.input-icon.input-icon-sm > i {
  margin-top: 8px;
  font-size: 13px;
}

/***
Customized Bootstrap Labels
***/
.label {
  text-shadow: none !important;
  font-size: 13px;
  font-weight: 300;
  padding: 3px 6px 3px 6px;
  color: #fff;
  font-family: "Open Sans", sans-serif;
}
.label.label-sm {
  font-size: 12px;
  padding: 0px 4px 1px 4px;
}
h1 .label, h2 .label, h3 .label, h4 .label, h5 .label, h6 .label {
  font-size: 75%;
}

/* Labels variants */
.label-default {
  background-color: #c6c6c6;
}
.label-default[href]:hover, .label-default[href]:focus {
  background-color: #adadad;
}

.label-primary {
  background-color: #428bca;
}
.label-primary[href]:hover, .label-primary[href]:focus {
  background-color: #3071a9;
}

.label-success {
  background-color: #45B6AF;
}
.label-success[href]:hover, .label-success[href]:focus {
  background-color: #37918b;
}

.label-info {
  background-color: #89C4F4;
}
.label-info[href]:hover, .label-info[href]:focus {
  background-color: #5aadf0;
}

.label-warning {
  background-color: #dfba49;
}
.label-warning[href]:hover, .label-warning[href]:focus {
  background-color: #d0a625;
}

.label-danger {
  background-color: #F3565D;
}
.label-danger[href]:hover, .label-danger[href]:focus {
  background-color: #f0262f;
}

/***
Iconic labels
***/
.label.label-icon {
  padding: 4px 0px 4px 4px;
  margin-right: 2px;
  text-align: center !important;
}
.label.label-icon > i {
  font-size: 12px;
  text-align: center !important;
}
.ie8 .label.label-icon, .ie9 .label.label-icon {
  padding: 3px 0px 3px 3px;
}

/***
Text states
***/
.text-default {
  color: #c6c6c6;
}

.text-primary {
  color: #428bca;
}

.text-success {
  color: #45B6AF;
}

.text-info {
  color: #89C4F4;
}

.text-warning {
  color: #dfba49;
}

.text-danger {
  color: #F3565D;
}

/***
Customized List Group
***/
/* Contextual variants */
.list-group > li:first-child {
  border-radius-topleft: 4px;
  border-radius-topright: 4px;
}
.list-group > li:last-child {
  border-radius-bottomleft: 4px;
  border-radius-bottomright: 4px;
}

.list-group .list-group-item-success {
  color: #3c763d;
  background-color: #dff0d8;
}
.list-group a.list-group-item-success {
  color: #3c763d;
}
.list-group a.list-group-item-success .list-group-item-heading {
  color: inherit;
}
.list-group a.list-group-item-success:hover, .list-group a.list-group-item-success:focus {
  color: #3c763d;
  background-color: #d0e9c6;
}
.list-group a.list-group-item-success.active, .list-group a.list-group-item-success.active:hover, .list-group a.list-group-item-success.active:focus {
  color: #fff;
  background-color: #3c763d;
  border-color: #3c763d;
}

.list-group .list-group-item-info {
  color: #31708f;
  background-color: #d9edf7;
}
.list-group a.list-group-item-info {
  color: #31708f;
}
.list-group a.list-group-item-info .list-group-item-heading {
  color: inherit;
}
.list-group a.list-group-item-info:hover, .list-group a.list-group-item-info:focus {
  color: #31708f;
  background-color: #c4e3f3;
}
.list-group a.list-group-item-info.active, .list-group a.list-group-item-info.active:hover, .list-group a.list-group-item-info.active:focus {
  color: #fff;
  background-color: #31708f;
  border-color: #31708f;
}

.list-group .list-group-item-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
}
.list-group a.list-group-item-warning {
  color: #8a6d3b;
}
.list-group a.list-group-item-warning .list-group-item-heading {
  color: inherit;
}
.list-group a.list-group-item-warning:hover, .list-group a.list-group-item-warning:focus {
  color: #8a6d3b;
  background-color: #faf2cc;
}
.list-group a.list-group-item-warning.active, .list-group a.list-group-item-warning.active:hover, .list-group a.list-group-item-warning.active:focus {
  color: #fff;
  background-color: #8a6d3b;
  border-color: #8a6d3b;
}

.list-group .list-group-item-danger {
  color: #a94442;
  background-color: #f2dede;
}
.list-group a.list-group-item-danger {
  color: #a94442;
}
.list-group a.list-group-item-danger .list-group-item-heading {
  color: inherit;
}
.list-group a.list-group-item-danger:hover, .list-group a.list-group-item-danger:focus {
  color: #a94442;
  background-color: #ebcccc;
}
.list-group a.list-group-item-danger.active, .list-group a.list-group-item-danger.active:hover, .list-group a.list-group-item-danger.active:focus {
  color: #fff;
  background-color: #a94442;
  border-color: #a94442;
}

/***
UI Loading
***/
.loading-message {
  display: inline-block;
  min-width: 125px;
  margin-left: -60px;
  padding: 10px;
  margin: 0 auto;
  color: #000 !important;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
}
.loading-message.loading-message-boxed {
  border: 1px solid #ddd;
  background-color: #eee;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}
.loading-message > span {
  line-height: 20px;
  vertical-align: middle;
}

.page-loading {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 125px;
  margin-left: -60px;
  margin-top: -30px;
  padding: 7px;
  text-align: center;
  color: #333;
  font-size: 13px;
  border: 1px solid #ddd;
  background-color: #eee;
  vertical-align: middle;
  -webkit-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}
.page-loading > span {
  line-height: 20px;
  vertical-align: middle;
}

.page-spinner-bar {
  position: fixed;
  z-index: 10051;
  width: 100px;
  top: 40%;
  left: 50%;
  margin-left: -55px;
  text-align: center;
}
.page-spinner-bar > div {
  margin: 0 5px;
  width: 18px;
  height: 18px;
  background: #eee;
  border-radius: 100% !important;
  display: inline-block;
  -webkit-animation: bounceDelay 1.4s infinite ease-in-out;
  animation: bounceDelay 1.4s infinite ease-in-out;
  /* Prevent first frame from flickering when animation starts */
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.page-spinner-bar .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.page-spinner-bar .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.block-spinner-bar {
  display: inline-block;
  width: 80px;
  text-align: center;
}
.block-spinner-bar > div {
  margin: 0 2px;
  width: 15px;
  height: 15px;
  background: #eee;
  border-radius: 100% !important;
  display: inline-block;
  -webkit-animation: bounceDelay 1.4s infinite ease-in-out;
  animation: bounceDelay 1.4s infinite ease-in-out;
  /* Prevent first frame from flickering when animation starts */
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.block-spinner-bar .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.block-spinner-bar .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

/***
Metro icons
***/
[class^="m-icon-"] {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-top: 3px;
  line-height: 14px;
  vertical-align: top;
  background-image: url(../img/syncfusion-icons.png);
  background-position: 0 0;
  background-repeat: no-repeat;
}

[class^="m-icon-big-"] {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 6px;
  vertical-align: middle;
  background-image: url(../img/syncfusion-icons.png);
  background-position: 0 0px;
  background-repeat: no-repeat;
}

/* large icons */
.btn.m-icon-big {
  padding: 9px 16px 8px 16px;
}

.btn.m-icon-big.m-icon-only {
  padding: 9px 8px 8px 0px;
}

.btn.m-icon-big [class^="m-icon-big-"] {
  margin: 0 0 0 10px;
}

.btn.m-icon-ony > i {
  margin-left: 0px;
}

/* default icons */
.btn.m-icon {
  padding: 7px 14px 7px 14px;
}

.btn.m-icon [class^="m-icon-"] {
  margin: 4px 0 0 5px;
}

.btn.m-icon.m-icon-only {
  padding: 7px 10px 7px 6px;
}

/* white icon */
.m-icon-white {
  background-image: url(../img/syncfusion-icons-white.png);
}

/*  Misc */
.m-icon-swapright {
  background-position: -27px -10px;
}

.m-icon-swapdown {
  background-position: -68px -10px;
}

.m-icon-swapleft {
  background-position: -8px -10px;
}

.m-icon-swapup {
  background-position: -46px -10px;
}

.m-icon-big-swapright {
  background-position: -42px -28px;
}

.m-icon-big-swapdown {
  background-position: -115px -28px;
}

.m-icon-big-swapleft {
  background-position: -6px -28px;
}

.m-icon-big-swapup {
  background-position: -78px -28px;
}

/***
Customized Bootstrap Modal 
***/
.modal {
  z-index: 10050;
  outline: none;
  overflow-y: auto !important;
  /* Fix content shifting to the right on modal open due to scrollbar closed */
}
.page-portlet-fullscreen .modal {
  z-index: 10060;
}
.modal .modal-header {
  border-bottom: 1px solid #EFEFEF;
}
.modal .modal-header h3 {
  font-weight: 300;
}
.modal .modal-header .close {
  margin-top: 0px !important;
}
.modal.draggable-modal .modal-header {
  cursor: move;
}
.modal .modal-dialog {
  z-index: 10051;
}
.modal > .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
}
.modal.in .page-loading {
  display: none;
}

.modal-open {
  overflow-y: auto !important;
}

.modal-open-noscroll {
  overflow-y: hidden !important;
}

.modal-backdrop {
  border: 0;
  outline: none;
  z-index: 10049;
}
.page-portlet-fullscreen .modal-backdrop {
  z-index: 10059;
}
.modal-backdrop, .modal-backdrop.fade.in {
  background-color: #333 !important;
}

/* Full width modal */
.modal-full.modal-dialog {
  width: 99%;
}

@media (max-width: 768px) {
  .modal-full.modal-dialog {
    width: auto;
  }
}
/***
Notes
***/
.note {
  margin: 0 0 20px 0;
  padding: 15px 30px 15px 15px;
  border-left: 5px solid #eee;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  -ms-border-radius: 0 4px 4px 0;
  -o-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
.note h1,
.note h2,
.note h3,
.note h4,
.note h5,
.note h6 {
  margin-top: 0;
}
.note h1 .close,
.note h2 .close,
.note h3 .close,
.note h4 .close,
.note h5 .close,
.note h6 .close {
  margin-right: -10px;
}
.note p {
  font-size: 13px;
}
.note p:last-child {
  margin-bottom: 0;
}
.note code,
.note .highlight {
  background-color: #fff;
}
.note.note-default {
  background-color: lightgray;
  border-color: #adadad;
  color: #333333;
}
.note.note-default.note-bordered {
  background-color: #c3c3c3;
  border-color: #a0a0a0;
}
.note.note-default.note-shadow {
  background-color: #c6c6c6;
  border-color: #a0a0a0;
  box-shadow: 5px 5px rgba(162, 162, 162, 0.2);
}
.note.note-primary {
  background-color: #5697d0;
  border-color: #3085a9;
  color: #D8E3F2;
}
.note.note-primary.note-bordered {
  background-color: #3e89c9;
  border-color: #2a7696;
}
.note.note-primary.note-shadow {
  background-color: #428bca;
  border-color: #2a7696;
  box-shadow: 5px 5px rgba(43, 121, 154, 0.2);
}
.note.note-success {
  background-color: #eef7ea;
  border-color: #c9e2b3;
  color: #3c763d;
}
.note.note-success.note-bordered {
  background-color: #dcefd4;
  border-color: #bbdba1;
}
.note.note-success.note-shadow {
  background-color: #dff0d8;
  border-color: #bbdba1;
  box-shadow: 5px 5px rgba(190, 220, 164, 0.2);
}
.note.note-info {
  background-color: #eef7fb;
  border-color: #a6e1ec;
  color: #31708f;
}
.note.note-info.note-bordered {
  background-color: #d5ebf6;
  border-color: #91d9e8;
}
.note.note-info.note-shadow {
  background-color: #d9edf7;
  border-color: #91d9e8;
  box-shadow: 5px 5px rgba(150, 219, 233, 0.2);
}
.note.note-warning {
  background-color: #fcf8e3;
  border-color: #f5d89e;
  color: #8a6d3b;
}
.note.note-warning.note-bordered {
  background-color: #f9f1c7;
  border-color: #f2cf87;
}
.note.note-warning.note-shadow {
  background-color: #faf2cc;
  border-color: #f2cf87;
  box-shadow: 5px 5px rgba(243, 209, 139, 0.2);
}
.note.note-danger {
  background-color: #f9f0f0;
  border-color: #e4b9c0;
  color: #a94442;
}
.note.note-danger.note-bordered {
  background-color: #f1dada;
  border-color: #dca7b0;
}
.note.note-danger.note-shadow {
  background-color: #f2dede;
  border-color: #dca7b0;
  box-shadow: 5px 5px rgba(222, 171, 179, 0.2);
}

/***
Customized Bootstrap Pagination
***/
.pagination {
  margin: 10px 0;
}
.pagination.pagination-circle > li:first-child > a {
  border-radius: 25px 0 0 25px !important;
}
.pagination.pagination-circle > li:last-child > a {
  border-radius: 0 25px 25px 0 !important;
}
.pagination .active > a,
.pagination .active > a:hover {
  background: #eee;
  border-color: #dddddd;
  color: #333;
}

/***
Customized Bootstrap Panels
***/
.panel {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
.panel-group .panel {
  overflow: visible;
}
.panel .panel-body {
  font-size: 13px;
}
.panel .panel-title > a:hover {
  text-decoration: none;
}
.accordion .panel .panel-heading {
  padding: 0;
}
.accordion .panel .panel-title {
  padding: 0;
}
.accordion .panel .panel-title .accordion-toggle {
  display: block;
  padding: 10px 15px;
}
.accordion .panel .panel-title .accordion-toggle.accordion-toggle-styled {
  background: url("../img/accordion-plusminus.png") no-repeat;
  background-position: right -19px;
  margin-right: 15px;
}
.accordion .panel .panel-title .accordion-toggle.accordion-toggle-styled.collapsed {
  background-position: right 12px;
}

.panel-default {
  border-color: #e0e0e0;
}
.panel-default > .panel-heading {
  color: #333333;
  background-color: #f9f9f9;
  border-color: #e0e0e0;
}
.panel-default > .panel-heading + .panel-collapse .panel-body {
  border-top-color: #e0e0e0;
}
.panel-default > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: #e0e0e0;
}

.panel-primary {
  border-color: #3595bd;
}
.panel-primary > .panel-heading {
  color: #D8E3F2;
  background-color: #428bca;
  border-color: #3595bd;
}
.panel-primary > .panel-heading + .panel-collapse .panel-body {
  border-top-color: #3595bd;
}
.panel-primary > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: #3595bd;
}

.panel-success {
  border-color: #d6e9c6;
}
.panel-success > .panel-heading {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
.panel-success > .panel-heading + .panel-collapse .panel-body {
  border-top-color: #d6e9c6;
}
.panel-success > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: #d6e9c6;
}

.panel-info {
  border-color: #bce8f1;
}
.panel-info > .panel-heading {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}
.panel-info > .panel-heading + .panel-collapse .panel-body {
  border-top-color: #bce8f1;
}
.panel-info > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: #bce8f1;
}

.panel-warning {
  border-color: #faebcc;
}
.panel-warning > .panel-heading {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}
.panel-warning > .panel-heading + .panel-collapse .panel-body {
  border-top-color: #faebcc;
}
.panel-warning > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: #faebcc;
}

.panel-danger {
  border-color: #ebccd1;
}
.panel-danger > .panel-heading {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}
.panel-danger > .panel-heading + .panel-collapse .panel-body {
  border-top-color: #ebccd1;
}
.panel-danger > .panel-footer + .panel-collapse .panel-body {
  border-bottom-color: #ebccd1;
}

/***
Accordions
***/
.accordion-heading {
  background: #eee;
}
.accordion-heading a {
  text-decoration: none;
}
.accordion-heading a:hover {
  text-decoration: none;
}

/***
Customized Bootstrap Popover
***/
.popover {
  box-shadow: 5px 5px rgba(102, 102, 102, 0.1);
  padding: 0;
}
.popover .popover-title {
  margin: 0 !important;
}

.popover.name .popover-title,
name .popover .popover-content,
.popover.name .popover-content {
  color: #89C4F4;
}

.popover.name .popover-title,
name .popover .popover-content,
.popover.name .popover-content {
  color: #428bca;
}

.popover.name .popover-title,
name .popover .popover-content,
.popover.name .popover-content {
  color: #45B6AF;
}

.popover.name .popover-title,
name .popover .popover-content,
.popover.name .popover-content {
  color: #dfba49;
}

.popover.name .popover-title,
name .popover .popover-content,
.popover.name .popover-content {
  color: #F3565D;
}

/***
Portlets
***/
/* Full Screen portlet mode */
.page-portlet-fullscreen {
  overflow: hidden;
}

/* Basic portlet */
.portlet {
  margin-top: 0px;
  margin-bottom: 25px;
  padding: 0px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}
.portlet.portlet-fullscreen {
  z-index: 10060;
  margin: 0;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}
.portlet.portlet-fullscreen > .portlet-body {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 10px;
}
.portlet.portlet-fullscreen > .portlet-title {
  padding: 0 10px;
}
.portlet > .portlet-title {
  border-bottom: 1px solid #eee;
  padding: 0;
  margin-bottom: 10px;
  min-height: 41px;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -ms-border-radius: 4px 4px 0 0;
  -o-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
.portlet > .portlet-title:before, .portlet > .portlet-title:after {
  content: " ";
  display: table;
}
.portlet > .portlet-title:after {
  clear: both;
}
.portlet > .portlet-title > .caption {
  float: left;
  display: inline-block;
  font-size: 18px;
  line-height: 18px;
  padding: 10px 0;
}
.portlet > .portlet-title > .caption.bold {
  font-weight: 400;
}
.portlet > .portlet-title > .caption > i {
  float: left;
  margin-top: 4px;
  display: inline-block;
  font-size: 13px;
  margin-right: 5px;
  color: #666;
}
.portlet > .portlet-title > .caption > i.glyphicon {
  margin-top: 2px;
}
.portlet > .portlet-title > .caption > .caption-helper {
  padding: 0;
  margin: 0;
  line-height: 13px;
  color: #9eacb4;
  font-size: 13px;
  font-weight: 400;
}
.portlet > .portlet-title > .actions {
  float: right;
  display: inline-block;
  padding: 6px 0;
}
.portlet > .portlet-title > .actions > .dropdown-menu i {
  color: #555555;
}
.portlet > .portlet-title > .actions > .btn,
.portlet > .portlet-title > .actions > .btn.btn-sm,
.portlet > .portlet-title > .actions > .btn-group > .btn,
.portlet > .portlet-title > .actions > .btn-group > .btn.btn-sm {
  padding: 4px 10px;
  font-size: 13px;
  line-height: 1.5;
}
.portlet > .portlet-title > .actions > .btn.btn-default,
.portlet > .portlet-title > .actions > .btn.btn-sm.btn-default,
.portlet > .portlet-title > .actions > .btn-group > .btn.btn-default,
.portlet > .portlet-title > .actions > .btn-group > .btn.btn-sm.btn-default {
  padding: 3px 9px;
}
.portlet > .portlet-title > .actions > .btn > i,
.portlet > .portlet-title > .actions > .btn.btn-sm > i,
.portlet > .portlet-title > .actions > .btn-group > .btn > i,
.portlet > .portlet-title > .actions > .btn-group > .btn.btn-sm > i {
  font-size: 13px;
}
.portlet > .portlet-title > .actions .btn-icon-only {
  padding: 5px 7px 3px 7px;
}
.portlet > .portlet-title > .actions .btn-icon-only.btn-default {
  padding: 4px 6px 2px 6px;
}
.portlet > .portlet-title > .actions .btn-icon-only.btn-default > i {
  font-size: 14px;
}
.portlet > .portlet-title > .actions .btn-icon-only.btn-default.fullscreen {
  font-family: FontAwesome;
  color: #a0a0a0;
  padding-top: 3px;
}
.portlet > .portlet-title > .actions .btn-icon-only.btn-default.fullscreen.btn-sm {
  padding: 3px 3px !important;
  height: 27px;
  width: 27px;
}
.portlet > .portlet-title > .actions .btn-icon-only.btn-default.fullscreen:before {
  content: "\f065";
}
.portlet > .portlet-title > .actions .btn-icon-only.btn-default.fullscreen.on:before {
  content: "\f066";
}
.portlet > .portlet-title > .tools {
  float: right;
  display: inline-block;
  padding: 12px 0 8px 0;
}
.portlet > .portlet-title > .tools > a {
  display: inline-block;
  height: 16px;
  margin-left: 5px;
  opacity: 1 ;
  filter: alpha(opacity=100) ;
}
.portlet > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon.png);
  background-repeat: no-repeat;
  width: 11px;
}
.portlet > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon.png);
  background-repeat: no-repeat;
  width: 12px;
}
.portlet > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon.png);
  width: 13px;
}
.portlet > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon.png);
  width: 14px;
  visibility: visible;
}
.portlet > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon.png);
  width: 14px;
  visibility: visible;
}
.portlet > .portlet-title > .tools > a.fullscreen {
  display: inline-block;
  top: -3px;
  position: relative;
  font-size: 13px;
  font-family: FontAwesome;
  color: #ACACAC;
}
.portlet > .portlet-title > .tools > a.fullscreen:before {
  content: "\f065";
}
.portlet > .portlet-title > .tools > a.fullscreen.on:before {
  content: "\f066";
}
.portlet > .portlet-title > .tools > a:hover {
  text-decoration: none;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  opacity: 0.8 ;
  filter: alpha(opacity=80) ;
}
.portlet > .portlet-title > .pagination {
  float: right;
  display: inline-block;
  margin: 2px 0 0 0;
  border: 0;
  padding: 4px 0;
}
.portlet > .portlet-title > .nav-tabs {
  background: none;
  margin: 0;
  float: right;
  display: inline-block;
  border: 0;
}
.portlet > .portlet-title > .nav-tabs > li {
  background: none;
  margin: 0;
  border: 0;
}
.portlet > .portlet-title > .nav-tabs > li > a {
  background: none;
  margin: 5px 0 0 1px;
  border: 0;
  padding: 8px 10px;
  color: #fff;
}
.portlet > .portlet-title > .nav-tabs > li.active > a, .portlet > .portlet-title > .nav-tabs > li:hover > a {
  color: #333;
  background: #fff;
  border: 0;
}
.portlet > .portlet-body {
  clear: both;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -ms-border-radius: 0 0 4px 4px;
  -o-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}
.portlet > .portlet-empty {
  min-height: 125px;
}
.portlet.full-height-content {
  margin-bottom: 0;
}

/* Portlet background colors */
/* Side bordered portlet */
.portlet.bordered {
  border-left: 2px solid #e6e9ec !important;
}
.portlet.bordered > .portlet-title {
  border-bottom: 0;
}

/* Solid colored portlet */
.portlet.solid {
  padding: 0 10px 10px 10px;
  border: 0px;
}
.portlet.solid > .portlet-title {
  border-bottom: 0;
  margin-bottom: 10px;
}
.portlet.solid > .portlet-title > .caption {
  padding: 16px 0 2px 0;
}
.portlet.solid > .portlet-title > .actions {
  padding: 12px 0 6px 0;
}
.portlet.solid > .portlet-title > .tools {
  padding: 14px 0 6px 0;
}

/* Solid bordered portlet */
.portlet.solid.bordered > .portlet-title {
  margin-bottom: 10px;
}

/* Box portlet */
.portlet.box {
  padding: 0px !important;
}
.portlet.box > .portlet-title {
  border-bottom: 0;
  padding: 0 10px;
  margin-bottom: 0;
  color: #fff;
}
.portlet.box > .portlet-title > .caption {
  padding: 11px 0 9px 0;
}
.portlet.box > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.box > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.box > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.box > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.box > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.box > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}
.portlet.box > .portlet-title > .actions {
  padding: 7px 0 5px 0;
}
.portlet.box > .portlet-body {
  background-color: #fff;
  padding: 10px;
}
.portlet.box.portlet-fullscreen > .portlet-body {
  padding: 10px;
}

/* Light Portlet */
.portlet.light {
  padding: 12px 20px 15px 20px;
  background-color: #fff;
}
.portlet.light.bordered {
  border: 1px solid #e1e1e1 !important;
}
.portlet.light.bordered > .portlet-title {
  border-bottom: 1px solid #eee;
}
.portlet.light.bg-inverse {
  background: #F7F7F7;
}
.portlet.light > .portlet-title {
  padding: 0;
  min-height: 48px;
}
.portlet.light > .portlet-title > .caption {
  color: #666;
  padding: 10px 0;
}
.portlet.light > .portlet-title > .caption > .caption-subject {
  font-size: 16px;
}
.portlet.light > .portlet-title > .caption > i {
  color: #777;
  font-size: 15px;
  font-weight: 300;
  margin-top: 3px;
}
.portlet.light > .portlet-title > .caption.caption-md > .caption-subject {
  font-size: 15px;
}
.portlet.light > .portlet-title > .caption.caption-md > i {
  font-size: 14px;
}
.portlet.light > .portlet-title > .actions {
  padding: 6px 0 14px 0;
}
.portlet.light > .portlet-title > .actions .btn-default {
  color: #666;
}
.portlet.light > .portlet-title > .actions .btn-icon-only {
  height: 27px;
  width: 27px;
}
.portlet.light > .portlet-title > .actions .dropdown-menu li > a {
  color: #555;
}
.portlet.light > .portlet-title > .inputs {
  float: right;
  display: inline-block;
  padding: 4px 0;
}
.portlet.light > .portlet-title > .inputs > .portlet-input .input-icon > i {
  font-size: 14px;
  margin-top: 9px;
}
.portlet.light > .portlet-title > .inputs > .portlet-input .input-icon > .form-control {
  height: 30px;
  padding: 2px 26px 3px 10px;
  font-size: 13px;
}
.portlet.light > .portlet-title > .inputs > .portlet-input > .form-control {
  height: 30px;
  padding: 3px 10px;
  font-size: 13px;
}
.portlet.light > .portlet-title > .pagination {
  padding: 2px 0 13px 0;
}
.portlet.light > .portlet-title > .tools {
  padding: 10px 0 13px 0;
  margin-top: 2px;
}
.portlet.light > .portlet-title > .nav-tabs > li {
  margin: 0;
  padding: 0;
}
.portlet.light > .portlet-title > .nav-tabs > li > a {
  margin: 0;
  padding: 12px 13px 13px 13px;
  font-size: 13px;
  color: #666;
}
.portlet.light > .portlet-title > .nav-tabs > li.active > a, .portlet.light > .portlet-title > .nav-tabs > li:hover > a {
  margin: 0;
  background: none;
  color: #333;
}
.portlet.light.form-fit {
  padding: 0;
}
.portlet.light.form-fit > .portlet-title {
  padding: 17px 20px 10px 20px;
  margin-bottom: 0;
}
.portlet.light .portlet-body {
  padding-top: 8px;
}
.portlet.light.portlet-fullscreen > .portlet-body {
  padding: 8px 0;
}

.tab-pane > p:last-child {
  margin-bottom: 0px;
}

/* Reverse aligned tabs */
.tabs-reversed > li {
  float: right;
  margin-right: 0;
}
.tabs-reversed > li > a {
  margin-right: 0;
}

/* jQuery UI Draggable Portlets */
.portlet-sortable:not(.portlet-fullscreen) > .portlet-title {
  cursor: move;
}

.portlet-sortable-placeholder {
  border: 2px dashed #eee;
  margin-bottom: 25px;
}

.portlet-sortable-empty {
  box-shadow: none !important;
  height: 45px;
}

/***
Customized Progress Bars
***/
.progress {
  border: 0;
  background-image: none;
  filter: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.progress.progress-sm {
  height: 12px;
}

.progress > .progress-bar-default {
  background-color: #c6c6c6;
}
.progress-striped .progress > .progress-bar-default {
  background-image: none;
  background-image: none;
}

.progress > .progress-bar-success {
  background-color: #45B6AF;
}
.progress-striped .progress > .progress-bar-success {
  background-image: none;
  background-image: none;
}

.progress > .progress-bar-info {
  background-color: #89C4F4;
}
.progress-striped .progress > .progress-bar-info {
  background-image: none;
  background-image: none;
}

.progress > .progress-bar-danger {
  background-color: #F3565D;
}
.progress-striped .progress > .progress-bar-danger {
  background-image: none;
  background-image: none;
}

.progress > .progress-bar-warning {
  background-color: #dfba49;
}
.progress-striped .progress > .progress-bar-warning {
  background-image: none;
  background-image: none;
}

/***
Dashboard Stats
***/
.dashboard-stat {
  display: block;
  margin-bottom: 25px;
  overflow: hidden;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}
.dashboard-stat:before, .dashboard-stat:after {
  content: " ";
  display: table;
}
.dashboard-stat:after {
  clear: both;
}
.portlet .dashboard-stat:last-child {
  margin-bottom: 0;
}
.dashboard-stat .visual {
  width: 80px;
  height: 80px;
  display: block;
  float: left;
  padding-top: 10px;
  padding-left: 15px;
  margin-bottom: 15px;
  font-size: 35px;
  line-height: 35px;
}
.dashboard-stat .visual > i {
  margin-left: -35px;
  font-size: 110px;
  line-height: 110px;
}
.dashboard-stat .details {
  position: absolute;
  right: 15px;
  padding-right: 15px;
}
.dashboard-stat .details .number {
  padding-top: 25px;
  text-align: right;
  font-size: 34px;
  line-height: 36px;
  letter-spacing: -1px;
  margin-bottom: 0px;
  font-weight: 300;
}
.dashboard-stat .details .desc {
  text-align: right;
  font-size: 16px;
  letter-spacing: 0px;
  font-weight: 300;
}
.dashboard-stat .more {
  clear: both;
  display: block;
  padding: 6px 10px 6px 10px;
  position: relative;
  text-transform: uppercase;
  font-weight: 300;
  font-size: 11px;
  opacity: 0.7;
  filter: alpha(opacity=70);
}
.dashboard-stat .more:hover {
  text-decoration: none;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.dashboard-stat .more > i {
  display: inline-block;
  margin-top: 1px;
  float: right;
}

.dashboard-stat-light {
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.dashboard-stat-light .details {
  margin-bottom: 5px;
}
.dashboard-stat-light .details .number {
  font-weight: 300;
  margin-bottom: 0px;
}

/***
Dashboard Stats 2
***/
.dashboard-stat2 {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  background: #fff;
  padding: 15px 15px 30px 15px;
  margin-bottom: 20px;
}
.dashboard-stat2 .display {
  margin-bottom: 20px;
}
.dashboard-stat2 .display:before, .dashboard-stat2 .display:after {
  content: " ";
  display: table;
}
.dashboard-stat2 .display:after {
  clear: both;
}
.dashboard-stat2 .display .number {
  float: left;
  display: inline-block;
}
.dashboard-stat2 .display .number h3 {
  margin: 0 0 2px 0;
  padding: 0;
  font-size: 30px;
  font-weight: 400;
}
.dashboard-stat2 .display .number h3 > small {
  font-size: 23px;
}
.dashboard-stat2 .display .number small {
  font-size: 14px;
  color: #AAB5BC;
  font-weight: 600;
  text-transform: uppercase;
}
.dashboard-stat2 .display .icon {
  display: inline-block;
  float: right;
  padding: 7px 0 0 0;
}
.dashboard-stat2 .display .icon > i {
  color: #cbd4e0;
  font-size: 26px;
}
.dashboard-stat2 .progress-info {
  clear: both;
}
.dashboard-stat2 .progress-info .progress {
  margin: 0;
  height: 4px;
  clear: both;
  display: block;
}
.dashboard-stat2 .progress-info .status {
  margin-top: 5px;
  font-size: 11px;
  color: #AAB5BC;
  font-weight: 600;
  text-transform: uppercase;
}
.dashboard-stat2 .progress-info .status .status-title {
  float: left;
  display: inline-block;
}
.dashboard-stat2 .progress-info .status .status-number {
  float: right;
  display: inline-block;
}

/***
Text Stats
***/
.text-stat h3 {
  margin-top: 5px;
  margin-bottom: 0px;
  font-size: 18px;
}
.text-stat span {
  font-size: 13px !important;
}

@media (max-width: 767px) {
  /* 767px */
  .text-stat {
    margin-top: 20px;
  }
}
/***
Social Icons
***/
.social-icons {
  padding: 0;
  margin: 0;
}
.social-icons:before, .social-icons:after {
  content: " ";
  display: table;
}
.social-icons:after {
  clear: both;
}
.social-icons li {
  float: left;
  display: inline;
  list-style: none;
  margin-right: 5px;
  margin-bottom: 5px;
  text-indent: -9999px;
}
.social-icons li > a {
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  width: 28px;
  height: 28px;
  display: block;
  background-position: 0 0;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}
.social-icons li:hover > a {
  background-position: 0 -38px;
}
.social-icons li .amazon {
  background: url(../img/social/amazon.png) no-repeat;
}
.social-icons li .behance {
  background: url(../img/social/behance.png) no-repeat;
}
.social-icons li .blogger {
  background: url(../img/social/blogger.png) no-repeat;
}
.social-icons li .deviantart {
  background: url(../img/social/deviantart.png) no-repeat;
}
.social-icons li .dribbble {
  background: url(../img/social/dribbble.png) no-repeat;
}
.social-icons li .dropbox {
  background: url(../img/social/dropbox.png) no-repeat;
}
.social-icons li .evernote {
  background: url(../img/social/evernote.png) no-repeat;
}
.social-icons li .facebook {
  background: url(../img/social/facebook.png) no-repeat;
}
.social-icons li .forrst {
  background: url(../img/social/forrst.png) no-repeat;
}
.social-icons li .github {
  background: url(../img/social/github.png) no-repeat;
}
.social-icons li .googleplus {
  background: url(../img/social/googleplus.png) no-repeat;
}
.social-icons li .jolicloud {
  background: url(../img/social/jolicloud.png) no-repeat;
}
.social-icons li .last-fm {
  background: url(../img/social/last-fm.png) no-repeat;
}
.social-icons li .linkedin {
  background: url(../img/social/linkedin.png) no-repeat;
}
.social-icons li .picasa {
  background: url(../img/social/picasa.png) no-repeat;
}
.social-icons li .pintrest {
  background: url(../img/social/pintrest.png) no-repeat;
}
.social-icons li .rss {
  background: url(../img/social/rss.png) no-repeat;
}
.social-icons li .skype {
  background: url(../img/social/skype.png) no-repeat;
}
.social-icons li .spotify {
  background: url(../img/social/spotify.png) no-repeat;
}
.social-icons li .stumbleupon {
  background: url(../img/social/stumbleupon.png) no-repeat;
}
.social-icons li .tumblr {
  background: url(../img/social/tumblr.png) no-repeat;
}
.social-icons li .twitter {
  background: url(../img/social/twitter.png) no-repeat;
}
.social-icons li .vimeo {
  background: url(../img/social/vimeo.png) no-repeat;
}
.social-icons li .wordpress {
  background: url(../img/social/wordpress.png) no-repeat;
}
.social-icons li .xing {
  background: url(../img/social/xing.png) no-repeat;
}
.social-icons li .yahoo {
  background: url(../img/social/yahoo.png) no-repeat;
}
.social-icons li .youtube {
  background: url(../img/social/youtube.png) no-repeat;
}
.social-icons li .vk {
  background: url(../img/social/vk.png) no-repeat;
}
.social-icons li .instagram {
  background: url(../img/social/instagram.png) no-repeat;
}
.social-icons li .reddit {
  background: url(../img/social/reddit.png) no-repeat;
}
.social-icons li .aboutme {
  background: url(../img/social/aboutme.png) no-repeat;
}
.social-icons li .flickr {
  background: url(../img/social/flickr.png) no-repeat;
}
.social-icons li .foursquare {
  background: url(../img/social/foursquare.png) no-repeat;
}
.social-icons li .gravatar {
  background: url(../img/social/gravatar.png) no-repeat;
}
.social-icons li .klout {
  background: url(../img/social/klout.png) no-repeat;
}
.social-icons li .myspace {
  background: url(../img/social/myspace.png) no-repeat;
}
.social-icons li .quora {
  background: url(../img/social/quora.png) no-repeat;
}
.social-icons.social-icons-color > li > a {
  opacity: 0.7;
  background-position: 0 -38px !important;
}
.social-icons.social-icons-color > li > a:hover {
  opacity: 1;
}
.social-icons.social-icons-circle > li > a {
  border-radius: 25px !important;
}

/***
Inline Social Icons
***/
.social-icon {
  display: inline-block !important;
  width: 28px;
  height: 28px;
  background-position: 0 0;
  background-repeat: no-repeat;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}
.social-icon.social-icon-circle {
  border-radius: 25px !important;
}

.social-icon.amazon {
  background: url(../img/social/amazon.png) no-repeat;
}
.social-icon.behance {
  background: url(../img/social/behance.png) no-repeat;
}
.social-icon.blogger {
  background: url(../img/social/blogger.png) no-repeat;
}
.social-icon.deviantart {
  background: url(../img/social/deviantart.png) no-repeat;
}
.social-icon.dribbble {
  background: url(../img/social/dribbble.png) no-repeat;
}
.social-icon.dropbox {
  background: url(../img/social/dropbox.png) no-repeat;
}
.social-icon.evernote {
  background: url(../img/social/evernote.png) no-repeat;
}
.social-icon.facebook {
  background: url(../img/social/facebook.png) no-repeat;
}
.social-icon.forrst {
  background: url(../img/social/forrst.png) no-repeat;
}
.social-icon.github {
  background: url(../img/social/github.png) no-repeat;
}
.social-icon.googleplus {
  background: url(../img/social/googleplus.png) no-repeat;
}
.social-icon.jolicloud {
  background: url(../img/social/jolicloud.png) no-repeat;
}
.social-icon.last-fm {
  background: url(../img/social/last-fm.png) no-repeat;
}
.social-icon.linkedin {
  background: url(../img/social/linkedin.png) no-repeat;
}
.social-icon.picasa {
  background: url(../img/social/picasa.png) no-repeat;
}
.social-icon.pintrest {
  background: url(../img/social/pintrest.png) no-repeat;
}
.social-icon.rss {
  background: url(../img/social/rss.png) no-repeat;
}
.social-icon.skype {
  background: url(../img/social/skype.png) no-repeat;
}
.social-icon.spotify {
  background: url(../img/social/spotify.png) no-repeat;
}
.social-icon.stumbleupon {
  background: url(../img/social/stumbleupon.png) no-repeat;
}
.social-icon.tumblr {
  background: url(../img/social/tumblr.png) no-repeat;
}
.social-icon.twitter {
  background: url(../img/social/twitter.png) no-repeat;
}
.social-icon.vimeo {
  background: url(../img/social/vimeo.png) no-repeat;
}
.social-icon.wordpress {
  background: url(../img/social/wordpress.png) no-repeat;
}
.social-icon.xing {
  background: url(../img/social/xing.png) no-repeat;
}
.social-icon.yahoo {
  background: url(../img/social/yahoo.png) no-repeat;
}
.social-icon.youtube {
  background: url(../img/social/youtube.png) no-repeat;
}
.social-icon.vk {
  background: url(../img/social/vk.png) no-repeat;
}
.social-icon.instagram {
  background: url(../img/social/instagram.png) no-repeat;
}
.social-icon.reddit {
  background: url(../img/social/reddit.png) no-repeat;
}
.social-icon.aboutme {
  background: url(../img/social/aboutme.png) no-repeat;
}
.social-icon.flickr {
  background: url(../img/social/flickr.png) no-repeat;
}
.social-icon.foursquare {
  background: url(../img/social/foursquare.png) no-repeat;
}
.social-icon.gravatar {
  background: url(../img/social/gravatar.png) no-repeat;
}
.social-icon.klout {
  background: url(../img/social/klout.png) no-repeat;
}
.social-icon.myspace {
  background: url(../img/social/myspace.png) no-repeat;
}
.social-icon.quora {
  background: url(../img/social/quora.png) no-repeat;
}
.social-icon:hover {
  background-position: 0 -38px;
}

.social-icon-color {
  opacity: 0.7;
  background-position: 0 -38px !important;
}
.social-icon-color:hover {
  opacity: 1;
}

/***
Customized Bootstrap Tables
***/
/***
Default table
***/
.table.table-bordered thead > tr > th {
  border-bottom: 0;
}
.table tr.heading > th {
  background-color: #eee !important;
}
.table td .img-responsive {
  width: 100%;
}

/* Contextual table row variants */
.table > thead > tr > td.active,
.table > thead > tr > th.active, .table > thead > tr.active > td, .table > thead > tr.active > th,
.table > tbody > tr > td.active,
.table > tbody > tr > th.active,
.table > tbody > tr.active > td,
.table > tbody > tr.active > th,
.table > tfoot > tr > td.active,
.table > tfoot > tr > th.active,
.table > tfoot > tr.active > td,
.table > tfoot > tr.active > th {
  background: #a6c8e6;
  color: #132339;
}

.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover, .table-hover > tbody > tr.active:hover > td, .table-hover > tbody > tr.active:hover > th {
  background: #92bce0;
}

.table > thead > tr > td.success,
.table > thead > tr > th.success, .table > thead > tr.success > td, .table > thead > tr.success > th,
.table > tbody > tr > td.success,
.table > tbody > tr > th.success,
.table > tbody > tr.success > td,
.table > tbody > tr.success > th,
.table > tfoot > tr > td.success,
.table > tfoot > tr > th.success,
.table > tfoot > tr.success > td,
.table > tfoot > tr.success > th {
  background: #dff0d8;
  color: #3c763d;
}

.table-hover > tbody > tr > td.success:hover,
.table-hover > tbody > tr > th.success:hover, .table-hover > tbody > tr.success:hover > td, .table-hover > tbody > tr.success:hover > th {
  background: #d0e9c6;
}

.table > thead > tr > td.info,
.table > thead > tr > th.info, .table > thead > tr.info > td, .table > thead > tr.info > th,
.table > tbody > tr > td.info,
.table > tbody > tr > th.info,
.table > tbody > tr.info > td,
.table > tbody > tr.info > th,
.table > tfoot > tr > td.info,
.table > tfoot > tr > th.info,
.table > tfoot > tr.info > td,
.table > tfoot > tr.info > th {
  background: #d9edf7;
  color: #31708f;
}

.table-hover > tbody > tr > td.info:hover,
.table-hover > tbody > tr > th.info:hover, .table-hover > tbody > tr.info:hover > td, .table-hover > tbody > tr.info:hover > th {
  background: #c4e3f3;
}

.table > thead > tr > td.warning,
.table > thead > tr > th.warning, .table > thead > tr.warning > td, .table > thead > tr.warning > th,
.table > tbody > tr > td.warning,
.table > tbody > tr > th.warning,
.table > tbody > tr.warning > td,
.table > tbody > tr.warning > th,
.table > tfoot > tr > td.warning,
.table > tfoot > tr > th.warning,
.table > tfoot > tr.warning > td,
.table > tfoot > tr.warning > th {
  background: #fcf8e3;
  color: #8a6d3b;
}

.table-hover > tbody > tr > td.warning:hover,
.table-hover > tbody > tr > th.warning:hover, .table-hover > tbody > tr.warning:hover > td, .table-hover > tbody > tr.warning:hover > th {
  background: #faf2cc;
}

.table > thead > tr > td.danger,
.table > thead > tr > th.danger, .table > thead > tr.danger > td, .table > thead > tr.danger > th,
.table > tbody > tr > td.danger,
.table > tbody > tr > th.danger,
.table > tbody > tr.danger > td,
.table > tbody > tr.danger > th,
.table > tfoot > tr > td.danger,
.table > tfoot > tr > th.danger,
.table > tfoot > tr.danger > td,
.table > tfoot > tr.danger > th {
  background: #f2dede;
  color: #a94442;
}

.table-hover > tbody > tr > td.danger:hover,
.table-hover > tbody > tr > th.danger:hover, .table-hover > tbody > tr.danger:hover > td, .table-hover > tbody > tr.danger:hover > th {
  background: #ebcccc;
}

/***
Responsive & Scrollable Tables
***/
.table-scrollable {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  border: 1px solid #dddddd;
  margin: 10px 0 !important;
}
.table-scrollable.table-scrollable-borderless {
  border: 0;
}
.table-scrollable > .table {
  width: 100% !important;
  margin: 0 !important;
  margin-bottom: 0;
  background-color: #fff;
}
.table-scrollable > .table > thead > tr > th,
.table-scrollable > .table > tbody > tr > th,
.table-scrollable > .table > tfoot > tr > th,
.table-scrollable > .table > tfoot > tr > th,
.table-scrollable > .table > tfoot > tr > td {
  white-space: nowrap;
}
.table-scrollable > .table-bordered {
  border: 0;
}
.table-scrollable > .table-bordered > thead > tr > th:first-child,
.table-scrollable > .table-bordered > tbody > tr > th:first-child,
.table-scrollable > .table-bordered > tfoot > tr > th:first-child,
.table-scrollable > .table-bordered > thead > tr > td:first-child,
.table-scrollable > .table-bordered > tbody > tr > td:first-child,
.table-scrollable > .table-bordered > tfoot > tr > td:first-child {
  border-left: 0;
}
.table-scrollable > .table-bordered > thead > tr > th:last-child,
.table-scrollable > .table-bordered > tbody > tr > th:last-child,
.table-scrollable > .table-bordered > tfoot > tr > th:last-child,
.table-scrollable > .table-bordered > thead > tr > td:last-child,
.table-scrollable > .table-bordered > tbody > tr > td:last-child,
.table-scrollable > .table-bordered > tfoot > tr > td:last-child {
  border-right: 0;
}
.table-scrollable > .table-bordered > thead > tr:last-child > th,
.table-scrollable > .table-bordered > tbody > tr:last-child > th,
.table-scrollable > .table-bordered > tfoot > tr:last-child > th,
.table-scrollable > .table-bordered > thead > tr:last-child > td,
.table-scrollable > .table-bordered > tbody > tr:last-child > td,
.table-scrollable > .table-bordered > tfoot > tr:last-child > td {
  border-bottom: 0;
}

/***
Responsive Flip Scroll Tables
***/
.flip-scroll table {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  /* 768px */
  .flip-scroll .flip-content:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }

  .flip-scroll * html .flip-content {
    zoom: 1;
  }

  .flip-scroll *:first-child + html .flip-content {
    zoom: 1;
  }

  .flip-scroll table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }

  .flip-scroll th,
  .flip-scroll td {
    margin: 0;
    vertical-align: top;
  }

  .flip-scroll th {
    text-align: left;
    border: 0 !important;
    border-bottom: 1px solid #ddd !important;
    border-right: 1px solid #ddd !important;
    font-size: 13px !important;
    padding: 5px;
    width: auto !important;
  }

  .flip-scroll table {
    display: block;
    position: relative;
    width: 100%;
  }

  .flip-scroll thead {
    display: block;
    float: left;
  }

  .flip-scroll tbody {
    display: block;
    width: auto;
    position: relative;
    overflow-x: auto;
    white-space: nowrap;
  }

  .flip-scroll .flip-content tbody tr td {
    font-size: 13px;
    line-height: 1.483;
  }

  .flip-scroll .table-bordered.flip-content tbody tr td {
    font-size: 13px;
    line-height: 1.43;
  }

  .flip-scroll thead tr {
    display: block;
  }

  .flip-scroll th {
    display: block;
    text-align: right;
  }

  .flip-scroll tbody tr {
    display: inline-block;
    vertical-align: top;
    margin-left: -5px;
  }

  .flip-scroll td {
    display: block;
    min-height: 1.25em;
    text-align: left;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
  }

  /* sort out borders */
  .flip-scroll th {
    border-bottom: 0;
    border-left: 0;
  }

  .flip-scroll td {
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
  }

  .flip-scroll tbody tr {
    border-left: 1px solid #ddd;
  }

  .flip-scroll th:last-child,
  .flip-scroll td:last-child {
    border-bottom: 1px solid #ddd;
  }
}
/***
Custom tables
***/
.table-toolbar {
  margin-bottom: 15px;
}
.table-toolbar:before, .table-toolbar:after {
  content: " ";
  display: table;
}
.table-toolbar:after {
  clear: both;
}

.table.table-full-width {
  width: 100% !important;
}

.table .btn {
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 5px;
}

.table thead tr th {
  font-size: 14px;
  font-weight: 600;
}

.table-advance {
  margin-bottom: 10px !important;
}

.table-advance thead {
  color: #999;
}

.table-advance thead tr th {
  background-color: #DDD;
  font-size: 14px;
  font-weight: 400;
  color: #666;
}

.table-advance div.success,
.table-advance div.info,
.table-advance div.important,
.table-advance div.warning,
.table-advance div.danger {
  position: absolute;
  margin-top: -5px;
  float: left;
  width: 2px;
  height: 30px;
  margin-right: 20px !important;
}

.table-advance tr td {
  border-left-width: 0px;
}

.table-advance tr td:first-child {
  border-left-width: 1px !important;
}

.table-advance tr td.highlight:first-child a {
  margin-left: 15px;
}

.table-advance td.highlight div.primary {
  border-left: 2px solid #428bca;
}

.table-advance td.highlight div.success {
  border-left: 2px solid #45B6AF;
}

.table-advance td.highlight div.info {
  border-left: 2px solid #89C4F4;
}

.table-advance td.highlight div.warning {
  border-left: 2px solid #dfba49;
}

.table-advance td.highlight div.danger {
  border-left: 2px solid #F3565D;
}

@media (max-width: 767px) {
  /* 767px */
  .table-advance tr > td.highlight:first-child a {
    margin-left: 8px;
  }
}
/***
Light Table
***/
.table.table-light {
  border: 0 !important;
}
.table.table-light > thead > tr:hover > th {
  background: none;
}
.table.table-light > thead > tr.uppercase {
  text-transform: uppercase;
}
.table.table-light > thead > tr > th {
  font-weight: 600;
  font-size: 13px;
  color: #93a2a9;
  font-family: "Open Sans", sans-serif;
  border: 0;
  border-bottom: 1px solid #F2F5F8;
}
.table.table-light > tbody > tr:last-child > td {
  border: 0;
}
.table.table-light > tbody > tr > td {
  border: 0;
  border-bottom: 1px solid #F2F5F8;
  color: #8896a0;
  vertical-align: middle;
}
.table.table-light > tbody > tr > td.fit {
  width: 1px;
  padding-right: 3px;
}
.table.table-light > tbody > tr > td .user-pic {
  display: inline-block;
  vertical-align: middle;
  height: 30px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
}
.table.table-light.table-hover > tbody > tr > td:hover,
.table.table-light.table-hover > tbody > tr > th:hover, .table.table-light.table-hover > tbody > tr:hover > td, .table.table-light.table-hover > tbody > tr:hover > th {
  background: #f9fafb;
}

/***
Customized Bootstrap Tabs 
***/
/* Tabs and pills */
.nav-tabs,
.nav-pills {
  margin-bottom: 10px;
}
.nav-tabs > li > a,
.nav-pills > li > a {
  font-size: 14px;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -ms-border-radius: 4px 4px 0 0;
  -o-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
.nav-tabs > li > a > .badge,
.nav-pills > li > a > .badge {
  margin-top: -6px;
}
.nav-tabs > li .dropdown-menu:before, .nav-tabs > li .dropdown-menu:after,
.nav-pills > li .dropdown-menu:before,
.nav-pills > li .dropdown-menu:after {
  display: none;
}
.nav-tabs.nav-tabs-sm > li > a, .nav-tabs.nav-pills-sm > li > a,
.nav-pills.nav-tabs-sm > li > a,
.nav-pills.nav-pills-sm > li > a {
  font-size: 13px;
}
.nav-tabs .dropdown.open > .dropdown-toggle,
.nav-pills .dropdown.open > .dropdown-toggle {
  background: #eee;
  color: #0d638f;
  border-color: transparent;
}

/* Left and right tabs */
.tabs-right.nav-tabs,
.tabs-left.nav-tabs {
  border-bottom: 0;
}
.tabs-right.nav-tabs > li,
.tabs-left.nav-tabs > li {
  float: none;
}
.tabs-right.nav-tabs > li > a,
.tabs-left.nav-tabs > li > a {
  margin-right: 0;
  margin-bottom: 3px;
}

/* Left tabs */
.tabs-left.nav-tabs {
  border-right: 1px solid #ddd;
}
.tabs-left.nav-tabs > li > a {
  display: block;
  margin-right: -1px;
}
.tabs-left.nav-tabs > li > a:hover, .tabs-left.nav-tabs > li > a:focus {
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  -ms-border-radius: 4px 0 0 4px;
  -o-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
  border-color: #eeeeee #dddddd #eeeeee #eeeeee;
}
.tabs-left.nav-tabs > li.active > a,
.tabs-left.nav-tabs > li.active > a:hover
> li.active > a:focus {
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  -ms-border-radius: 4px 0 0 4px;
  -o-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
  border-color: #ddd transparent #ddd #ddd;
  *border-right-color: #ffffff;
}

/* Right tabs */
.tabs-right.nav-tabs {
  border-left: 1px solid #ddd;
}
.tabs-right.nav-tabs > li > a {
  display: block;
  margin-left: -1px;
}
.tabs-right.nav-tabs > li > a:hover, .tabs-right.nav-tabs > li > a:focus {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  -ms-border-radius: 0 4px 4px 0;
  -o-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  border-color: #eeeeee #eeeeee #eeeeee #dddddd;
}
.tabs-right.nav-tabs > li.active > a,
.tabs-right.nav-tabs > li.active > a:hover
> li.active > a:focus {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  -ms-border-radius: 0 4px 4px 0;
  -o-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  border-color: #ddd #ddd #ddd transparent;
  *border-left-color: #ffffff;
}

/* Below tabs */
.tabs-below > .nav-tabs,
.tabs-below > .nav-pills {
  border-bottom: 0;
  margin-bottom: 0px;
  margin-top: 10px;
}

.tabs-below > .nav-tabs {
  border-top: 1px solid #ddd;
  margin-bottom: 0;
  margin-top: 10px;
}
.tabs-below > .nav-tabs > li > a {
  margin-top: -1px;
  margin-bottom: 0;
}
.tabs-below > .nav-tabs > li > a:hover, .tabs-below > .nav-tabs > li > a:focus {
  border-top-color: #ddd;
  border-bottom-color: transparent;
}
.tabs-below > .nav-tabs > li > a .dropdown-menu {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}
.tabs-below > .nav-tabs .active a,
.tabs-below > .nav-tabs .active a:hover
.active a:focus {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -ms-border-radius: 0 0 4px 4px;
  -o-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  border-color: transparent #ddd #ddd #ddd  !important;
}

/***
Custom tabs
***/
/* In BS3.0.0 tabbable class was removed. We had to added it back */
.tabbable:before, .tabbable:after {
  content: " ";
  display: table;
}
.tabbable:after {
  clear: both;
}

.tabbable-custom {
  margin-bottom: 15px;
  padding: 0px;
  overflow: hidden;
  /* justified tabs */
  /* boxless tabs */
  /* below justified tabs */
  /* full width tabs */
  /* below tabs */
}
.tabbable-custom > .nav-tabs {
  border: none;
  margin: 0px;
}
.tabbable-custom > .nav-tabs > li {
  margin-right: 2px;
  border-top: 2px solid transparent;
}
.tabbable-custom > .nav-tabs > li > a {
  margin-right: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}
.tabbable-custom > .nav-tabs > li > a:hover {
  background: none;
  border-color: transparent;
}
.tabbable-custom > .nav-tabs > li.active {
  border-top: 3px solid #F3565D;
  margin-top: 0;
  position: relative;
}
.tabbable-custom > .nav-tabs > li.active > a {
  border-top: none !important;
  font-weight: 400;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}
.tabbable-custom > .nav-tabs > li.active > a:hover {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  border-top: none;
  background: #fff;
  border-color: #d4d4d4 #d4d4d4 transparent;
}
.tabbable-custom > .tab-content {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px;
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  -ms-border-radius: 0 0 4px 4px;
  -o-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}
.tabbable-custom.nav-justified > .tab-content {
  margin-top: -1px;
}
.tabbable-custom.boxless > .tab-content {
  padding: 15px 0;
  border-left: none;
  border-right: none;
  border-bottom: none;
}
.tabbable-custom.tabs-below.nav-justified .tab-content {
  margin-top: 0px;
  margin-bottom: -2px;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -ms-border-radius: 4px 4px 0 0;
  -o-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
.tabbable-custom.tabbable-full-width > .nav-tabs > li > a {
  color: #424242;
  font-size: 15px;
  padding: 9px 15px;
}
.tabbable-custom.tabbable-full-width > .tab-content {
  padding: 15px 0;
  border-left: none;
  border-right: none;
  border-bottom: none;
}
.tabbable-custom.tabs-below .nav-tabs > li > a {
  border-top: none;
  border-bottom: 2px solid transparent;
  margin-top: -1px;
}
.tabbable-custom.tabs-below .nav-tabs > li.active {
  border-top: none;
  border-bottom: 3px solid #d12610;
  margin-bottom: 0;
  position: relative;
}
.tabbable-custom.tabs-below .nav-tabs > li.active > a {
  border-bottom: none;
}
.tabbable-custom.tabs-below .nav-tabs > li.active > a:hover {
  background: #fff;
  border-color: #d4d4d4 #d4d4d4 transparent;
}

.tabbable-custom.tabbable-noborder > .nav-tabs > li > a {
  border: 0;
}
.tabbable-custom.tabbable-noborder .tab-content {
  border: 0;
}

.tabbable-line > .nav-tabs {
  border: none;
  margin: 0px;
}
.tabbable-line > .nav-tabs > li {
  margin-right: 2px;
}
.tabbable-line > .nav-tabs > li > a {
  border: 0;
  margin-right: 0;
  color: #737373;
}
.tabbable-line > .nav-tabs > li > a > i {
  color: #a6a6a6;
}
.tabbable-line > .nav-tabs > li.open, .tabbable-line > .nav-tabs > li:hover {
  border-bottom: 4px solid #fbcdcf;
}
.tabbable-line > .nav-tabs > li.open > a, .tabbable-line > .nav-tabs > li:hover > a {
  border: 0;
  background: none !important;
  color: #333;
}
.tabbable-line > .nav-tabs > li.open > a > i, .tabbable-line > .nav-tabs > li:hover > a > i {
  color: #a6a6a6;
}
.tabbable-line > .nav-tabs > li.open .dropdown-menu, .tabbable-line > .nav-tabs > li:hover .dropdown-menu {
  margin-top: 0px;
}
.tabbable-line > .nav-tabs > li.active {
  border-bottom: 4px solid #F3565D;
  position: relative;
}
.tabbable-line > .nav-tabs > li.active > a {
  border: 0;
  color: #333;
}
.tabbable-line > .nav-tabs > li.active > a > i {
  color: #404040;
}
.tabbable-line > .tab-content {
  margin-top: -3px;
  background-color: #fff;
  border: 0;
  border-top: 1px solid #eee;
  padding: 15px 0;
}
.portlet .tabbable-line > .tab-content {
  padding-bottom: 0;
}

.tabbable-line.tabs-below > .nav-tabs > li {
  border-top: 4px solid transparent;
}
.tabbable-line.tabs-below > .nav-tabs > li > a {
  margin-top: 0;
}
.tabbable-line.tabs-below > .nav-tabs > li:hover {
  border-bottom: 0;
  border-top: 4px solid #fbcdcf;
}
.tabbable-line.tabs-below > .nav-tabs > li.active {
  margin-bottom: -2px;
  border-bottom: 0;
  border-top: 4px solid #F3565D;
}
.tabbable-line.tabs-below > .tab-content {
  margin-top: -10px;
  border-top: 0;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
}

/***
Tiles(new in v1.1.1)
***/
.tiles {
  margin-right: -10px;
}
.tiles:before, .tiles:after {
  display: table;
  content: " ";
}
.tiles:after {
  clear: both;
}
.tiles .tile {
  display: block;
  letter-spacing: 0.02em;
  float: left;
  height: 135px;
  width: 135px !important;
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
  position: relative;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 0.02em;
  line-height: 20px;
  overflow: hidden;
  border: 4px solid transparent;
  margin: 0 10px 10px 0;
}
.tiles .tile:after, .tiles .tile:before {
  content: "";
  float: left;
}
.tiles .tile.double {
  width: 280px !important;
}
.tiles .tile.double-down {
  height: 280px !important;
}
.tiles .tile.double-down i {
  margin-top: 95px;
}
.tiles .tile:hover {
  border-color: #aaa !important;
}
.tiles .tile:active, .tiles .tile.selected {
  border-color: #ccc !important;
}
.tiles .tile.selected .corner:after {
  content: "";
  display: inline-block;
  border-left: 40px solid transparent;
  border-bottom: 40px solid transparent;
  border-right: 40px solid #ccc;
  position: absolute;
  top: -3px;
  right: -3px;
}
.tiles .tile.selected .check:after {
  content: "";
  font-family: FontAwesome;
  font-size: 13px;
  content: "\f00c";
  display: inline-block;
  position: absolute;
  top: 2px;
  right: 2px;
}
.tiles .tile.icon {
  padding: 0;
}
.tiles .tile.image .tile-body {
  padding: 0 !important;
}
.tiles .tile.image .tile-body > img {
  width: 100%;
  height: auto;
  min-height: 100%;
  max-width: 100%;
}
.tiles .tile.image .tile-body h3 {
  display: inline-block;
}
.tiles .tile .tile-body {
  height: 100%;
  vertical-align: top;
  padding: 10px 10px;
  overflow: hidden;
  position: relative;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  color: #ffffff;
  margin-bottom: 10px;
}
.tiles .tile .tile-body p {
  font-weight: 400;
  font-size: 13px;
  color: #000000;
  color: #ffffff;
  line-height: 20px;
  overflow: hidden;
}
.tiles .tile .tile-body p:hover {
  color: rgba(0, 0, 0, 0.8);
}
.tiles .tile .tile-body p:active {
  color: rgba(0, 0, 0, 0.4);
}
.tiles .tile .tile-body p:hover {
  color: #ffffff;
}
.tiles .tile .tile-body img {
  float: left;
  margin-right: 10px;
}
.tiles .tile .tile-body img.pull-right {
  float: right !important;
  margin-left: 10px;
  margin-right: 0px;
}
.tiles .tile .tile-body > .content {
  display: inline-block;
}
.tiles .tile .tile-body > i {
  margin-top: 17px;
  display: block;
  font-size: 56px;
  line-height: 56px;
  text-align: center;
}
.tiles .tile .tile-body h1,
.tiles .tile .tile-body h2,
.tiles .tile .tile-body h3,
.tiles .tile .tile-body h4,
.tiles .tile .tile-body h5,
.tiles .tile .tile-body h6,
.tiles .tile .tile-body p {
  padding: 0;
  margin: 0;
  line-height: 14px;
}
.tiles .tile .tile-body h1:hover,
.tiles .tile .tile-body h2:hover,
.tiles .tile .tile-body h3:hover,
.tiles .tile .tile-body h4:hover,
.tiles .tile .tile-body h5:hover,
.tiles .tile .tile-body h6:hover,
.tiles .tile .tile-body p:hover {
  color: #ffffff;
}
.tiles .tile .tile-body h3,
.tiles .tile .tile-body h4 {
  margin-bottom: 5px;
}
.tiles .tile .tile-object {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 30px;
  background-color: transparent;
  *zoom: 1;
}
.tiles .tile .tile-object:before, .tiles .tile .tile-object:after {
  display: table;
  content: "";
}
.tiles .tile .tile-object:after {
  clear: both;
}
.tiles .tile .tile-object > .name {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 15px;
  font-weight: 400;
  font-size: 13px;
  color: #ffffff;
}
.tiles .tile .tile-object > .name > i {
  vertical-align: middle;
  display: block;
  font-size: 24px;
  height: 18px;
  width: 24px;
}
.tiles .tile .tile-object > .number {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 0;
  color: #ffffff;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.01em;
  line-height: 14px;
  margin-bottom: 8px;
  margin-right: 10px;
}

/***
Custimized Bootstrap Wells
***/
.well {
  background-color: #eee;
  border: 0;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

/*--------------------------------------------------
	[Widgets]
----------------------------------------------------*/
/*** Widget Background Colors ***/
.widget-bg-color-purple {
  background: #9a7caf;
}

.widget-bg-color-purple-dark {
  background: #4b365a;
}

.widget-bg-color-purple-light {
  background: #674d79;
}

.widget-bg-color-green {
  background: #4db3a4;
}

.widget-bg-color-red {
  background: #f36a5a;
}

.widget-bg-color-blue {
  background: #5b9bd1;
}

.widget-bg-color-gray {
  background: #323c45;
}

.widget-bg-color-gray-dark {
  background: #144f57;
}

.widget-bg-color-white {
  background: #fff;
}

.widget-bg-color-dark {
  background: #3e4f5e;
}

.widget-bg-color-dark-light {
  background: #8e9daa;
}

.widget-bg-color-fb {
  background: #475e98;
}

.widget-bg-color-tw {
  background: #55acee;
}

/*** Widget Title Colors ***/
.widget-title-color-purple {
  color: #9a7caf;
}

.widget-title-color-purple-dark {
  color: #4b365a;
}

.widget-title-color-purple-light {
  color: #674d79;
}

.widget-title-color-green {
  color: #4db3a4;
}

.widget-title-color-red {
  color: #f36a5a;
}

.widget-title-color-blue {
  color: #5b9bd1;
}

.widget-title-color-gray {
  color: #323c45;
}

.widget-title-color-gray-dark {
  color: #144f57;
}

.widget-title-color-white {
  color: #fff;
}

.widget-title-color-dark {
  color: #3e4f5e;
}

.widget-title-color-dark-light {
  color: #8e9daa;
}

.widget-title-color-fb {
  color: #475e98;
}

.widget-title-color-tw {
  color: #55acee;
}

.overflow-h {
  overflow: hidden;
}

/*** Widget Carousel ***/
.widget-carousel .carousel-indicators {
  left: -18%;
  bottom: 10px;
  margin-left: 0;
}
.widget-carousel .carousel-indicators-red > li {
  border-color: #f36a5a;
}
.widget-carousel .carousel-indicators-red > li.active {
  background: #f36a5a;
}

/*** Widget Gradient ***/
.widget-gradient {
  position: relative;
  min-height: 350px;
  overflow: hidden;
}
.widget-gradient .widget-gradient-body {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  padding: 20px;
}
.widget-gradient .widget-gradient-body .widget-gradient-title {
  font-size: 21px;
  font-weight: 600;
  color: #fff;
  margin: 0;
}
.widget-gradient .widget-gradient-body .widget-gradient-body-actions {
  position: absolute;
  right: 20px;
  bottom: 20px;
  padding: 0;
  margin: 0;
}
.widget-gradient .widget-gradient-body .widget-gradient-body-actions li {
  font-size: 14px;
  padding: 0 0 0 8px;
}
.widget-gradient .widget-gradient-body .widget-gradient-body-actions li:first-child {
  padding-left: 0;
}
.widget-gradient .widget-gradient-body .widget-gradient-body-actions li a {
  color: #fff;
}
.widget-gradient .widget-gradient-body .widget-gradient-body-actions li a:hover {
  color: #a1afbb;
  text-decoration: none;
}

.widget-gradient.widget-gradient-img-v1 {
  background: url(../../../assets/admin/layout7/img/02.jpg);
  background-size: cover;
  background-position: 50% 50%;
}

.widget-gradient.widget-gradient-img-v2 {
  background: url(../../../assets/admin/layout7/img/01.jpg);
  background-size: cover;
  background-position: 50% 50%;
}

.widget-gradient.widget-gradient-img-v3 {
  background: url(../../../assets/admin/layout7/img/06.jpg);
  background-size: cover;
  background-position: 50% 50%;
}

.widget-gradient.widget-gradient-img-v4 {
  background: url(../../../assets/admin/layout7/img/03.jpg);
  background-size: cover;
  background-position: 50% 50%;
}

.widget-gradient.widget-gradient-img-v5 {
  background: url(../../../assets/admin/layout7/img/04.jpg);
  background-size: cover;
  background-position: 50% 50%;
}

/*** Widget Gradient ***/
.widget-wrap-img {
  position: relative;
  min-height: 350px;
  padding: 20px;
}
.widget-wrap-img .widget-wrap-img-title {
  font-size: 21px;
  font-weight: 600;
  color: #3e4f5e;
  margin: 0 0 20px;
}
.widget-wrap-img .widget-wrap-img-element {
  position: absolute;
  bottom: 0;
  right: 0;
}

/*** Widget Tab ***/
.widget-tab {
  min-height: 420px;
}
.widget-tab .nav-tabs {
  margin: 0;
  border-color: #eff1f3;
}
.widget-tab .nav-tabs > li {
  margin: 0 10px;
}
.widget-tab .nav-tabs > li:first-child {
  margin-left: 20px;
}
.widget-tab .nav-tabs > li > a {
  border: 0;
  font-weight: bold;
  color: #8e9daa;
  text-transform: uppercase;
  padding: 20px 0;
}
.widget-tab .nav-tabs > li > a > i {
  color: #8e9daa;
}
.widget-tab .nav-tabs > li.open, .widget-tab .nav-tabs > li:hover {
  border-bottom: 1px solid #f36a5a;
}
.widget-tab .nav-tabs > li.open > a, .widget-tab .nav-tabs > li:hover > a {
  border: 0;
  background: inherit;
  color: #f36a5a;
}
.widget-tab .nav-tabs > li.open > a > i, .widget-tab .nav-tabs > li:hover > a > i {
  color: #f36a5a;
}
.widget-tab .nav-tabs > li.active {
  border-bottom: 1px solid #f36a5a;
  position: relative;
}
.widget-tab .nav-tabs > li.active > a {
  border: 0;
  color: #f36a5a;
}
.widget-tab .nav-tabs > li.active > a > i {
  color: #f36a5a;
}
.widget-tab .tab-content {
  padding: 20px;
  color: #8e9daa;
}
.widget-tab .slimScrollBar {
  right: 10px !important;
  margin-top: 17px !important;
  margin-bottom: 17px !important;
}

/*** Widget News ***/
.widget-news {
  overflow: hidden;
  margin-right: 10px;
}
.widget-news .widget-news-left-elem {
  float: left;
  width: 100px;
  height: auto;
  margin-right: 15px;
}
.widget-news .widget-news-right-body {
  overflow: hidden;
}
.widget-news .widget-news-right-body .widget-news-right-body-title {
  font-size: 16px;
  font-weight: 600;
  color: #3e4f5e;
  margin: 0 0 5px;
  clear: both;
}
.widget-news .widget-news-right-body .widget-news-right-body-title .label {
  float: right;
  font-weight: 600;
  background: #a1afbb;
  border-radius: 3px !important;
}
.widget-news .widget-news-right-body p {
  font-size: 13px;
}

/*** Widget Thumb ***/
.widget-thumb {
  padding: 20px;
}
.widget-thumb .widget-thumb-heading {
  font-size: 14px;
  font-weight: bold;
  color: #8e9daa;
  margin: 0 0 20px 0;
}
.widget-thumb .widget-thumb-wrap {
  overflow: hidden;
}
.widget-thumb .widget-thumb-wrap .widget-thumb-icon {
  float: left;
  width: 60px;
  height: 60px;
  display: inline-block;
  font-size: 20px;
  line-height: 41px;
  color: #fff;
  text-align: center;
  padding: 10px;
  margin-right: 15px;
}
.widget-thumb .widget-thumb-body {
  overflow: hidden;
}
.widget-thumb .widget-thumb-body .widget-thumb-subtitle {
  padding-top: 2px;
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #8e9daa;
}
.widget-thumb .widget-thumb-body .widget-thumb-body-stat {
  display: block;
  font-size: 30px;
  font-weight: 600;
  color: #3e4f5e;
}

/*** Widget Socials ***/
.widget-socials {
  min-height: 250px;
  padding: 20px;
}
.widget-socials .widget-socials-title {
  font-size: 25px;
  font-weight: 700;
  line-height: 1.4;
  color: #fff;
  margin: 0 0 20px;
}
.widget-socials .widget-social-subtitle {
  color: #fff;
  font-weight: 200;
  line-height: 1.4;
}
.widget-socials .widget-social-subtitle a {
  color: #fff;
}
.widget-socials .widget-socials-paragraph {
  display: block;
  color: #65727d;
}
.widget-socials .widget-social-icon-fb, .widget-socials .widget-social-icon-tw {
  font-size: 30px;
  margin: 30px 0;
}
.widget-socials .widget-social-icon-fb {
  color: #2b3f72;
}
.widget-socials .widget-social-icon-tw {
  color: #3686c3;
}

/*** Widget Comments ***/
.widget-comments {
  min-height: 420px;
}

/*** Widget Media ***/
.widget-media {
  border-bottom: 1px solid #f6f9fc;
  overflow: hidden;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.widget-media .widget-media-elements {
  float: left;
  margin-right: 20px;
}
.widget-media .widget-media-avatar {
  width: 55px;
  height: 55px;
  display: block;
}
.widget-media .widget-btn-default {
  display: inline-block;
  font-size: 12px;
  color: #96a2b1;
  border: 1px solid #ebf0f6;
  padding: 3px 10px;
}
.widget-media .widget-btn-default .widget-btn-icon {
  line-height: 1.5;
}
.widget-media .widget-btn-default:hover {
  background: #ebf0f6;
  text-decoration: none;
}
.widget-media .widget-btn-red {
  display: inline-block;
  font-size: 12px;
  color: #f36a5a;
  border: 1px solid #ebf0f6;
  padding: 3px 10px;
}
.widget-media .widget-btn-red .widget-btn-icon {
  line-height: 1.5;
}
.widget-media .widget-btn-red:hover {
  color: #fff;
  background: #f36a5a;
  text-decoration: none;
}
.widget-media .widget-btn-blue {
  display: inline-block;
  font-size: 12px;
  color: #fff;
  border: 1px solid #ebf0f6;
  padding: 3px 10px;
  background: #5b9bd1;
}
.widget-media .widget-btn-blue .widget-btn-icon {
  line-height: 1.5;
}
.widget-media .widget-btn-blue:hover {
  color: #5b9bd1;
  background: #fff;
  text-decoration: none;
}
.widget-media .widget-media-body {
  overflow: hidden;
}
.widget-media .widget-media-body .widget-media-body-title {
  font-size: 15px;
  font-weight: 600;
  color: #5b9bd1;
  margin: 0 0 7px;
}
.widget-media .widget-media-body .widget-media-body-subtitle {
  font-size: 13px;
  color: #7e8c9e;
}

/*** Widget Blog ***/
.widget-blog {
  background: #fff;
  padding: 20px;
  background-position: center center;
  background-size: cover;
  padding-top: 30px;
}
.widget-blog .widget-blog-heading {
  position: relative;
  margin-bottom: 30px;
}
.widget-blog .widget-blog-heading:before {
  position: absolute;
  bottom: -15px;
  left: 50%;
  width: 50px;
  height: 1px;
  border-width: 1px;
  background: #8e9daa;
  margin-left: -25px;
  content: " ";
}
.widget-blog .widget-blog-title {
  font-size: 20px;
  font-weight: 400;
  color: #3e4f5e;
  margin: 0 0 15px;
}
.widget-blog .widget-blog-title a {
  color: #3e4f5e;
}
.widget-blog .widget-blog-subtitle {
  display: block;
  font-size: 13px;
  color: #8e9daa;
  letter-spacing: 3px;
}
.widget-blog .btn-widget-purple {
  display: inline-block;
  font-size: 13px;
  color: #8e9daa;
  border: 1px solid #8e9daa;
  padding: 7px 17px;
}
.widget-blog .btn-widget-purple:hover {
  color: #fff;
  background: #8e9daa;
  text-decoration: none;
}

/*** Widget Progress ***/
.widget-progress {
  min-height: 420px;
}
.widget-progress .widget-progress-element {
  overflow: hidden;
  padding: 30px 10px;
}
.widget-progress .widget-progress-title {
  display: block;
  color: #fff;
  margin-bottom: 5px;
}
.widget-progress .progress {
  height: 3px;
  background: rgba(255, 255, 255, 0.2);
  margin-bottom: 0;
}

/*** Widget Gradient ***/
.widget-map {
  min-height: 350px;
  border-top-right-radius: 3px !important;
  border-top-left-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
}
.widget-map .widget-map-mapplic {
  border-top-right-radius: 3px !important;
  border-top-left-radius: 3px !important;
}
.widget-map .widget-map-mapplic .mapplic-container {
  background: #5b9bd1;
}
.widget-map .widget-map-mapplic .mapplic-layer.world > img {
  opacity: .3;
}
.widget-map .widget-map-body {
  background: #fff;
  border-bottom-right-radius: 3px !important;
  border-bottom-left-radius: 3px !important;
  padding: 20px;
  overflow: hidden;
}
.widget-map .widget-sparkline-chart {
  width: 25%;
  float: left;
  border-left: 1px solid #e7eff7;
  padding: 0 15px;
}
.widget-map .widget-sparkline-chart:first-child {
  border-left: none;
}
.widget-map .widget-sparkline-chart .widget-sparkline-title {
  display: block;
  font-size: 12px;
  font-weight: 600;
  color: #a1afbb;
}

/* Widget Map for max-width 480px */
@media (max-width: 480px) {
  /* 480px */
  .widget-map .widget-sparkline-chart {
    width: 50%;
    border-left: none;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
/*** Widget Subscribe ***/
.widget-subscribe {
  min-height: 250px;
  overflow: hidden;
  padding: 30px;
}
.widget-subscribe .widget-subscribe-no {
  float: left;
  font-size: 67px;
  font-weight: 600;
  line-height: 1;
  color: #9a7caf;
}
.widget-subscribe .widget-subscribe-title {
  font-size: 25px;
  font-weight: 700;
  line-height: 1.4;
  margin: 0 0 15px 45px;
}
.widget-subscribe .widget-subscribe-subtitle {
  font-size: 15px;
  font-weight: 600;
}
.widget-subscribe .widget-subscribe-subtitle-link {
  color: #cab0dd;
}
.widget-subscribe.widget-subscribe-quote {
  position: relative;
}
.widget-subscribe.widget-subscribe-quote:before {
  position: absolute;
  top: 2px;
  font-size: 70px;
  color: #fff;
  content: "\201C";
}

/* Widget Subscribe for media queries */
@media (max-width: 767px) {
  /* 767px */
  .widget-subscribe.widget-subscribe-border {
    border-top: 1px solid #f5f8fb;
    border-bottom: 1px solid #f5f8fb;
    border-right: none;
  }
}
@media (min-width: 768px) {
  /* 768px */
  .widget-subscribe.widget-subscribe-border {
    border-left: 1px solid #f5f8fb;
    border-right: 1px solid #f5f8fb;
  }
}
@media (min-width: 767px) and (max-width: 991px) {
  /* 767px & 991px */
  .widget-subscribe.widget-subscribe-border {
    border-left: none;
  }
  .widget-subscribe.widget-subscribe-border-top {
    border-top: 1px solid #f5f8fb;
  }
}
/*--------------------------------------------------
    [Material Design]
----------------------------------------------------*/
.page-md .breadcrumbs {
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}
.page-md .widget-bg-color-white,
.page-md .widget-map,
.page-md .widget-carousel,
.page-md .widget-progress-element,
.page-md .widget-socials,
.page-md .widget-blog {
  box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.1);
}

/***
AngularJS Basic Animations
***/
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(15px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(15px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(15px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fade-in-up {
  -webkit-animation: fadeInUp .5s;
  animation: fadeInUp .5s;
}

@-webkit-keyframes bounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes bounceDelay {
  0%, 80%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  40% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
@keyframes input-focus {
  0% {
    left: 20%;
    width: 20%;
  }
  99% {
    width: 0;
    left: 0;
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* Button */
.btn.default {
  color: #333333;
  background-color: #E5E5E5;
}
.btn.default:hover, .btn.default:focus, .btn.default:active, .btn.default.active {
  color: #333333;
  background-color: lightgray;
}
.open .btn.default.dropdown-toggle {
  color: #333333;
  background-color: lightgray;
}
.btn.default:active, .btn.default.active {
  background-image: none;
  background-color: #c6c6c6;
}
.btn.default:active:hover, .btn.default.active:hover {
  background-color: #cccccc;
}
.open .btn.default.dropdown-toggle {
  background-image: none;
}
.btn.default.disabled, .btn.default.disabled:hover, .btn.default.disabled:focus, .btn.default.disabled:active, .btn.default.disabled.active, .btn.default[disabled], .btn.default[disabled]:hover, .btn.default[disabled]:focus, .btn.default[disabled]:active, .btn.default[disabled].active, fieldset[disabled] .btn.default, fieldset[disabled] .btn.default:hover, fieldset[disabled] .btn.default:focus, fieldset[disabled] .btn.default:active, fieldset[disabled] .btn.default.active {
  background-color: #E5E5E5;
}
.btn.default .badge {
  color: #E5E5E5;
  background-color: #333333;
}

/**********
Blue Colors
***********/
/***
Blue Default
***/
/* Button */
.blue.btn {
  color: #FFFFFF;
  background-color: #3598dc;
}
.blue.btn:hover, .blue.btn:focus, .blue.btn:active, .blue.btn.active {
  color: #FFFFFF;
  background-color: #2386ca;
}
.open .blue.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #2386ca;
}
.blue.btn:active, .blue.btn.active {
  background-image: none;
  background-color: #1f78b5;
}
.blue.btn:active:hover, .blue.btn.active:hover {
  background-color: #217ebd;
}
.open .blue.btn.dropdown-toggle {
  background-image: none;
}
.blue.btn.disabled, .blue.btn.disabled:hover, .blue.btn.disabled:focus, .blue.btn.disabled:active, .blue.btn.disabled.active, .blue.btn[disabled], .blue.btn[disabled]:hover, .blue.btn[disabled]:focus, .blue.btn[disabled]:active, .blue.btn[disabled].active, fieldset[disabled] .blue.btn, fieldset[disabled] .blue.btn:hover, fieldset[disabled] .blue.btn:focus, fieldset[disabled] .blue.btn:active, fieldset[disabled] .blue.btn.active {
  background-color: #3598dc;
}
.blue.btn .badge {
  color: #3598dc;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.blue-stripe {
  border-left: 3px solid #3598dc;
}

/* Portlet */
.portlet > .portlet-body.blue,
.portlet.blue {
  background-color: #3598dc;
}

.portlet.solid.blue > .portlet-title,
.portlet.solid.blue > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.blue > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.blue > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.blue > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons//public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.blue > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.blue > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.blue > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.blue > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.blue > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.blue {
  border: 1px solid #60aee4;
  border-top: 0;
}
.portlet.box.blue > .portlet-title {
  background-color: #3598dc;
}
.portlet.box.blue > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.blue > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.blue > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #95c9ed;
  color: #aad4f0;
}
.portlet.box.blue > .portlet-title > .actions .btn-default > i {
  color: #b7daf3;
}
.portlet.box.blue > .portlet-title > .actions .btn-default:hover, .portlet.box.blue > .portlet-title > .actions .btn-default:focus, .portlet.box.blue > .portlet-title > .actions .btn-default:active, .portlet.box.blue > .portlet-title > .actions .btn-default.active {
  border: 1px solid #c0dff4;
  color: #d6eaf8;
}

/* Statistic Block */
.dashboard-stat.blue {
  background-color: #3598dc;
}
.dashboard-stat.blue.dashboard-stat-light:hover {
  background-color: #258fd7;
}
.dashboard-stat.blue .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.blue .details .number {
  color: #FFFFFF;
}
.dashboard-stat.blue .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.blue .more {
  color: #FFFFFF;
  background-color: #258fd7;
}

/* General Element */
.bg-blue {
  border-color: #3598dc !important;
  background-image: none !important;
  background-color: #3598dc !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-blue {
  color: #3598dc !important;
}

.progress-bar.blue {
  background: #3598dc !important;
  color: #FFFFFF !important;
}

/***
Blue Madison
***/
/* Button */
.blue-madison.btn {
  color: #FFFFFF;
  background-color: #578ebe;
}
.blue-madison.btn:hover, .blue-madison.btn:focus, .blue-madison.btn:active, .blue-madison.btn.active {
  color: #FFFFFF;
  background-color: #437cae;
}
.open .blue-madison.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #437cae;
}
.blue-madison.btn:active, .blue-madison.btn.active {
  background-image: none;
  background-color: #3c6f9c;
}
.blue-madison.btn:active:hover, .blue-madison.btn.active:hover {
  background-color: #3f74a3;
}
.open .blue-madison.btn.dropdown-toggle {
  background-image: none;
}
.blue-madison.btn.disabled, .blue-madison.btn.disabled:hover, .blue-madison.btn.disabled:focus, .blue-madison.btn.disabled:active, .blue-madison.btn.disabled.active, .blue-madison.btn[disabled], .blue-madison.btn[disabled]:hover, .blue-madison.btn[disabled]:focus, .blue-madison.btn[disabled]:active, .blue-madison.btn[disabled].active, fieldset[disabled] .blue-madison.btn, fieldset[disabled] .blue-madison.btn:hover, fieldset[disabled] .blue-madison.btn:focus, fieldset[disabled] .blue-madison.btn:active, fieldset[disabled] .blue-madison.btn.active {
  background-color: #578ebe;
}
.blue-madison.btn .badge {
  color: #578ebe;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.blue-madison-stripe {
  border-left: 3px solid #578ebe;
}

/* Portlet */
.portlet > .portlet-body.blue-madison,
.portlet.blue-madison {
  background-color: #578ebe;
}

.portlet.solid.blue-madison > .portlet-title,
.portlet.solid.blue-madison > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.blue-madison > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.blue-madison > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.blue-madison > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons//public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.blue-madison > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.blue-madison > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.blue-madison > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.blue-madison > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.blue-madison > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.blue-madison {
  border: 1px solid #7ca7cc;
  border-top: 0;
}
.portlet.box.blue-madison > .portlet-title {
  background-color: #578ebe;
}
.portlet.box.blue-madison > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.blue-madison > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.blue-madison > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #a8c4dd;
  color: #bad1e4;
}
.portlet.box.blue-madison > .portlet-title > .actions .btn-default > i {
  color: #c5d8e9;
}
.portlet.box.blue-madison > .portlet-title > .actions .btn-default:hover, .portlet.box.blue-madison > .portlet-title > .actions .btn-default:focus, .portlet.box.blue-madison > .portlet-title > .actions .btn-default:active, .portlet.box.blue-madison > .portlet-title > .actions .btn-default.active {
  border: 1px solid #cdddec;
  color: #dfeaf3;
}

/* Statistic Block */
.dashboard-stat.blue-madison {
  background-color: #578ebe;
}
.dashboard-stat.blue-madison.dashboard-stat-light:hover {
  background-color: #4884b8;
}
.dashboard-stat.blue-madison .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.blue-madison .details .number {
  color: #FFFFFF;
}
.dashboard-stat.blue-madison .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.blue-madison .more {
  color: #FFFFFF;
  background-color: #4884b8;
}

/* General Element */
.bg-blue-madison {
  border-color: #578ebe !important;
  background-image: none !important;
  background-color: #578ebe !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-blue-madison {
  color: #578ebe !important;
}

.progress-bar.blue-madison {
  background: #578ebe !important;
  color: #FFFFFF !important;
}

/***
Blue Chambray
***/
/* Button */
.blue-chambray.btn {
  color: #FFFFFF;
  background-color: #2C3E50;
}
.blue-chambray.btn:hover, .blue-chambray.btn:focus, .blue-chambray.btn:active, .blue-chambray.btn.active {
  color: #FFFFFF;
  background-color: #1f2c39;
}
.open .blue-chambray.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #1f2c39;
}
.blue-chambray.btn:active, .blue-chambray.btn.active {
  background-image: none;
  background-color: #161f29;
}
.blue-chambray.btn:active:hover, .blue-chambray.btn.active:hover {
  background-color: #1a242f;
}
.open .blue-chambray.btn.dropdown-toggle {
  background-image: none;
}
.blue-chambray.btn.disabled, .blue-chambray.btn.disabled:hover, .blue-chambray.btn.disabled:focus, .blue-chambray.btn.disabled:active, .blue-chambray.btn.disabled.active, .blue-chambray.btn[disabled], .blue-chambray.btn[disabled]:hover, .blue-chambray.btn[disabled]:focus, .blue-chambray.btn[disabled]:active, .blue-chambray.btn[disabled].active, fieldset[disabled] .blue-chambray.btn, fieldset[disabled] .blue-chambray.btn:hover, fieldset[disabled] .blue-chambray.btn:focus, fieldset[disabled] .blue-chambray.btn:active, fieldset[disabled] .blue-chambray.btn.active {
  background-color: #2C3E50;
}
.blue-chambray.btn .badge {
  color: #2C3E50;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.blue-chambray-stripe {
  border-left: 3px solid #2C3E50;
}

/* Portlet */
.portlet > .portlet-body.blue-chambray,
.portlet.blue-chambray {
  background-color: #2C3E50;
}

.portlet.solid.blue-chambray > .portlet-title,
.portlet.solid.blue-chambray > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.blue-chambray > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.blue-chambray > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.blue-chambray > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.blue-chambray > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.blue-chambray > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.blue-chambray > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.blue-chambray > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.blue-chambray > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.blue-chambray {
  border: 1px solid #3e5771;
  border-top: 0;
}
.portlet.box.blue-chambray > .portlet-title {
  background-color: #2C3E50;
}
.portlet.box.blue-chambray > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.blue-chambray > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.blue-chambray > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #547698;
  color: #5f83a7;
}
.portlet.box.blue-chambray > .portlet-title > .actions .btn-default > i {
  color: #698aac;
}
.portlet.box.blue-chambray > .portlet-title > .actions .btn-default:hover, .portlet.box.blue-chambray > .portlet-title > .actions .btn-default:focus, .portlet.box.blue-chambray > .portlet-title > .actions .btn-default:active, .portlet.box.blue-chambray > .portlet-title > .actions .btn-default.active {
  border: 1px solid #6f90b0;
  color: #809cb9;
}

/* Statistic Block */
.dashboard-stat.blue-chambray {
  background-color: #2C3E50;
}
.dashboard-stat.blue-chambray.dashboard-stat-light:hover {
  background-color: #253443;
}
.dashboard-stat.blue-chambray .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.blue-chambray .details .number {
  color: #FFFFFF;
}
.dashboard-stat.blue-chambray .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.blue-chambray .more {
  color: #FFFFFF;
  background-color: #253443;
}

/* General Element */
.bg-blue-chambray {
  border-color: #2C3E50 !important;
  background-image: none !important;
  background-color: #2C3E50 !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-blue-chambray {
  color: #2C3E50 !important;
}

.progress-bar.blue-chambray {
  background: #2C3E50 !important;
  color: #FFFFFF !important;
}

/***
Blue Ebonyclay
***/
/* Button */
.blue-ebonyclay.btn {
  color: #FFFFFF;
  background-color: #22313F;
}
.blue-ebonyclay.btn:hover, .blue-ebonyclay.btn:focus, .blue-ebonyclay.btn:active, .blue-ebonyclay.btn.active {
  color: #FFFFFF;
  background-color: #151f28;
}
.open .blue-ebonyclay.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #151f28;
}
.blue-ebonyclay.btn:active, .blue-ebonyclay.btn.active {
  background-image: none;
  background-color: #0d1217;
}
.blue-ebonyclay.btn:active:hover, .blue-ebonyclay.btn.active:hover {
  background-color: #10171e;
}
.open .blue-ebonyclay.btn.dropdown-toggle {
  background-image: none;
}
.blue-ebonyclay.btn.disabled, .blue-ebonyclay.btn.disabled:hover, .blue-ebonyclay.btn.disabled:focus, .blue-ebonyclay.btn.disabled:active, .blue-ebonyclay.btn.disabled.active, .blue-ebonyclay.btn[disabled], .blue-ebonyclay.btn[disabled]:hover, .blue-ebonyclay.btn[disabled]:focus, .blue-ebonyclay.btn[disabled]:active, .blue-ebonyclay.btn[disabled].active, fieldset[disabled] .blue-ebonyclay.btn, fieldset[disabled] .blue-ebonyclay.btn:hover, fieldset[disabled] .blue-ebonyclay.btn:focus, fieldset[disabled] .blue-ebonyclay.btn:active, fieldset[disabled] .blue-ebonyclay.btn.active {
  background-color: #22313F;
}
.blue-ebonyclay.btn .badge {
  color: #22313F;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.blue-ebonyclay-stripe {
  border-left: 3px solid #22313F;
}

/* Portlet */
.portlet > .portlet-body.blue-ebonyclay,
.portlet.blue-ebonyclay {
  background-color: #22313F;
}

.portlet.solid.blue-ebonyclay > .portlet-title,
.portlet.solid.blue-ebonyclay > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.blue-ebonyclay > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.blue-ebonyclay > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.blue-ebonyclay > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.blue-ebonyclay > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.blue-ebonyclay > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.blue-ebonyclay > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.blue-ebonyclay > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.blue-ebonyclay > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.blue-ebonyclay {
  border: 1px solid #344b60;
  border-top: 0;
}
.portlet.box.blue-ebonyclay > .portlet-title {
  background-color: #22313F;
}
.portlet.box.blue-ebonyclay > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.blue-ebonyclay > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.blue-ebonyclay > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #496a88;
  color: #527798;
}
.portlet.box.blue-ebonyclay > .portlet-title > .actions .btn-default > i {
  color: #587ea2;
}
.portlet.box.blue-ebonyclay > .portlet-title > .actions .btn-default:hover, .portlet.box.blue-ebonyclay > .portlet-title > .actions .btn-default:focus, .portlet.box.blue-ebonyclay > .portlet-title > .actions .btn-default:active, .portlet.box.blue-ebonyclay > .portlet-title > .actions .btn-default.active {
  border: 1px solid #5d83a7;
  color: #6d90b0;
}

/* Statistic Block */
.dashboard-stat.blue-ebonyclay {
  background-color: #22313F;
}
.dashboard-stat.blue-ebonyclay.dashboard-stat-light:hover {
  background-color: #1b2732;
}
.dashboard-stat.blue-ebonyclay .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.blue-ebonyclay .details .number {
  color: #FFFFFF;
}
.dashboard-stat.blue-ebonyclay .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.blue-ebonyclay .more {
  color: #FFFFFF;
  background-color: #1b2732;
}

/* General Element */
.bg-blue-ebonyclay {
  border-color: #22313F !important;
  background-image: none !important;
  background-color: #22313F !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-blue-ebonyclay {
  color: #22313F !important;
}

.progress-bar.blue-ebonyclay {
  background: #22313F !important;
  color: #FFFFFF !important;
}

/***
Blue Hoki
***/
/* Button */
.blue-hoki.btn {
  color: #FFFFFF;
  background-color: #67809F;
}
.blue-hoki.btn:hover, .blue-hoki.btn:focus, .blue-hoki.btn:active, .blue-hoki.btn.active {
  color: #FFFFFF;
  background-color: #586e8b;
}
.open .blue-hoki.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #586e8b;
}
.blue-hoki.btn:active, .blue-hoki.btn.active {
  background-image: none;
  background-color: #4e627b;
}
.blue-hoki.btn:active:hover, .blue-hoki.btn.active:hover {
  background-color: #526781;
}
.open .blue-hoki.btn.dropdown-toggle {
  background-image: none;
}
.blue-hoki.btn.disabled, .blue-hoki.btn.disabled:hover, .blue-hoki.btn.disabled:focus, .blue-hoki.btn.disabled:active, .blue-hoki.btn.disabled.active, .blue-hoki.btn[disabled], .blue-hoki.btn[disabled]:hover, .blue-hoki.btn[disabled]:focus, .blue-hoki.btn[disabled]:active, .blue-hoki.btn[disabled].active, fieldset[disabled] .blue-hoki.btn, fieldset[disabled] .blue-hoki.btn:hover, fieldset[disabled] .blue-hoki.btn:focus, fieldset[disabled] .blue-hoki.btn:active, fieldset[disabled] .blue-hoki.btn.active {
  background-color: #67809F;
}
.blue-hoki.btn .badge {
  color: #67809F;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.blue-hoki-stripe {
  border-left: 3px solid #67809F;
}

/* Portlet */
.portlet > .portlet-body.blue-hoki,
.portlet.blue-hoki {
  background-color: #67809F;
}

.portlet.solid.blue-hoki > .portlet-title,
.portlet.solid.blue-hoki > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.blue-hoki > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.blue-hoki > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.blue-hoki > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.blue-hoki > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.blue-hoki > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.blue-hoki > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.blue-hoki > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.blue-hoki > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.blue-hoki {
  border: 1px solid #869ab3;
  border-top: 0;
}
.portlet.box.blue-hoki > .portlet-title {
  background-color: #67809F;
}
.portlet.box.blue-hoki > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.blue-hoki > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.blue-hoki > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #acb9ca;
  color: #bbc7d4;
}
.portlet.box.blue-hoki > .portlet-title > .actions .btn-default > i {
  color: #c5ceda;
}
.portlet.box.blue-hoki > .portlet-title > .actions .btn-default:hover, .portlet.box.blue-hoki > .portlet-title > .actions .btn-default:focus, .portlet.box.blue-hoki > .portlet-title > .actions .btn-default:active, .portlet.box.blue-hoki > .portlet-title > .actions .btn-default.active {
  border: 1px solid #cbd4de;
  color: #dbe1e8;
}

/* Statistic Block */
.dashboard-stat.blue-hoki {
  background-color: #67809F;
}
.dashboard-stat.blue-hoki.dashboard-stat-light:hover {
  background-color: #5e7694;
}
.dashboard-stat.blue-hoki .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.blue-hoki .details .number {
  color: #FFFFFF;
}
.dashboard-stat.blue-hoki .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.blue-hoki .more {
  color: #FFFFFF;
  background-color: #5e7694;
}

/* General Element */
.bg-blue-hoki {
  border-color: #67809F !important;
  background-image: none !important;
  background-color: #67809F !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-blue-hoki {
  color: #67809F !important;
}

.progress-bar.blue-hoki {
  background: #67809F !important;
  color: #FFFFFF !important;
}

/***
Blue Stell
***/
/* Button */
.blue-steel.btn {
  color: #FFFFFF;
  background-color: #4B77BE;
}
.blue-steel.btn:hover, .blue-steel.btn:focus, .blue-steel.btn:active, .blue-steel.btn.active {
  color: #FFFFFF;
  background-color: #3d66a8;
}
.open .blue-steel.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #3d66a8;
}
.blue-steel.btn:active, .blue-steel.btn.active {
  background-image: none;
  background-color: #365b96;
}
.blue-steel.btn:active:hover, .blue-steel.btn.active:hover {
  background-color: #395f9d;
}
.open .blue-steel.btn.dropdown-toggle {
  background-image: none;
}
.blue-steel.btn.disabled, .blue-steel.btn.disabled:hover, .blue-steel.btn.disabled:focus, .blue-steel.btn.disabled:active, .blue-steel.btn.disabled.active, .blue-steel.btn[disabled], .blue-steel.btn[disabled]:hover, .blue-steel.btn[disabled]:focus, .blue-steel.btn[disabled]:active, .blue-steel.btn[disabled].active, fieldset[disabled] .blue-steel.btn, fieldset[disabled] .blue-steel.btn:hover, fieldset[disabled] .blue-steel.btn:focus, fieldset[disabled] .blue-steel.btn:active, fieldset[disabled] .blue-steel.btn.active {
  background-color: #4B77BE;
}
.blue-steel.btn .badge {
  color: #4B77BE;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.blue-steel-stripe {
  border-left: 3px solid #4B77BE;
}

/* Portlet */
.portlet > .portlet-body.blue-steel,
.portlet.blue-steel {
  background-color: #4B77BE;
}

.portlet.solid.blue-steel > .portlet-title,
.portlet.solid.blue-steel > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.blue-steel > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.blue-steel > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.blue-steel > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.blue-steel > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.blue-steel > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.blue-steel > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.blue-steel > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.blue-steel > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.blue-steel {
  border: 1px solid #7093cc;
  border-top: 0;
}
.portlet.box.blue-steel > .portlet-title {
  background-color: #4B77BE;
}
.portlet.box.blue-steel > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.blue-steel > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.blue-steel > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #9db5dc;
  color: #b0c3e3;
}
.portlet.box.blue-steel > .portlet-title > .actions .btn-default > i {
  color: #bbcce7;
}
.portlet.box.blue-steel > .portlet-title > .actions .btn-default:hover, .portlet.box.blue-steel > .portlet-title > .actions .btn-default:focus, .portlet.box.blue-steel > .portlet-title > .actions .btn-default:active, .portlet.box.blue-steel > .portlet-title > .actions .btn-default.active {
  border: 1px solid #c3d2e9;
  color: #d6e0f0;
}

/* Statistic Block */
.dashboard-stat.blue-steel {
  background-color: #4B77BE;
}
.dashboard-stat.blue-steel.dashboard-stat-light:hover {
  background-color: #416db4;
}
.dashboard-stat.blue-steel .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.blue-steel .details .number {
  color: #FFFFFF;
}
.dashboard-stat.blue-steel .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.blue-steel .more {
  color: #FFFFFF;
  background-color: #416db4;
}

/* General Element */
.bg-blue-steel {
  border-color: #4B77BE !important;
  background-image: none !important;
  background-color: #4B77BE !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-blue-steel {
  color: #4B77BE !important;
}

.progress-bar.blue-steel {
  background: #4B77BE !important;
  color: #FFFFFF !important;
}

/***
Blue Soft
***/
/* Button */
.blue-soft.btn {
  color: #FFFFFF;
  background-color: #4c87b9;
}
.blue-soft.btn:hover, .blue-soft.btn:focus, .blue-soft.btn:active, .blue-soft.btn.active {
  color: #FFFFFF;
  background-color: #3f75a2;
}
.open .blue-soft.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #3f75a2;
}
.blue-soft.btn:active, .blue-soft.btn.active {
  background-image: none;
  background-color: #386890;
}
.blue-soft.btn:active:hover, .blue-soft.btn.active:hover {
  background-color: #3b6d97;
}
.open .blue-soft.btn.dropdown-toggle {
  background-image: none;
}
.blue-soft.btn.disabled, .blue-soft.btn.disabled:hover, .blue-soft.btn.disabled:focus, .blue-soft.btn.disabled:active, .blue-soft.btn.disabled.active, .blue-soft.btn[disabled], .blue-soft.btn[disabled]:hover, .blue-soft.btn[disabled]:focus, .blue-soft.btn[disabled]:active, .blue-soft.btn[disabled].active, fieldset[disabled] .blue-soft.btn, fieldset[disabled] .blue-soft.btn:hover, fieldset[disabled] .blue-soft.btn:focus, fieldset[disabled] .blue-soft.btn:active, fieldset[disabled] .blue-soft.btn.active {
  background-color: #4c87b9;
}
.blue-soft.btn .badge {
  color: #4c87b9;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.blue-soft-stripe {
  border-left: 3px solid #4c87b9;
}

/* Portlet */
.portlet > .portlet-body.blue-soft,
.portlet.blue-soft {
  background-color: #4c87b9;
}

.portlet.solid.blue-soft > .portlet-title,
.portlet.solid.blue-soft > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.blue-soft > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.blue-soft > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.blue-soft > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.blue-soft > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.blue-soft > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.blue-soft > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.blue-soft > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.blue-soft > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.blue-soft {
  border: 1px solid #71a0c7;
  border-top: 0;
}
.portlet.box.blue-soft > .portlet-title {
  background-color: #4c87b9;
}
.portlet.box.blue-soft > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.blue-soft > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.blue-soft > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #9dbdd9;
  color: #afc9e0;
}
.portlet.box.blue-soft > .portlet-title > .actions .btn-default > i {
  color: #bad1e4;
}
.portlet.box.blue-soft > .portlet-title > .actions .btn-default:hover, .portlet.box.blue-soft > .portlet-title > .actions .btn-default:focus, .portlet.box.blue-soft > .portlet-title > .actions .btn-default:active, .portlet.box.blue-soft > .portlet-title > .actions .btn-default.active {
  border: 1px solid #c1d6e7;
  color: #d4e2ee;
}

/* Statistic Block */
.dashboard-stat.blue-soft {
  background-color: #4c87b9;
}
.dashboard-stat.blue-soft.dashboard-stat-light:hover {
  background-color: #447dad;
}
.dashboard-stat.blue-soft .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.blue-soft .details .number {
  color: #FFFFFF;
}
.dashboard-stat.blue-soft .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.blue-soft .more {
  color: #FFFFFF;
  background-color: #447dad;
}

/* General Element */
.bg-blue-soft {
  border-color: #4c87b9 !important;
  background-image: none !important;
  background-color: #4c87b9 !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-blue-soft {
  color: #4c87b9 !important;
}

.progress-bar.blue-soft {
  background: #4c87b9 !important;
  color: #FFFFFF !important;
}

/***
Blue Dark
***/
/* Button */
.blue-dark.btn {
  color: #FFFFFF;
  background-color: #5e738b;
}
.blue-dark.btn:hover, .blue-dark.btn:focus, .blue-dark.btn:active, .blue-dark.btn.active {
  color: #FFFFFF;
  background-color: #506176;
}
.open .blue-dark.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #506176;
}
.blue-dark.btn:active, .blue-dark.btn.active {
  background-image: none;
  background-color: #455566;
}
.blue-dark.btn:active:hover, .blue-dark.btn.active:hover {
  background-color: #495a6d;
}
.open .blue-dark.btn.dropdown-toggle {
  background-image: none;
}
.blue-dark.btn.disabled, .blue-dark.btn.disabled:hover, .blue-dark.btn.disabled:focus, .blue-dark.btn.disabled:active, .blue-dark.btn.disabled.active, .blue-dark.btn[disabled], .blue-dark.btn[disabled]:hover, .blue-dark.btn[disabled]:focus, .blue-dark.btn[disabled]:active, .blue-dark.btn[disabled].active, fieldset[disabled] .blue-dark.btn, fieldset[disabled] .blue-dark.btn:hover, fieldset[disabled] .blue-dark.btn:focus, fieldset[disabled] .blue-dark.btn:active, fieldset[disabled] .blue-dark.btn.active {
  background-color: #5e738b;
}
.blue-dark.btn .badge {
  color: #5e738b;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.blue-dark-stripe {
  border-left: 3px solid #5e738b;
}

/* Portlet */
.portlet > .portlet-body.blue-dark,
.portlet.blue-dark {
  background-color: #5e738b;
}

.portlet.solid.blue-dark > .portlet-title,
.portlet.solid.blue-dark > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.blue-dark > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.blue-dark > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.blue-dark > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.blue-dark > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.blue-dark > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.blue-dark > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.blue-dark > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.blue-dark > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.blue-dark {
  border: 1px solid #788da4;
  border-top: 0;
}
.portlet.box.blue-dark > .portlet-title {
  background-color: #5e738b;
}
.portlet.box.blue-dark > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.blue-dark > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.blue-dark > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #9dacbd;
  color: #acb8c7;
}
.portlet.box.blue-dark > .portlet-title > .actions .btn-default > i {
  color: #b5c0cd;
}
.portlet.box.blue-dark > .portlet-title > .actions .btn-default:hover, .portlet.box.blue-dark > .portlet-title > .actions .btn-default:focus, .portlet.box.blue-dark > .portlet-title > .actions .btn-default:active, .portlet.box.blue-dark > .portlet-title > .actions .btn-default.active {
  border: 1px solid #bbc5d1;
  color: #cad2db;
}

/* Statistic Block */
.dashboard-stat.blue-dark {
  background-color: #5e738b;
}
.dashboard-stat.blue-dark.dashboard-stat-light:hover {
  background-color: #56697f;
}
.dashboard-stat.blue-dark .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.blue-dark .details .number {
  color: #FFFFFF;
}
.dashboard-stat.blue-dark .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.blue-dark .more {
  color: #FFFFFF;
  background-color: #56697f;
}

/* General Element */
.bg-blue-dark {
  border-color: #5e738b !important;
  background-image: none !important;
  background-color: #5e738b !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-blue-dark {
  color: #5e738b !important;
}

.progress-bar.blue-dark {
  background: #5e738b !important;
  color: #FFFFFF !important;
}

/***
Blue Sharp
***/
/* Button */
.blue-sharp.btn {
  color: #FFFFFF;
  background-color: #5C9BD1;
}
.blue-sharp.btn:hover, .blue-sharp.btn:focus, .blue-sharp.btn:active, .blue-sharp.btn.active {
  color: #FFFFFF;
  background-color: #408ac9;
}
.open .blue-sharp.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #408ac9;
}
.blue-sharp.btn:active, .blue-sharp.btn.active {
  background-image: none;
  background-color: #357dbb;
}
.blue-sharp.btn:active:hover, .blue-sharp.btn.active:hover {
  background-color: #3782c3;
}
.open .blue-sharp.btn.dropdown-toggle {
  background-image: none;
}
.blue-sharp.btn.disabled, .blue-sharp.btn.disabled:hover, .blue-sharp.btn.disabled:focus, .blue-sharp.btn.disabled:active, .blue-sharp.btn.disabled.active, .blue-sharp.btn[disabled], .blue-sharp.btn[disabled]:hover, .blue-sharp.btn[disabled]:focus, .blue-sharp.btn[disabled]:active, .blue-sharp.btn[disabled].active, fieldset[disabled] .blue-sharp.btn, fieldset[disabled] .blue-sharp.btn:hover, fieldset[disabled] .blue-sharp.btn:focus, fieldset[disabled] .blue-sharp.btn:active, fieldset[disabled] .blue-sharp.btn.active {
  background-color: #5C9BD1;
}
.blue-sharp.btn .badge {
  color: #5C9BD1;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.blue-sharp-stripe {
  border-left: 3px solid #5C9BD1;
}

/* Portlet */
.portlet > .portlet-body.blue-sharp,
.portlet.blue-sharp {
  background-color: #5C9BD1;
}

.portlet.solid.blue-sharp > .portlet-title,
.portlet.solid.blue-sharp > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.blue-sharp > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.blue-sharp > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.blue-sharp > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.blue-sharp > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.blue-sharp > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.blue-sharp > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.blue-sharp > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.blue-sharp > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.blue-sharp {
  border: 1px solid #84b3dc;
  border-top: 0;
}
.portlet.box.blue-sharp > .portlet-title {
  background-color: #5C9BD1;
}
.portlet.box.blue-sharp > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.blue-sharp > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.blue-sharp > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #b4d1ea;
  color: #c7ddef;
}
.portlet.box.blue-sharp > .portlet-title > .actions .btn-default > i {
  color: #d3e4f3;
}
.portlet.box.blue-sharp > .portlet-title > .actions .btn-default:hover, .portlet.box.blue-sharp > .portlet-title > .actions .btn-default:focus, .portlet.box.blue-sharp > .portlet-title > .actions .btn-default:active, .portlet.box.blue-sharp > .portlet-title > .actions .btn-default.active {
  border: 1px solid #dbe9f5;
  color: #eff5fb;
}

/* Statistic Block */
.dashboard-stat.blue-sharp {
  background-color: #5C9BD1;
}
.dashboard-stat.blue-sharp.dashboard-stat-light:hover {
  background-color: #4c91cd;
}
.dashboard-stat.blue-sharp .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.blue-sharp .details .number {
  color: #FFFFFF;
}
.dashboard-stat.blue-sharp .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.blue-sharp .more {
  color: #FFFFFF;
  background-color: #4c91cd;
}

/* General Element */
.bg-blue-sharp {
  border-color: #5C9BD1 !important;
  background-image: none !important;
  background-color: #5C9BD1 !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-blue-sharp {
  color: #5C9BD1 !important;
}

.progress-bar.blue-sharp {
  background: #5C9BD1 !important;
  color: #FFFFFF !important;
}

/***********
Green Colors
************/
/***
Green Default
***/
/* Button */
.green.btn {
  color: #FFFFFF;
  background-color: #26a69a;
}
.green.btn:hover, .green.btn:focus, .green.btn:active, .green.btn.active {
  color: #FFFFFF;
  background-color: #1f897f;
}
.open .green.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #1f897f;
}
.green.btn:active, .green.btn.active {
  background-image: none;
  background-color: #1b746c;
}
.green.btn:active:hover, .green.btn.active:hover {
  background-color: #1c7d74;
}
.open .green.btn.dropdown-toggle {
  background-image: none;
}
.green.btn.disabled, .green.btn.disabled:hover, .green.btn.disabled:focus, .green.btn.disabled:active, .green.btn.disabled.active, .green.btn[disabled], .green.btn[disabled]:hover, .green.btn[disabled]:focus, .green.btn[disabled]:active, .green.btn[disabled].active, fieldset[disabled] .green.btn, fieldset[disabled] .green.btn:hover, fieldset[disabled] .green.btn:focus, fieldset[disabled] .green.btn:active, fieldset[disabled] .green.btn.active {
  background-color: #26a69a;
}
.green.btn .badge {
  color: #26a69a;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.green-stripe {
  border-left: 3px solid #26a69a;
}

/* Portlet */
.portlet > .portlet-body.green,
.portlet.green {
  background-color: #26a69a;
}

.portlet.solid.green > .portlet-title,
.portlet.solid.green > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.green > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.green > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.green > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.green > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.green > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.green > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.green > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.green > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.green {
  border: 1px solid #30d0c1;
  border-top: 0;
}
.portlet.box.green > .portlet-title {
  background-color: #26a69a;
}
.portlet.box.green > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.green > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.green > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #61dbd0;
  color: #76e0d6;
}
.portlet.box.green > .portlet-title > .actions .btn-default > i {
  color: #83e3da;
}
.portlet.box.green > .portlet-title > .actions .btn-default:hover, .portlet.box.green > .portlet-title > .actions .btn-default:focus, .portlet.box.green > .portlet-title > .actions .btn-default:active, .portlet.box.green > .portlet-title > .actions .btn-default.active {
  border: 1px solid #8be4dc;
  color: #a0e9e2;
}

/* Statistic Block */
.dashboard-stat.green {
  background-color: #26a69a;
}
.dashboard-stat.green.dashboard-stat-light:hover {
  background-color: #22958b;
}
.dashboard-stat.green .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.green .details .number {
  color: #FFFFFF;
}
.dashboard-stat.green .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.green .more {
  color: #FFFFFF;
  background-color: #22958b;
}

/* General Element */
.bg-green {
  border-color: #26a69a !important;
  background-image: none !important;
  background-color: #26a69a !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-green {
  color: #26a69a !important;
}

.progress-bar.green {
  background: #26a69a !important;
  color: #FFFFFF !important;
}

/***
Green Meadow
***/
/* Button */
.green-meadow.btn {
  color: #FFFFFF;
  background-color: #1BBC9B;
}
.green-meadow.btn:hover, .green-meadow.btn:focus, .green-meadow.btn:active, .green-meadow.btn.active {
  color: #FFFFFF;
  background-color: #179d81;
}
.open .green-meadow.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #179d81;
}
.green-meadow.btn:active, .green-meadow.btn.active {
  background-image: none;
  background-color: #13866f;
}
.green-meadow.btn:active:hover, .green-meadow.btn.active:hover {
  background-color: #158f76;
}
.open .green-meadow.btn.dropdown-toggle {
  background-image: none;
}
.green-meadow.btn.disabled, .green-meadow.btn.disabled:hover, .green-meadow.btn.disabled:focus, .green-meadow.btn.disabled:active, .green-meadow.btn.disabled.active, .green-meadow.btn[disabled], .green-meadow.btn[disabled]:hover, .green-meadow.btn[disabled]:focus, .green-meadow.btn[disabled]:active, .green-meadow.btn[disabled].active, fieldset[disabled] .green-meadow.btn, fieldset[disabled] .green-meadow.btn:hover, fieldset[disabled] .green-meadow.btn:focus, fieldset[disabled] .green-meadow.btn:active, fieldset[disabled] .green-meadow.btn.active {
  background-color: #1BBC9B;
}
.green-meadow.btn .badge {
  color: #1BBC9B;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.green-meadow-stripe {
  border-left: 3px solid #1BBC9B;
}

/* Portlet */
.portlet > .portlet-body.green-meadow,
.portlet.green-meadow {
  background-color: #1BBC9B;
}

.portlet.solid.green-meadow > .portlet-title,
.portlet.solid.green-meadow > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.green-meadow > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.green-meadow > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.green-meadow > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.green-meadow > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.green-meadow > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.green-meadow > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.green-meadow > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.green-meadow > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.green-meadow {
  border: 1px solid #2ae0bb;
  border-top: 0;
}
.portlet.box.green-meadow > .portlet-title {
  background-color: #1BBC9B;
}
.portlet.box.green-meadow > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.green-meadow > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.green-meadow > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #5fe8cc;
  color: #75ebd3;
}
.portlet.box.green-meadow > .portlet-title > .actions .btn-default > i {
  color: #83edd7;
}
.portlet.box.green-meadow > .portlet-title > .actions .btn-default:hover, .portlet.box.green-meadow > .portlet-title > .actions .btn-default:focus, .portlet.box.green-meadow > .portlet-title > .actions .btn-default:active, .portlet.box.green-meadow > .portlet-title > .actions .btn-default.active {
  border: 1px solid #8ceeda;
  color: #a2f2e1;
}

/* Statistic Block */
.dashboard-stat.green-meadow {
  background-color: #1BBC9B;
}
.dashboard-stat.green-meadow.dashboard-stat-light:hover {
  background-color: #18aa8c;
}
.dashboard-stat.green-meadow .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.green-meadow .details .number {
  color: #FFFFFF;
}
.dashboard-stat.green-meadow .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.green-meadow .more {
  color: #FFFFFF;
  background-color: #18aa8c;
}

/* General Element */
.bg-green-meadow {
  border-color: #1BBC9B !important;
  background-image: none !important;
  background-color: #1BBC9B !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-green-meadow {
  color: #1BBC9B !important;
}

.progress-bar.green-meadow {
  background: #1BBC9B !important;
  color: #FFFFFF !important;
}

/***
Green Seagreen
***/
/* Button */
.green-seagreen.btn {
  color: #FFFFFF;
  background-color: #1BA39C;
}
.green-seagreen.btn:hover, .green-seagreen.btn:focus, .green-seagreen.btn:active, .green-seagreen.btn.active {
  color: #FFFFFF;
  background-color: #16847f;
}
.open .green-seagreen.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #16847f;
}
.green-seagreen.btn:active, .green-seagreen.btn.active {
  background-image: none;
  background-color: #126e6a;
}
.green-seagreen.btn:active:hover, .green-seagreen.btn.active:hover {
  background-color: #147772;
}
.open .green-seagreen.btn.dropdown-toggle {
  background-image: none;
}
.green-seagreen.btn.disabled, .green-seagreen.btn.disabled:hover, .green-seagreen.btn.disabled:focus, .green-seagreen.btn.disabled:active, .green-seagreen.btn.disabled.active, .green-seagreen.btn[disabled], .green-seagreen.btn[disabled]:hover, .green-seagreen.btn[disabled]:focus, .green-seagreen.btn[disabled]:active, .green-seagreen.btn[disabled].active, fieldset[disabled] .green-seagreen.btn, fieldset[disabled] .green-seagreen.btn:hover, fieldset[disabled] .green-seagreen.btn:focus, fieldset[disabled] .green-seagreen.btn:active, fieldset[disabled] .green-seagreen.btn.active {
  background-color: #1BA39C;
}
.green-seagreen.btn .badge {
  color: #1BA39C;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.green-seagreen-stripe {
  border-left: 3px solid #1BA39C;
}

/* Portlet */
.portlet > .portlet-body.green-seagreen,
.portlet.green-seagreen {
  background-color: #1BA39C;
}

.portlet.solid.green-seagreen > .portlet-title,
.portlet.solid.green-seagreen > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.green-seagreen > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.green-seagreen > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.green-seagreen > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.green-seagreen > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.green-seagreen > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.green-seagreen > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.green-seagreen > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.green-seagreen > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.green-seagreen {
  border: 1px solid #22cfc6;
  border-top: 0;
}
.portlet.box.green-seagreen > .portlet-title {
  background-color: #1BA39C;
}
.portlet.box.green-seagreen > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.green-seagreen > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.green-seagreen > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #4de1da;
  color: #63e5de;
}
.portlet.box.green-seagreen > .portlet-title > .actions .btn-default > i {
  color: #70e7e1;
}
.portlet.box.green-seagreen > .portlet-title > .actions .btn-default:hover, .portlet.box.green-seagreen > .portlet-title > .actions .btn-default:focus, .portlet.box.green-seagreen > .portlet-title > .actions .btn-default:active, .portlet.box.green-seagreen > .portlet-title > .actions .btn-default.active {
  border: 1px solid #78e9e3;
  color: #8eece8;
}

/* Statistic Block */
.dashboard-stat.green-seagreen {
  background-color: #1BA39C;
}
.dashboard-stat.green-seagreen.dashboard-stat-light:hover {
  background-color: #18918b;
}
.dashboard-stat.green-seagreen .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.green-seagreen .details .number {
  color: #FFFFFF;
}
.dashboard-stat.green-seagreen .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.green-seagreen .more {
  color: #FFFFFF;
  background-color: #18918b;
}

/* General Element */
.bg-green-seagreen {
  border-color: #1BA39C !important;
  background-image: none !important;
  background-color: #1BA39C !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-green-seagreen {
  color: #1BA39C !important;
}

.progress-bar.green-seagreen {
  background: #1BA39C !important;
  color: #FFFFFF !important;
}

/***
Green Turquoise
***/
/* Button */
.green-turquoise.btn {
  color: #FFFFFF;
  background-color: #36D7B7;
}
.green-turquoise.btn:hover, .green-turquoise.btn:focus, .green-turquoise.btn:active, .green-turquoise.btn.active {
  color: #FFFFFF;
  background-color: #27c3a4;
}
.open .green-turquoise.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #27c3a4;
}
.green-turquoise.btn:active, .green-turquoise.btn.active {
  background-image: none;
  background-color: #22ad92;
}
.green-turquoise.btn:active:hover, .green-turquoise.btn.active:hover {
  background-color: #24b699;
}
.open .green-turquoise.btn.dropdown-toggle {
  background-image: none;
}
.green-turquoise.btn.disabled, .green-turquoise.btn.disabled:hover, .green-turquoise.btn.disabled:focus, .green-turquoise.btn.disabled:active, .green-turquoise.btn.disabled.active, .green-turquoise.btn[disabled], .green-turquoise.btn[disabled]:hover, .green-turquoise.btn[disabled]:focus, .green-turquoise.btn[disabled]:active, .green-turquoise.btn[disabled].active, fieldset[disabled] .green-turquoise.btn, fieldset[disabled] .green-turquoise.btn:hover, fieldset[disabled] .green-turquoise.btn:focus, fieldset[disabled] .green-turquoise.btn:active, fieldset[disabled] .green-turquoise.btn.active {
  background-color: #36D7B7;
}
.green-turquoise.btn .badge {
  color: #36D7B7;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.green-turquoise-stripe {
  border-left: 3px solid #36D7B7;
}

/* Portlet */
.portlet > .portlet-body.green-turquoise,
.portlet.green-turquoise {
  background-color: #36D7B7;
}

.portlet.solid.green-turquoise > .portlet-title,
.portlet.solid.green-turquoise > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.green-turquoise > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.green-turquoise > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.green-turquoise > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.green-turquoise > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.green-turquoise > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.green-turquoise > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.green-turquoise > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.green-turquoise > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.green-turquoise {
  border: 1px solid #61dfc6;
  border-top: 0;
}
.portlet.box.green-turquoise > .portlet-title {
  background-color: #36D7B7;
}
.portlet.box.green-turquoise > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.green-turquoise > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.green-turquoise > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #94ead9;
  color: #a9eee0;
}
.portlet.box.green-turquoise > .portlet-title > .actions .btn-default > i {
  color: #b6f0e5;
}
.portlet.box.green-turquoise > .portlet-title > .actions .btn-default:hover, .portlet.box.green-turquoise > .portlet-title > .actions .btn-default:focus, .portlet.box.green-turquoise > .portlet-title > .actions .btn-default:active, .portlet.box.green-turquoise > .portlet-title > .actions .btn-default.active {
  border: 1px solid #bef2e8;
  color: #d3f6ef;
}

/* Statistic Block */
.dashboard-stat.green-turquoise {
  background-color: #36D7B7;
}
.dashboard-stat.green-turquoise.dashboard-stat-light:hover {
  background-color: #29cfae;
}
.dashboard-stat.green-turquoise .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.green-turquoise .details .number {
  color: #FFFFFF;
}
.dashboard-stat.green-turquoise .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.green-turquoise .more {
  color: #FFFFFF;
  background-color: #29cfae;
}

/* General Element */
.bg-green-turquoise {
  border-color: #36D7B7 !important;
  background-image: none !important;
  background-color: #36D7B7 !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-green-turquoise {
  color: #36D7B7 !important;
}

.progress-bar.green-turquoise {
  background: #36D7B7 !important;
  color: #FFFFFF !important;
}

/***
Green Haze
***/
/* Button */
.green-haze.btn {
  color: #FFFFFF;
  background-color: #44b6ae;
}
.green-haze.btn:hover, .green-haze.btn:focus, .green-haze.btn:active, .green-haze.btn.active {
  color: #FFFFFF;
  background-color: #3a9c95;
}
.open .green-haze.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #3a9c95;
}
.green-haze.btn:active, .green-haze.btn.active {
  background-image: none;
  background-color: #338983;
}
.green-haze.btn:active:hover, .green-haze.btn.active:hover {
  background-color: #36918b;
}
.open .green-haze.btn.dropdown-toggle {
  background-image: none;
}
.green-haze.btn.disabled, .green-haze.btn.disabled:hover, .green-haze.btn.disabled:focus, .green-haze.btn.disabled:active, .green-haze.btn.disabled.active, .green-haze.btn[disabled], .green-haze.btn[disabled]:hover, .green-haze.btn[disabled]:focus, .green-haze.btn[disabled]:active, .green-haze.btn[disabled].active, fieldset[disabled] .green-haze.btn, fieldset[disabled] .green-haze.btn:hover, fieldset[disabled] .green-haze.btn:focus, fieldset[disabled] .green-haze.btn:active, fieldset[disabled] .green-haze.btn.active {
  background-color: #44b6ae;
}
.green-haze.btn .badge {
  color: #44b6ae;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.green-haze-stripe {
  border-left: 3px solid #44b6ae;
}

/* Portlet */
.portlet > .portlet-body.green-haze,
.portlet.green-haze {
  background-color: #44b6ae;
}

.portlet.solid.green-haze > .portlet-title,
.portlet.solid.green-haze > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.green-haze > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.green-haze > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.green-haze > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.green-haze > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.green-haze > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.green-haze > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.green-haze > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.green-haze > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.green-haze {
  border: 1px solid #67c6bf;
  border-top: 0;
}
.portlet.box.green-haze > .portlet-title {
  background-color: #44b6ae;
}
.portlet.box.green-haze > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.green-haze > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.green-haze > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #93d7d2;
  color: #a6deda;
}
.portlet.box.green-haze > .portlet-title > .actions .btn-default > i {
  color: #b1e2de;
}
.portlet.box.green-haze > .portlet-title > .actions .btn-default:hover, .portlet.box.green-haze > .portlet-title > .actions .btn-default:focus, .portlet.box.green-haze > .portlet-title > .actions .btn-default:active, .portlet.box.green-haze > .portlet-title > .actions .btn-default.active {
  border: 1px solid #b9e5e2;
  color: #cbece9;
}

/* Statistic Block */
.dashboard-stat.green-haze {
  background-color: #44b6ae;
}
.dashboard-stat.green-haze.dashboard-stat-light:hover {
  background-color: #3ea7a0;
}
.dashboard-stat.green-haze .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.green-haze .details .number {
  color: #FFFFFF;
}
.dashboard-stat.green-haze .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.green-haze .more {
  color: #FFFFFF;
  background-color: #3ea7a0;
}

/* General Element */
.bg-green-haze {
  border-color: #44b6ae !important;
  background-image: none !important;
  background-color: #44b6ae !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-green-haze {
  color: #44b6ae !important;
}

.progress-bar.green-haze {
  background: #44b6ae !important;
  color: #FFFFFF !important;
}

/***
Green Jungle
***/
/* Button */
.green-jungle.btn {
  color: #FFFFFF;
  background-color: #26C281;
}
.green-jungle.btn:hover, .green-jungle.btn:focus, .green-jungle.btn:active, .green-jungle.btn.active {
  color: #FFFFFF;
  background-color: #20a46d;
}
.open .green-jungle.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #20a46d;
}
.green-jungle.btn:active, .green-jungle.btn.active {
  background-image: none;
  background-color: #1c8f5f;
}
.green-jungle.btn:active:hover, .green-jungle.btn.active:hover {
  background-color: #1e9765;
}
.open .green-jungle.btn.dropdown-toggle {
  background-image: none;
}
.green-jungle.btn.disabled, .green-jungle.btn.disabled:hover, .green-jungle.btn.disabled:focus, .green-jungle.btn.disabled:active, .green-jungle.btn.disabled.active, .green-jungle.btn[disabled], .green-jungle.btn[disabled]:hover, .green-jungle.btn[disabled]:focus, .green-jungle.btn[disabled]:active, .green-jungle.btn[disabled].active, fieldset[disabled] .green-jungle.btn, fieldset[disabled] .green-jungle.btn:hover, fieldset[disabled] .green-jungle.btn:focus, fieldset[disabled] .green-jungle.btn:active, fieldset[disabled] .green-jungle.btn.active {
  background-color: #26C281;
}
.green-jungle.btn .badge {
  color: #26C281;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.green-jungle-stripe {
  border-left: 3px solid #26C281;
}

/* Portlet */
.portlet > .portlet-body.green-jungle,
.portlet.green-jungle {
  background-color: #26C281;
}

.portlet.solid.green-jungle > .portlet-title,
.portlet.solid.green-jungle > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.green-jungle > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.green-jungle > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.green-jungle > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.green-jungle > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.green-jungle > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.green-jungle > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.green-jungle > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.green-jungle > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.green-jungle {
  border: 1px solid #41da9a;
  border-top: 0;
}
.portlet.box.green-jungle > .portlet-title {
  background-color: #26C281;
}
.portlet.box.green-jungle > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.green-jungle > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.green-jungle > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #74e4b5;
  color: #8ae8c1;
}
.portlet.box.green-jungle > .portlet-title > .actions .btn-default > i {
  color: #96ebc8;
}
.portlet.box.green-jungle > .portlet-title > .actions .btn-default:hover, .portlet.box.green-jungle > .portlet-title > .actions .btn-default:focus, .portlet.box.green-jungle > .portlet-title > .actions .btn-default:active, .portlet.box.green-jungle > .portlet-title > .actions .btn-default.active {
  border: 1px solid #9feccc;
  color: #b4f0d7;
}

/* Statistic Block */
.dashboard-stat.green-jungle {
  background-color: #26C281;
}
.dashboard-stat.green-jungle.dashboard-stat-light:hover {
  background-color: #23b176;
}
.dashboard-stat.green-jungle .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.green-jungle .details .number {
  color: #FFFFFF;
}
.dashboard-stat.green-jungle .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.green-jungle .more {
  color: #FFFFFF;
  background-color: #23b176;
}

/* General Element */
.bg-green-jungle {
  border-color: #26C281 !important;
  background-image: none !important;
  background-color: #26C281 !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-green-jungle {
  color: #26C281 !important;
}

.progress-bar.green-jungle {
  background: #26C281 !important;
  color: #FFFFFF !important;
}

/***
Green Sharp
***/
/* Button */
.green-sharp.btn {
  color: #FFFFFF;
  background-color: #4DB3A2;
}
.green-sharp.btn:hover, .green-sharp.btn:focus, .green-sharp.btn:active, .green-sharp.btn.active {
  color: #FFFFFF;
  background-color: #429a8c;
}
.open .green-sharp.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #429a8c;
}
.green-sharp.btn:active, .green-sharp.btn.active {
  background-image: none;
  background-color: #3a897b;
}
.green-sharp.btn:active:hover, .green-sharp.btn.active:hover {
  background-color: #3d9082;
}
.open .green-sharp.btn.dropdown-toggle {
  background-image: none;
}
.green-sharp.btn.disabled, .green-sharp.btn.disabled:hover, .green-sharp.btn.disabled:focus, .green-sharp.btn.disabled:active, .green-sharp.btn.disabled.active, .green-sharp.btn[disabled], .green-sharp.btn[disabled]:hover, .green-sharp.btn[disabled]:focus, .green-sharp.btn[disabled]:active, .green-sharp.btn[disabled].active, fieldset[disabled] .green-sharp.btn, fieldset[disabled] .green-sharp.btn:hover, fieldset[disabled] .green-sharp.btn:focus, fieldset[disabled] .green-sharp.btn:active, fieldset[disabled] .green-sharp.btn.active {
  background-color: #4DB3A2;
}
.green-sharp.btn .badge {
  color: #4DB3A2;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.green-sharp-stripe {
  border-left: 3px solid #4DB3A2;
}

/* Portlet */
.portlet > .portlet-body.green-sharp,
.portlet.green-sharp {
  background-color: #4DB3A2;
}

.portlet.solid.green-sharp > .portlet-title,
.portlet.solid.green-sharp > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.green-sharp > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.green-sharp > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.green-sharp > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.green-sharp > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.green-sharp > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.green-sharp > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.green-sharp > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.green-sharp > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.green-sharp {
  border: 1px solid #71c2b5;
  border-top: 0;
}
.portlet.box.green-sharp > .portlet-title {
  background-color: #4DB3A2;
}
.portlet.box.green-sharp > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.green-sharp > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.green-sharp > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #9cd5cb;
  color: #addcd4;
}
.portlet.box.green-sharp > .portlet-title > .actions .btn-default > i {
  color: #b8e1da;
}
.portlet.box.green-sharp > .portlet-title > .actions .btn-default:hover, .portlet.box.green-sharp > .portlet-title > .actions .btn-default:focus, .portlet.box.green-sharp > .portlet-title > .actions .btn-default:active, .portlet.box.green-sharp > .portlet-title > .actions .btn-default.active {
  border: 1px solid #bfe4de;
  color: #d1ebe7;
}

/* Statistic Block */
.dashboard-stat.green-sharp {
  background-color: #4DB3A2;
}
.dashboard-stat.green-sharp.dashboard-stat-light:hover {
  background-color: #46a595;
}
.dashboard-stat.green-sharp .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.green-sharp .details .number {
  color: #FFFFFF;
}
.dashboard-stat.green-sharp .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.green-sharp .more {
  color: #FFFFFF;
  background-color: #46a595;
}

/* General Element */
.bg-green-sharp {
  border-color: #4DB3A2 !important;
  background-image: none !important;
  background-color: #4DB3A2 !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-green-sharp {
  color: #4DB3A2 !important;
}

.progress-bar.green-sharp {
  background: #4DB3A2 !important;
  color: #FFFFFF !important;
}

/***
Green Sharp
***/
/* Button */
.green-soft.btn {
  color: #FFFFFF;
  background-color: #3faba4;
}
.green-soft.btn:hover, .green-soft.btn:focus, .green-soft.btn:active, .green-soft.btn.active {
  color: #FFFFFF;
  background-color: #35918b;
}
.open .green-soft.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #35918b;
}
.green-soft.btn:active, .green-soft.btn.active {
  background-image: none;
  background-color: #2f7e79;
}
.green-soft.btn:active:hover, .green-soft.btn.active:hover {
  background-color: #318680;
}
.open .green-soft.btn.dropdown-toggle {
  background-image: none;
}
.green-soft.btn.disabled, .green-soft.btn.disabled:hover, .green-soft.btn.disabled:focus, .green-soft.btn.disabled:active, .green-soft.btn.disabled.active, .green-soft.btn[disabled], .green-soft.btn[disabled]:hover, .green-soft.btn[disabled]:focus, .green-soft.btn[disabled]:active, .green-soft.btn[disabled].active, fieldset[disabled] .green-soft.btn, fieldset[disabled] .green-soft.btn:hover, fieldset[disabled] .green-soft.btn:focus, fieldset[disabled] .green-soft.btn:active, fieldset[disabled] .green-soft.btn.active {
  background-color: #3faba4;
}
.green-soft.btn .badge {
  color: #3faba4;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.green-soft-stripe {
  border-left: 3px solid #3faba4;
}

/* Portlet */
.portlet > .portlet-body.green-soft,
.portlet.green-soft {
  background-color: #3faba4;
}

.portlet.solid.green-soft > .portlet-title,
.portlet.solid.green-soft > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.green-soft > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.green-soft > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.green-soft > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.green-soft > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.green-soft > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.green-soft > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.green-soft > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.green-soft > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.green-soft {
  border: 1px solid #5bc2bc;
  border-top: 0;
}
.portlet.box.green-soft > .portlet-title {
  background-color: #3faba4;
}
.portlet.box.green-soft > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.green-soft > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.green-soft > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #87d3ce;
  color: #9adad6;
}
.portlet.box.green-soft > .portlet-title > .actions .btn-default > i {
  color: #a5deda;
}
.portlet.box.green-soft > .portlet-title > .actions .btn-default:hover, .portlet.box.green-soft > .portlet-title > .actions .btn-default:focus, .portlet.box.green-soft > .portlet-title > .actions .btn-default:active, .portlet.box.green-soft > .portlet-title > .actions .btn-default.active {
  border: 1px solid #ade1dd;
  color: #bfe7e5;
}

/* Statistic Block */
.dashboard-stat.green-soft {
  background-color: #3faba4;
}
.dashboard-stat.green-soft.dashboard-stat-light:hover {
  background-color: #3a9c96;
}
.dashboard-stat.green-soft .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.green-soft .details .number {
  color: #FFFFFF;
}
.dashboard-stat.green-soft .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.green-soft .more {
  color: #FFFFFF;
  background-color: #3a9c96;
}

/* General Element */
.bg-green-soft {
  border-color: #3faba4 !important;
  background-image: none !important;
  background-color: #3faba4 !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-green-soft {
  color: #3faba4 !important;
}

.progress-bar.green-soft {
  background: #3faba4 !important;
  color: #FFFFFF !important;
}

/***********
Grey Colors
************/
/***
Grey Default
***/
/* Button */
.grey.btn {
  color: #333333;
  background-color: #E5E5E5;
}
.grey.btn:hover, .grey.btn:focus, .grey.btn:active, .grey.btn.active {
  color: #333333;
  background-color: lightgray;
}
.open .grey.btn.dropdown-toggle {
  color: #333333;
  background-color: lightgray;
}
.grey.btn:active, .grey.btn.active {
  background-image: none;
  background-color: #c6c6c6;
}
.grey.btn:active:hover, .grey.btn.active:hover {
  background-color: #cccccc;
}
.open .grey.btn.dropdown-toggle {
  background-image: none;
}
.grey.btn.disabled, .grey.btn.disabled:hover, .grey.btn.disabled:focus, .grey.btn.disabled:active, .grey.btn.disabled.active, .grey.btn[disabled], .grey.btn[disabled]:hover, .grey.btn[disabled]:focus, .grey.btn[disabled]:active, .grey.btn[disabled].active, fieldset[disabled] .grey.btn, fieldset[disabled] .grey.btn:hover, fieldset[disabled] .grey.btn:focus, fieldset[disabled] .grey.btn:active, fieldset[disabled] .grey.btn.active {
  background-color: #E5E5E5;
}
.grey.btn .badge {
  color: #E5E5E5;
  background-color: #333333;
}

/* Button Strip */
.btn.grey-stripe {
  border-left: 3px solid #E5E5E5;
}

/* Portlet */
.portlet > .portlet-body.grey,
.portlet.grey {
  background-color: #E5E5E5;
}

.portlet.solid.grey > .portlet-title,
.portlet.solid.grey > .portlet-body {
  border: 0;
  color: #333333;
}
.portlet.solid.grey > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.grey > .portlet-title > .caption > i {
  color: #333333;
}
.portlet.solid.grey > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.grey > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.grey > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.grey > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.grey > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.grey > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.grey {
  border: 1px solid white;
  border-top: 0;
}
.portlet.box.grey > .portlet-title {
  background-color: #E5E5E5;
}
.portlet.box.grey > .portlet-title > .caption {
  color: #333333;
}
.portlet.box.grey > .portlet-title > .caption > i {
  color: #333333;
}
.portlet.box.grey > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid white;
  color: white;
}
.portlet.box.grey > .portlet-title > .actions .btn-default > i {
  color: white;
}
.portlet.box.grey > .portlet-title > .actions .btn-default:hover, .portlet.box.grey > .portlet-title > .actions .btn-default:focus, .portlet.box.grey > .portlet-title > .actions .btn-default:active, .portlet.box.grey > .portlet-title > .actions .btn-default.active {
  border: 1px solid white;
  color: white;
}

/* Statistic Block */
.dashboard-stat.grey {
  background-color: #E5E5E5;
}
.dashboard-stat.grey.dashboard-stat-light:hover {
  background-color: #dbdbdb;
}
.dashboard-stat.grey .visual > i {
  color: #333333;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.grey .details .number {
  color: #333333;
}
.dashboard-stat.grey .details .desc {
  color: #333333;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.grey .more {
  color: #333333;
  background-color: #dbdbdb;
}

/* General Element */
.bg-grey {
  border-color: #E5E5E5 !important;
  background-image: none !important;
  background-color: #E5E5E5 !important;
  color: #333333 !important;
}

/* General Text & Icon Font Colors */
.font-grey {
  color: #E5E5E5 !important;
}

.progress-bar.grey {
  background: #E5E5E5 !important;
  color: #333333 !important;
}

/***
Grey Steel
***/
/* Button */
.grey-steel.btn {
  color: #80898e;
  background-color: #e9edef;
}
.grey-steel.btn:hover, .grey-steel.btn:focus, .grey-steel.btn:active, .grey-steel.btn.active {
  color: #80898e;
  background-color: #d4dce0;
}
.open .grey-steel.btn.dropdown-toggle {
  color: #80898e;
  background-color: #d4dce0;
}
.grey-steel.btn:active, .grey-steel.btn.active {
  background-image: none;
  background-color: #c6d0d5;
}
.grey-steel.btn:active:hover, .grey-steel.btn.active:hover {
  background-color: #cbd5da;
}
.open .grey-steel.btn.dropdown-toggle {
  background-image: none;
}
.grey-steel.btn.disabled, .grey-steel.btn.disabled:hover, .grey-steel.btn.disabled:focus, .grey-steel.btn.disabled:active, .grey-steel.btn.disabled.active, .grey-steel.btn[disabled], .grey-steel.btn[disabled]:hover, .grey-steel.btn[disabled]:focus, .grey-steel.btn[disabled]:active, .grey-steel.btn[disabled].active, fieldset[disabled] .grey-steel.btn, fieldset[disabled] .grey-steel.btn:hover, fieldset[disabled] .grey-steel.btn:focus, fieldset[disabled] .grey-steel.btn:active, fieldset[disabled] .grey-steel.btn.active {
  background-color: #e9edef;
}
.grey-steel.btn .badge {
  color: #e9edef;
  background-color: #80898e;
}

/* Button Strip */
.btn.grey-steel-stripe {
  border-left: 3px solid #e9edef;
}

/* Portlet */
.portlet > .portlet-body.grey-steel,
.portlet.grey-steel {
  background-color: #e9edef;
}

.portlet.solid.grey-steel > .portlet-title,
.portlet.solid.grey-steel > .portlet-body {
  border: 0;
  color: #80898e;
}
.portlet.solid.grey-steel > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.grey-steel > .portlet-title > .caption > i {
  color: #80898e;
}
.portlet.solid.grey-steel > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.grey-steel > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.grey-steel > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.grey-steel > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.grey-steel > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.grey-steel > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.grey-steel {
  border: 1px solid white;
  border-top: 0;
}
.portlet.box.grey-steel > .portlet-title {
  background-color: #e9edef;
}
.portlet.box.grey-steel > .portlet-title > .caption {
  color: #80898e;
}
.portlet.box.grey-steel > .portlet-title > .caption > i {
  color: #80898e;
}
.portlet.box.grey-steel > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid white;
  color: white;
}
.portlet.box.grey-steel > .portlet-title > .actions .btn-default > i {
  color: white;
}
.portlet.box.grey-steel > .portlet-title > .actions .btn-default:hover, .portlet.box.grey-steel > .portlet-title > .actions .btn-default:focus, .portlet.box.grey-steel > .portlet-title > .actions .btn-default:active, .portlet.box.grey-steel > .portlet-title > .actions .btn-default.active {
  border: 1px solid white;
  color: white;
}

/* Statistic Block */
.dashboard-stat.grey-steel {
  background-color: #e9edef;
}
.dashboard-stat.grey-steel.dashboard-stat-light:hover {
  background-color: #dde3e6;
}
.dashboard-stat.grey-steel .visual > i {
  color: #80898e;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.grey-steel .details .number {
  color: #80898e;
}
.dashboard-stat.grey-steel .details .desc {
  color: #80898e;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.grey-steel .more {
  color: #80898e;
  background-color: #dde3e6;
}

/* General Element */
.bg-grey-steel {
  border-color: #e9edef !important;
  background-image: none !important;
  background-color: #e9edef !important;
  color: #80898e !important;
}

/* General Text & Icon Font Colors */
.font-grey-steel {
  color: #e9edef !important;
}

.progress-bar.grey-steel {
  background: #e9edef !important;
  color: #80898e !important;
}

/***
Grey Cararra
***/
/* Button */
.grey-cararra.btn {
  color: #333333;
  background-color: #fafafa;
}
.grey-cararra.btn:hover, .grey-cararra.btn:focus, .grey-cararra.btn:active, .grey-cararra.btn.active {
  color: #333333;
  background-color: #e8e8e8;
}
.open .grey-cararra.btn.dropdown-toggle {
  color: #333333;
  background-color: #e8e8e8;
}
.grey-cararra.btn:active, .grey-cararra.btn.active {
  background-image: none;
  background-color: #dbdbdb;
}
.grey-cararra.btn:active:hover, .grey-cararra.btn.active:hover {
  background-color: #e1e1e1;
}
.open .grey-cararra.btn.dropdown-toggle {
  background-image: none;
}
.grey-cararra.btn.disabled, .grey-cararra.btn.disabled:hover, .grey-cararra.btn.disabled:focus, .grey-cararra.btn.disabled:active, .grey-cararra.btn.disabled.active, .grey-cararra.btn[disabled], .grey-cararra.btn[disabled]:hover, .grey-cararra.btn[disabled]:focus, .grey-cararra.btn[disabled]:active, .grey-cararra.btn[disabled].active, fieldset[disabled] .grey-cararra.btn, fieldset[disabled] .grey-cararra.btn:hover, fieldset[disabled] .grey-cararra.btn:focus, fieldset[disabled] .grey-cararra.btn:active, fieldset[disabled] .grey-cararra.btn.active {
  background-color: #fafafa;
}
.grey-cararra.btn .badge {
  color: #fafafa;
  background-color: #333333;
}

/* Button Strip */
.btn.grey-cararra-stripe {
  border-left: 3px solid #fafafa;
}

/* Portlet */
.portlet > .portlet-body.grey-cararra,
.portlet.grey-cararra {
  background-color: #fafafa;
}

.portlet.solid.grey-cararra > .portlet-title,
.portlet.solid.grey-cararra > .portlet-body {
  border: 0;
  color: #333333;
}
.portlet.solid.grey-cararra > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.grey-cararra > .portlet-title > .caption > i {
  color: #333333;
}
.portlet.solid.grey-cararra > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.grey-cararra > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.grey-cararra > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.grey-cararra > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.grey-cararra > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.grey-cararra > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.grey-cararra {
  border: 1px solid white;
  border-top: 0;
}
.portlet.box.grey-cararra > .portlet-title {
  background-color: #fafafa;
}
.portlet.box.grey-cararra > .portlet-title > .caption {
  color: #333333;
}
.portlet.box.grey-cararra > .portlet-title > .caption > i {
  color: #333333;
}
.portlet.box.grey-cararra > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid white;
  color: white;
}
.portlet.box.grey-cararra > .portlet-title > .actions .btn-default > i {
  color: white;
}
.portlet.box.grey-cararra > .portlet-title > .actions .btn-default:hover, .portlet.box.grey-cararra > .portlet-title > .actions .btn-default:focus, .portlet.box.grey-cararra > .portlet-title > .actions .btn-default:active, .portlet.box.grey-cararra > .portlet-title > .actions .btn-default.active {
  border: 1px solid white;
  color: white;
}

/* Statistic Block */
.dashboard-stat.grey-cararra {
  background-color: #fafafa;
}
.dashboard-stat.grey-cararra.dashboard-stat-light:hover {
  background-color: #f0f0f0;
}
.dashboard-stat.grey-cararra .visual > i {
  color: #333333;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.grey-cararra .details .number {
  color: #333333;
}
.dashboard-stat.grey-cararra .details .desc {
  color: #333333;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.grey-cararra .more {
  color: #333333;
  background-color: #f0f0f0;
}

/* General Element */
.bg-grey-cararra {
  border-color: #fafafa !important;
  background-image: none !important;
  background-color: #fafafa !important;
  color: #333333 !important;
}

/* General Text & Icon Font Colors */
.font-grey-cararra {
  color: #fafafa !important;
}

.progress-bar.grey-cararra {
  background: #fafafa !important;
  color: #333333 !important;
}

/***
Grey Gallery
***/
/* Button */
.grey-gallery.btn {
  color: #ffffff;
  background-color: #555555;
}
.grey-gallery.btn:hover, .grey-gallery.btn:focus, .grey-gallery.btn:active, .grey-gallery.btn.active {
  color: #ffffff;
  background-color: #434343;
}
.open .grey-gallery.btn.dropdown-toggle {
  color: #ffffff;
  background-color: #434343;
}
.grey-gallery.btn:active, .grey-gallery.btn.active {
  background-image: none;
  background-color: #363636;
}
.grey-gallery.btn:active:hover, .grey-gallery.btn.active:hover {
  background-color: #3b3b3b;
}
.open .grey-gallery.btn.dropdown-toggle {
  background-image: none;
}
.grey-gallery.btn.disabled, .grey-gallery.btn.disabled:hover, .grey-gallery.btn.disabled:focus, .grey-gallery.btn.disabled:active, .grey-gallery.btn.disabled.active, .grey-gallery.btn[disabled], .grey-gallery.btn[disabled]:hover, .grey-gallery.btn[disabled]:focus, .grey-gallery.btn[disabled]:active, .grey-gallery.btn[disabled].active, fieldset[disabled] .grey-gallery.btn, fieldset[disabled] .grey-gallery.btn:hover, fieldset[disabled] .grey-gallery.btn:focus, fieldset[disabled] .grey-gallery.btn:active, fieldset[disabled] .grey-gallery.btn.active {
  background-color: #555555;
}
.grey-gallery.btn .badge {
  color: #555555;
  background-color: #ffffff;
}

/* Button Strip */
.btn.grey-gallery-stripe {
  border-left: 3px solid #555555;
}

/* Portlet */
.portlet > .portlet-body.grey-gallery,
.portlet.grey-gallery {
  background-color: #555555;
}

.portlet.solid.grey-gallery > .portlet-title,
.portlet.solid.grey-gallery > .portlet-body {
  border: 0;
  color: #ffffff;
}
.portlet.solid.grey-gallery > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.grey-gallery > .portlet-title > .caption > i {
  color: #ffffff;
}
.portlet.solid.grey-gallery > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.grey-gallery > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.grey-gallery > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.grey-gallery > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.grey-gallery > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.grey-gallery > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.grey-gallery {
  border: 1px solid #6e6e6e;
  border-top: 0;
}
.portlet.box.grey-gallery > .portlet-title {
  background-color: #555555;
}
.portlet.box.grey-gallery > .portlet-title > .caption {
  color: #ffffff;
}
.portlet.box.grey-gallery > .portlet-title > .caption > i {
  color: #ffffff;
}
.portlet.box.grey-gallery > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #8d8d8d;
  color: #9a9a9a;
}
.portlet.box.grey-gallery > .portlet-title > .actions .btn-default > i {
  color: #a2a2a2;
}
.portlet.box.grey-gallery > .portlet-title > .actions .btn-default:hover, .portlet.box.grey-gallery > .portlet-title > .actions .btn-default:focus, .portlet.box.grey-gallery > .portlet-title > .actions .btn-default:active, .portlet.box.grey-gallery > .portlet-title > .actions .btn-default.active {
  border: 1px solid #a7a7a7;
  color: #b3b3b3;
}

/* Statistic Block */
.dashboard-stat.grey-gallery {
  background-color: #555555;
}
.dashboard-stat.grey-gallery.dashboard-stat-light:hover {
  background-color: #4b4b4b;
}
.dashboard-stat.grey-gallery .visual > i {
  color: #ffffff;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.grey-gallery .details .number {
  color: #ffffff;
}
.dashboard-stat.grey-gallery .details .desc {
  color: #ffffff;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.grey-gallery .more {
  color: #ffffff;
  background-color: #4b4b4b;
}

/* General Element */
.bg-grey-gallery {
  border-color: #555555 !important;
  background-image: none !important;
  background-color: #555555 !important;
  color: #ffffff !important;
}

/* General Text & Icon Font Colors */
.font-grey-gallery {
  color: #555555 !important;
}

.progress-bar.grey-gallery {
  background: #555555 !important;
  color: #ffffff !important;
}

/***
Grey Cascade
***/
/* Button */
.grey-cascade.btn {
  color: #FFFFFF;
  background-color: #95A5A6;
}
.grey-cascade.btn:hover, .grey-cascade.btn:focus, .grey-cascade.btn:active, .grey-cascade.btn.active {
  color: #FFFFFF;
  background-color: #829596;
}
.open .grey-cascade.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #829596;
}
.grey-cascade.btn:active, .grey-cascade.btn.active {
  background-image: none;
  background-color: #74898a;
}
.grey-cascade.btn:active:hover, .grey-cascade.btn.active:hover {
  background-color: #798d8f;
}
.open .grey-cascade.btn.dropdown-toggle {
  background-image: none;
}
.grey-cascade.btn.disabled, .grey-cascade.btn.disabled:hover, .grey-cascade.btn.disabled:focus, .grey-cascade.btn.disabled:active, .grey-cascade.btn.disabled.active, .grey-cascade.btn[disabled], .grey-cascade.btn[disabled]:hover, .grey-cascade.btn[disabled]:focus, .grey-cascade.btn[disabled]:active, .grey-cascade.btn[disabled].active, fieldset[disabled] .grey-cascade.btn, fieldset[disabled] .grey-cascade.btn:hover, fieldset[disabled] .grey-cascade.btn:focus, fieldset[disabled] .grey-cascade.btn:active, fieldset[disabled] .grey-cascade.btn.active {
  background-color: #95A5A6;
}
.grey-cascade.btn .badge {
  color: #95A5A6;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.grey-cascade-stripe {
  border-left: 3px solid #95A5A6;
}

/* Portlet */
.portlet > .portlet-body.grey-cascade,
.portlet.grey-cascade {
  background-color: #95A5A6;
}

.portlet.solid.grey-cascade > .portlet-title,
.portlet.solid.grey-cascade > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.grey-cascade > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.grey-cascade > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.grey-cascade > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.grey-cascade > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.grey-cascade > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.grey-cascade > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.grey-cascade > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.grey-cascade > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.grey-cascade {
  border: 1px solid #b1bdbd;
  border-top: 0;
}
.portlet.box.grey-cascade > .portlet-title {
  background-color: #95A5A6;
}
.portlet.box.grey-cascade > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.grey-cascade > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.grey-cascade > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #d2d9d9;
  color: #e0e5e5;
}
.portlet.box.grey-cascade > .portlet-title > .actions .btn-default > i {
  color: #e8ecec;
}
.portlet.box.grey-cascade > .portlet-title > .actions .btn-default:hover, .portlet.box.grey-cascade > .portlet-title > .actions .btn-default:focus, .portlet.box.grey-cascade > .portlet-title > .actions .btn-default:active, .portlet.box.grey-cascade > .portlet-title > .actions .btn-default.active {
  border: 1px solid #eef0f0;
  color: #fcfcfc;
}

/* Statistic Block */
.dashboard-stat.grey-cascade {
  background-color: #95A5A6;
}
.dashboard-stat.grey-cascade.dashboard-stat-light:hover {
  background-color: #8a9c9d;
}
.dashboard-stat.grey-cascade .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.grey-cascade .details .number {
  color: #FFFFFF;
}
.dashboard-stat.grey-cascade .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.grey-cascade .more {
  color: #FFFFFF;
  background-color: #8a9c9d;
}

/* General Element */
.bg-grey-cascade {
  border-color: #95A5A6 !important;
  background-image: none !important;
  background-color: #95A5A6 !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-grey-cascade {
  color: #95A5A6 !important;
}

.progress-bar.grey-cascade {
  background: #95A5A6 !important;
  color: #FFFFFF !important;
}

/***
Grey Silver
***/
/* Button */
.grey-silver.btn {
  color: #FAFCFB;
  background-color: #BFBFBF;
}
.grey-silver.btn:hover, .grey-silver.btn:focus, .grey-silver.btn:active, .grey-silver.btn.active {
  color: #FAFCFB;
  background-color: #adadad;
}
.open .grey-silver.btn.dropdown-toggle {
  color: #FAFCFB;
  background-color: #adadad;
}
.grey-silver.btn:active, .grey-silver.btn.active {
  background-image: none;
  background-color: #a0a0a0;
}
.grey-silver.btn:active:hover, .grey-silver.btn.active:hover {
  background-color: #a6a6a6;
}
.open .grey-silver.btn.dropdown-toggle {
  background-image: none;
}
.grey-silver.btn.disabled, .grey-silver.btn.disabled:hover, .grey-silver.btn.disabled:focus, .grey-silver.btn.disabled:active, .grey-silver.btn.disabled.active, .grey-silver.btn[disabled], .grey-silver.btn[disabled]:hover, .grey-silver.btn[disabled]:focus, .grey-silver.btn[disabled]:active, .grey-silver.btn[disabled].active, fieldset[disabled] .grey-silver.btn, fieldset[disabled] .grey-silver.btn:hover, fieldset[disabled] .grey-silver.btn:focus, fieldset[disabled] .grey-silver.btn:active, fieldset[disabled] .grey-silver.btn.active {
  background-color: #BFBFBF;
}
.grey-silver.btn .badge {
  color: #BFBFBF;
  background-color: #FAFCFB;
}

/* Button Strip */
.btn.grey-silver-stripe {
  border-left: 3px solid #BFBFBF;
}

/* Portlet */
.portlet > .portlet-body.grey-silver,
.portlet.grey-silver {
  background-color: #BFBFBF;
}

.portlet.solid.grey-silver > .portlet-title,
.portlet.solid.grey-silver > .portlet-body {
  border: 0;
  color: #FAFCFB;
}
.portlet.solid.grey-silver > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.grey-silver > .portlet-title > .caption > i {
  color: #FAFCFB;
}
.portlet.solid.grey-silver > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.grey-silver > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.grey-silver > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.grey-silver > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.grey-silver > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.grey-silver > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.grey-silver {
  border: 1px solid #d9d9d9;
  border-top: 0;
}
.portlet.box.grey-silver > .portlet-title {
  background-color: #BFBFBF;
}
.portlet.box.grey-silver > .portlet-title > .caption {
  color: #FAFCFB;
}
.portlet.box.grey-silver > .portlet-title > .caption > i {
  color: #FAFCFB;
}
.portlet.box.grey-silver > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #f7f7f7;
  color: white;
}
.portlet.box.grey-silver > .portlet-title > .actions .btn-default > i {
  color: white;
}
.portlet.box.grey-silver > .portlet-title > .actions .btn-default:hover, .portlet.box.grey-silver > .portlet-title > .actions .btn-default:focus, .portlet.box.grey-silver > .portlet-title > .actions .btn-default:active, .portlet.box.grey-silver > .portlet-title > .actions .btn-default.active {
  border: 1px solid white;
  color: white;
}

/* Statistic Block */
.dashboard-stat.grey-silver {
  background-color: #BFBFBF;
}
.dashboard-stat.grey-silver.dashboard-stat-light:hover {
  background-color: #b5b5b5;
}
.dashboard-stat.grey-silver .visual > i {
  color: #FAFCFB;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.grey-silver .details .number {
  color: #FAFCFB;
}
.dashboard-stat.grey-silver .details .desc {
  color: #FAFCFB;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.grey-silver .more {
  color: #FAFCFB;
  background-color: #b5b5b5;
}

/* General Element */
.bg-grey-silver {
  border-color: #BFBFBF !important;
  background-image: none !important;
  background-color: #BFBFBF !important;
  color: #FAFCFB !important;
}

/* General Text & Icon Font Colors */
.font-grey-silver {
  color: #BFBFBF !important;
}

.progress-bar.grey-silver {
  background: #BFBFBF !important;
  color: #FAFCFB !important;
}

/***
Grey Salsa
***/
/* Button */
.grey-salsa.btn {
  color: #FAFCFB;
  background-color: #ACB5C3;
}
.grey-salsa.btn:hover, .grey-salsa.btn:focus, .grey-salsa.btn:active, .grey-salsa.btn.active {
  color: #FAFCFB;
  background-color: #97a3b4;
}
.open .grey-salsa.btn.dropdown-toggle {
  color: #FAFCFB;
  background-color: #97a3b4;
}
.grey-salsa.btn:active, .grey-salsa.btn.active {
  background-image: none;
  background-color: #8895a9;
}
.grey-salsa.btn:active:hover, .grey-salsa.btn.active:hover {
  background-color: #8e9bae;
}
.open .grey-salsa.btn.dropdown-toggle {
  background-image: none;
}
.grey-salsa.btn.disabled, .grey-salsa.btn.disabled:hover, .grey-salsa.btn.disabled:focus, .grey-salsa.btn.disabled:active, .grey-salsa.btn.disabled.active, .grey-salsa.btn[disabled], .grey-salsa.btn[disabled]:hover, .grey-salsa.btn[disabled]:focus, .grey-salsa.btn[disabled]:active, .grey-salsa.btn[disabled].active, fieldset[disabled] .grey-salsa.btn, fieldset[disabled] .grey-salsa.btn:hover, fieldset[disabled] .grey-salsa.btn:focus, fieldset[disabled] .grey-salsa.btn:active, fieldset[disabled] .grey-salsa.btn.active {
  background-color: #ACB5C3;
}
.grey-salsa.btn .badge {
  color: #ACB5C3;
  background-color: #FAFCFB;
}

/* Button Strip */
.btn.grey-salsa-stripe {
  border-left: 3px solid #ACB5C3;
}

/* Portlet */
.portlet > .portlet-body.grey-salsa,
.portlet.grey-salsa {
  background-color: #ACB5C3;
}

.portlet.solid.grey-salsa > .portlet-title,
.portlet.solid.grey-salsa > .portlet-body {
  border: 0;
  color: #FAFCFB;
}
.portlet.solid.grey-salsa > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.grey-salsa > .portlet-title > .caption > i {
  color: #FAFCFB;
}
.portlet.solid.grey-salsa > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.grey-salsa > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.grey-salsa > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.grey-salsa > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.grey-salsa > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.grey-salsa > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.grey-salsa {
  border: 1px solid #cacfd8;
  border-top: 0;
}
.portlet.box.grey-salsa > .portlet-title {
  background-color: #ACB5C3;
}
.portlet.box.grey-salsa > .portlet-title > .caption {
  color: #FAFCFB;
}
.portlet.box.grey-salsa > .portlet-title > .caption > i {
  color: #FAFCFB;
}
.portlet.box.grey-salsa > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #edeff2;
  color: #fcfcfd;
}
.portlet.box.grey-salsa > .portlet-title > .actions .btn-default > i {
  color: white;
}
.portlet.box.grey-salsa > .portlet-title > .actions .btn-default:hover, .portlet.box.grey-salsa > .portlet-title > .actions .btn-default:focus, .portlet.box.grey-salsa > .portlet-title > .actions .btn-default:active, .portlet.box.grey-salsa > .portlet-title > .actions .btn-default.active {
  border: 1px solid white;
  color: white;
}

/* Statistic Block */
.dashboard-stat.grey-salsa {
  background-color: #ACB5C3;
}
.dashboard-stat.grey-salsa.dashboard-stat-light:hover {
  background-color: #a0aaba;
}
.dashboard-stat.grey-salsa .visual > i {
  color: #FAFCFB;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.grey-salsa .details .number {
  color: #FAFCFB;
}
.dashboard-stat.grey-salsa .details .desc {
  color: #FAFCFB;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.grey-salsa .more {
  color: #FAFCFB;
  background-color: #a0aaba;
}

/* General Element */
.bg-grey-salsa {
  border-color: #ACB5C3 !important;
  background-image: none !important;
  background-color: #ACB5C3 !important;
  color: #FAFCFB !important;
}

/* General Text & Icon Font Colors */
.font-grey-salsa {
  color: #ACB5C3 !important;
}

.progress-bar.grey-salsa {
  background: #ACB5C3 !important;
  color: #FAFCFB !important;
}

/***
Grey Salt
***/
/* Button */
.grey-salt.btn {
  color: #FAFCFB;
  background-color: #bfcad1;
}
.grey-salt.btn:hover, .grey-salt.btn:focus, .grey-salt.btn:active, .grey-salt.btn.active {
  color: #FAFCFB;
  background-color: #aab9c2;
}
.open .grey-salt.btn.dropdown-toggle {
  color: #FAFCFB;
  background-color: #aab9c2;
}
.grey-salt.btn:active, .grey-salt.btn.active {
  background-image: none;
  background-color: #9badb7;
}
.grey-salt.btn:active:hover, .grey-salt.btn.active:hover {
  background-color: #a1b1bc;
}
.open .grey-salt.btn.dropdown-toggle {
  background-image: none;
}
.grey-salt.btn.disabled, .grey-salt.btn.disabled:hover, .grey-salt.btn.disabled:focus, .grey-salt.btn.disabled:active, .grey-salt.btn.disabled.active, .grey-salt.btn[disabled], .grey-salt.btn[disabled]:hover, .grey-salt.btn[disabled]:focus, .grey-salt.btn[disabled]:active, .grey-salt.btn[disabled].active, fieldset[disabled] .grey-salt.btn, fieldset[disabled] .grey-salt.btn:hover, fieldset[disabled] .grey-salt.btn:focus, fieldset[disabled] .grey-salt.btn:active, fieldset[disabled] .grey-salt.btn.active {
  background-color: #bfcad1;
}
.grey-salt.btn .badge {
  color: #bfcad1;
  background-color: #FAFCFB;
}

/* Button Strip */
.btn.grey-salt-stripe {
  border-left: 3px solid #bfcad1;
}

/* Portlet */
.portlet > .portlet-body.grey-salt,
.portlet.grey-salt {
  background-color: #bfcad1;
}

.portlet.solid.grey-salt > .portlet-title,
.portlet.solid.grey-salt > .portlet-body {
  border: 0;
  color: #FAFCFB;
}
.portlet.solid.grey-salt > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.grey-salt > .portlet-title > .caption > i {
  color: #FAFCFB;
}
.portlet.solid.grey-salt > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.grey-salt > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.grey-salt > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.grey-salt > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.grey-salt > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.grey-salt > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.grey-salt {
  border: 1px solid #dde3e6;
  border-top: 0;
}
.portlet.box.grey-salt > .portlet-title {
  background-color: #bfcad1;
}
.portlet.box.grey-salt > .portlet-title > .caption {
  color: #FAFCFB;
}
.portlet.box.grey-salt > .portlet-title > .caption > i {
  color: #FAFCFB;
}
.portlet.box.grey-salt > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid white;
  color: white;
}
.portlet.box.grey-salt > .portlet-title > .actions .btn-default > i {
  color: white;
}
.portlet.box.grey-salt > .portlet-title > .actions .btn-default:hover, .portlet.box.grey-salt > .portlet-title > .actions .btn-default:focus, .portlet.box.grey-salt > .portlet-title > .actions .btn-default:active, .portlet.box.grey-salt > .portlet-title > .actions .btn-default.active {
  border: 1px solid white;
  color: white;
}

/* Statistic Block */
.dashboard-stat.grey-salt {
  background-color: #bfcad1;
}
.dashboard-stat.grey-salt.dashboard-stat-light:hover {
  background-color: #b3c0c8;
}
.dashboard-stat.grey-salt .visual > i {
  color: #FAFCFB;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.grey-salt .details .number {
  color: #FAFCFB;
}
.dashboard-stat.grey-salt .details .desc {
  color: #FAFCFB;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.grey-salt .more {
  color: #FAFCFB;
  background-color: #b3c0c8;
}

/* General Element */
.bg-grey-salt {
  border-color: #bfcad1 !important;
  background-image: none !important;
  background-color: #bfcad1 !important;
  color: #FAFCFB !important;
}

/* General Text & Icon Font Colors */
.font-grey-salt {
  color: #bfcad1 !important;
}

.progress-bar.grey-salt {
  background: #bfcad1 !important;
  color: #FAFCFB !important;
}

/***
Grey Mint
***/
/* Button */
.grey-mint.btn {
  color: #FAFCFB;
  background-color: #9eacb4;
}
.grey-mint.btn:hover, .grey-mint.btn:focus, .grey-mint.btn:active, .grey-mint.btn.active {
  color: #FAFCFB;
  background-color: #8a9ba4;
}
.open .grey-mint.btn.dropdown-toggle {
  color: #FAFCFB;
  background-color: #8a9ba4;
}
.grey-mint.btn:active, .grey-mint.btn.active {
  background-image: none;
  background-color: #7b8e99;
}
.grey-mint.btn:active:hover, .grey-mint.btn.active:hover {
  background-color: #81939e;
}
.open .grey-mint.btn.dropdown-toggle {
  background-image: none;
}
.grey-mint.btn.disabled, .grey-mint.btn.disabled:hover, .grey-mint.btn.disabled:focus, .grey-mint.btn.disabled:active, .grey-mint.btn.disabled.active, .grey-mint.btn[disabled], .grey-mint.btn[disabled]:hover, .grey-mint.btn[disabled]:focus, .grey-mint.btn[disabled]:active, .grey-mint.btn[disabled].active, fieldset[disabled] .grey-mint.btn, fieldset[disabled] .grey-mint.btn:hover, fieldset[disabled] .grey-mint.btn:focus, fieldset[disabled] .grey-mint.btn:active, fieldset[disabled] .grey-mint.btn.active {
  background-color: #9eacb4;
}
.grey-mint.btn .badge {
  color: #9eacb4;
  background-color: #FAFCFB;
}

/* Button Strip */
.btn.grey-mint-stripe {
  border-left: 3px solid #9eacb4;
}

/* Portlet */
.portlet > .portlet-body.grey-mint,
.portlet.grey-mint {
  background-color: #9eacb4;
}

.portlet.solid.grey-mint > .portlet-title,
.portlet.solid.grey-mint > .portlet-body {
  border: 0;
  color: #FAFCFB;
}
.portlet.solid.grey-mint > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.grey-mint > .portlet-title > .caption > i {
  color: #FAFCFB;
}
.portlet.solid.grey-mint > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.grey-mint > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.grey-mint > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.grey-mint > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.grey-mint > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.grey-mint > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.grey-mint {
  border: 1px solid #bbc5ca;
  border-top: 0;
}
.portlet.box.grey-mint > .portlet-title {
  background-color: #9eacb4;
}
.portlet.box.grey-mint > .portlet-title > .caption {
  color: #FAFCFB;
}
.portlet.box.grey-mint > .portlet-title > .caption > i {
  color: #FAFCFB;
}
.portlet.box.grey-mint > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #dde2e5;
  color: #eceef0;
}
.portlet.box.grey-mint > .portlet-title > .actions .btn-default > i {
  color: #f4f6f7;
}
.portlet.box.grey-mint > .portlet-title > .actions .btn-default:hover, .portlet.box.grey-mint > .portlet-title > .actions .btn-default:focus, .portlet.box.grey-mint > .portlet-title > .actions .btn-default:active, .portlet.box.grey-mint > .portlet-title > .actions .btn-default.active {
  border: 1px solid #fafbfb;
  color: white;
}

/* Statistic Block */
.dashboard-stat.grey-mint {
  background-color: #9eacb4;
}
.dashboard-stat.grey-mint.dashboard-stat-light:hover {
  background-color: #92a2ab;
}
.dashboard-stat.grey-mint .visual > i {
  color: #FAFCFB;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.grey-mint .details .number {
  color: #FAFCFB;
}
.dashboard-stat.grey-mint .details .desc {
  color: #FAFCFB;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.grey-mint .more {
  color: #FAFCFB;
  background-color: #92a2ab;
}

/* General Element */
.bg-grey-mint {
  border-color: #9eacb4 !important;
  background-image: none !important;
  background-color: #9eacb4 !important;
  color: #FAFCFB !important;
}

/* General Text & Icon Font Colors */
.font-grey-mint {
  color: #9eacb4 !important;
}

.progress-bar.grey-mint {
  background: #9eacb4 !important;
  color: #FAFCFB !important;
}

/***********
Red Colors
************/
/***
Red Default
***/
/* Button */
.red.btn {
  color: #FFFFFF;
  background-color: #cb5a5e;
}
.red.btn:hover, .red.btn:focus, .red.btn:active, .red.btn.active {
  color: #FFFFFF;
  background-color: #c23f44;
}
.open .red.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #c23f44;
}
.red.btn:active, .red.btn.active {
  background-image: none;
  background-color: #b0383c;
}
.red.btn:active:hover, .red.btn.active:hover {
  background-color: #b83a3e;
}
.open .red.btn.dropdown-toggle {
  background-image: none;
}
.red.btn.disabled, .red.btn.disabled:hover, .red.btn.disabled:focus, .red.btn.disabled:active, .red.btn.disabled.active, .red.btn[disabled], .red.btn[disabled]:hover, .red.btn[disabled]:focus, .red.btn[disabled]:active, .red.btn[disabled].active, fieldset[disabled] .red.btn, fieldset[disabled] .red.btn:hover, fieldset[disabled] .red.btn:focus, fieldset[disabled] .red.btn:active, fieldset[disabled] .red.btn.active {
  background-color: #cb5a5e;
}
.red.btn .badge {
  color: #cb5a5e;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.red-stripe {
  border-left: 3px solid #cb5a5e;
}

/* Portlet */
.portlet > .portlet-body.red,
.portlet.red {
  background-color: #cb5a5e;
}

.portlet.solid.red > .portlet-title,
.portlet.solid.red > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.red > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.red > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.red > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.red > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.red > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.red > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.red > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.red > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.red {
  border: 1px solid #d78184;
  border-top: 0;
}
.portlet.box.red > .portlet-title {
  background-color: #cb5a5e;
}
.portlet.box.red > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.red > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.red > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #e6afb1;
  color: #ecc3c4;
}
.portlet.box.red > .portlet-title > .actions .btn-default > i {
  color: #f0ced0;
}
.portlet.box.red > .portlet-title > .actions .btn-default:hover, .portlet.box.red > .portlet-title > .actions .btn-default:focus, .portlet.box.red > .portlet-title > .actions .btn-default:active, .portlet.box.red > .portlet-title > .actions .btn-default.active {
  border: 1px solid #f2d6d7;
  color: #f8e9ea;
}

/* Statistic Block */
.dashboard-stat.red {
  background-color: #cb5a5e;
}
.dashboard-stat.red.dashboard-stat-light:hover {
  background-color: #c64a4f;
}
.dashboard-stat.red .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.red .details .number {
  color: #FFFFFF;
}
.dashboard-stat.red .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.red .more {
  color: #FFFFFF;
  background-color: #c64a4f;
}

/* General Element */
.bg-red {
  border-color: #cb5a5e !important;
  background-image: none !important;
  background-color: #cb5a5e !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-red {
  color: #cb5a5e !important;
}

.progress-bar.red {
  background: #cb5a5e !important;
  color: #FFFFFF !important;
}

/***
Red Pink
***/
/* Button */
.red-pink.btn {
  color: #FFFFFF;
  background-color: #E08283;
}
.red-pink.btn:hover, .red-pink.btn:focus, .red-pink.btn:active, .red-pink.btn.active {
  color: #FFFFFF;
  background-color: #d96567;
}
.open .red-pink.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #d96567;
}
.red-pink.btn:active, .red-pink.btn.active {
  background-image: none;
  background-color: #d45152;
}
.red-pink.btn:active:hover, .red-pink.btn.active:hover {
  background-color: #d6595a;
}
.open .red-pink.btn.dropdown-toggle {
  background-image: none;
}
.red-pink.btn.disabled, .red-pink.btn.disabled:hover, .red-pink.btn.disabled:focus, .red-pink.btn.disabled:active, .red-pink.btn.disabled.active, .red-pink.btn[disabled], .red-pink.btn[disabled]:hover, .red-pink.btn[disabled]:focus, .red-pink.btn[disabled]:active, .red-pink.btn[disabled].active, fieldset[disabled] .red-pink.btn, fieldset[disabled] .red-pink.btn:hover, fieldset[disabled] .red-pink.btn:focus, fieldset[disabled] .red-pink.btn:active, fieldset[disabled] .red-pink.btn.active {
  background-color: #E08283;
}
.red-pink.btn .badge {
  color: #E08283;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.red-pink-stripe {
  border-left: 3px solid #E08283;
}

/* Portlet */
.portlet > .portlet-body.red-pink,
.portlet.red-pink {
  background-color: #E08283;
}

.portlet.solid.red-pink > .portlet-title,
.portlet.solid.red-pink > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.red-pink > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.red-pink > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.red-pink > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.red-pink > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.red-pink > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.red-pink > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.red-pink > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.red-pink > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.red-pink {
  border: 1px solid #eaabac;
  border-top: 0;
}
.portlet.box.red-pink > .portlet-title {
  background-color: #E08283;
}
.portlet.box.red-pink > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.red-pink > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.red-pink > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #f6dcdc;
  color: #fbf0f0;
}
.portlet.box.red-pink > .portlet-title > .actions .btn-default > i {
  color: #fefdfd;
}
.portlet.box.red-pink > .portlet-title > .actions .btn-default:hover, .portlet.box.red-pink > .portlet-title > .actions .btn-default:focus, .portlet.box.red-pink > .portlet-title > .actions .btn-default:active, .portlet.box.red-pink > .portlet-title > .actions .btn-default.active {
  border: 1px solid white;
  color: white;
}

/* Statistic Block */
.dashboard-stat.red-pink {
  background-color: #E08283;
}
.dashboard-stat.red-pink.dashboard-stat-light:hover {
  background-color: #dc7273;
}
.dashboard-stat.red-pink .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.red-pink .details .number {
  color: #FFFFFF;
}
.dashboard-stat.red-pink .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.red-pink .more {
  color: #FFFFFF;
  background-color: #dc7273;
}

/* General Element */
.bg-red-pink {
  border-color: #E08283 !important;
  background-image: none !important;
  background-color: #E08283 !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-red-pink {
  color: #E08283 !important;
}

.progress-bar.red-pink {
  background: #E08283 !important;
  color: #FFFFFF !important;
}

/***
Red Sunglo
***/
/* Button */
.red-sunglo.btn {
  color: #FFFFFF;
  background-color: #E26A6A;
}
.red-sunglo.btn:hover, .red-sunglo.btn:focus, .red-sunglo.btn:active, .red-sunglo.btn.active {
  color: #FFFFFF;
  background-color: #dc4c4c;
}
.open .red-sunglo.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #dc4c4c;
}
.red-sunglo.btn:active, .red-sunglo.btn.active {
  background-image: none;
  background-color: #d83737;
}
.red-sunglo.btn:active:hover, .red-sunglo.btn.active:hover {
  background-color: #da3f3f;
}
.open .red-sunglo.btn.dropdown-toggle {
  background-image: none;
}
.red-sunglo.btn.disabled, .red-sunglo.btn.disabled:hover, .red-sunglo.btn.disabled:focus, .red-sunglo.btn.disabled:active, .red-sunglo.btn.disabled.active, .red-sunglo.btn[disabled], .red-sunglo.btn[disabled]:hover, .red-sunglo.btn[disabled]:focus, .red-sunglo.btn[disabled]:active, .red-sunglo.btn[disabled].active, fieldset[disabled] .red-sunglo.btn, fieldset[disabled] .red-sunglo.btn:hover, fieldset[disabled] .red-sunglo.btn:focus, fieldset[disabled] .red-sunglo.btn:active, fieldset[disabled] .red-sunglo.btn.active {
  background-color: #E26A6A;
}
.red-sunglo.btn .badge {
  color: #E26A6A;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.red-sunglo-stripe {
  border-left: 3px solid #E26A6A;
}

/* Portlet */
.portlet > .portlet-body.red-sunglo,
.portlet.red-sunglo {
  background-color: #E26A6A;
}

.portlet.solid.red-sunglo > .portlet-title,
.portlet.solid.red-sunglo > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.red-sunglo > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.red-sunglo > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.red-sunglo > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.red-sunglo > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.red-sunglo > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.red-sunglo > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.red-sunglo > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.red-sunglo > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.red-sunglo {
  border: 1px solid #ea9595;
  border-top: 0;
}
.portlet.box.red-sunglo > .portlet-title {
  background-color: #E26A6A;
}
.portlet.box.red-sunglo > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.red-sunglo > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.red-sunglo > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #f4c8c8;
  color: #f8dddd;
}
.portlet.box.red-sunglo > .portlet-title > .actions .btn-default > i {
  color: #fbeaea;
}
.portlet.box.red-sunglo > .portlet-title > .actions .btn-default:hover, .portlet.box.red-sunglo > .portlet-title > .actions .btn-default:focus, .portlet.box.red-sunglo > .portlet-title > .actions .btn-default:active, .portlet.box.red-sunglo > .portlet-title > .actions .btn-default.active {
  border: 1px solid #fdf3f3;
  color: white;
}

/* Statistic Block */
.dashboard-stat.red-sunglo {
  background-color: #E26A6A;
}
.dashboard-stat.red-sunglo.dashboard-stat-light:hover {
  background-color: #df5959;
}
.dashboard-stat.red-sunglo .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.red-sunglo .details .number {
  color: #FFFFFF;
}
.dashboard-stat.red-sunglo .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.red-sunglo .more {
  color: #FFFFFF;
  background-color: #df5959;
}

/* General Element */
.bg-red-sunglo {
  border-color: #E26A6A !important;
  background-image: none !important;
  background-color: #E26A6A !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-red-sunglo {
  color: #E26A6A !important;
}

.progress-bar.red-sunglo {
  background: #E26A6A !important;
  color: #FFFFFF !important;
}

/***
Red Intense
***/
/* Button */
.red-intense.btn {
  color: #FFFFFF;
  background-color: #e35b5a;
}
.red-intense.btn:hover, .red-intense.btn:focus, .red-intense.btn:active, .red-intense.btn.active {
  color: #FFFFFF;
  background-color: #de3d3b;
}
.open .red-intense.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #de3d3b;
}
.red-intense.btn:active, .red-intense.btn.active {
  background-image: none;
  background-color: #da2726;
}
.red-intense.btn:active:hover, .red-intense.btn.active:hover {
  background-color: #dc302e;
}
.open .red-intense.btn.dropdown-toggle {
  background-image: none;
}
.red-intense.btn.disabled, .red-intense.btn.disabled:hover, .red-intense.btn.disabled:focus, .red-intense.btn.disabled:active, .red-intense.btn.disabled.active, .red-intense.btn[disabled], .red-intense.btn[disabled]:hover, .red-intense.btn[disabled]:focus, .red-intense.btn[disabled]:active, .red-intense.btn[disabled].active, fieldset[disabled] .red-intense.btn, fieldset[disabled] .red-intense.btn:hover, fieldset[disabled] .red-intense.btn:focus, fieldset[disabled] .red-intense.btn:active, fieldset[disabled] .red-intense.btn.active {
  background-color: #e35b5a;
}
.red-intense.btn .badge {
  color: #e35b5a;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.red-intense-stripe {
  border-left: 3px solid #e35b5a;
}

/* Portlet */
.portlet > .portlet-body.red-intense,
.portlet.red-intense {
  background-color: #e35b5a;
}

.portlet.solid.red-intense > .portlet-title,
.portlet.solid.red-intense > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.red-intense > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.red-intense > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.red-intense > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.red-intense > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.red-intense > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.red-intense > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.red-intense > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.red-intense > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.red-intense {
  border: 1px solid #ea8686;
  border-top: 0;
}
.portlet.box.red-intense > .portlet-title {
  background-color: #e35b5a;
}
.portlet.box.red-intense > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.red-intense > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.red-intense > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #f3baba;
  color: #f7d0d0;
}
.portlet.box.red-intense > .portlet-title > .actions .btn-default > i {
  color: #f9dddd;
}
.portlet.box.red-intense > .portlet-title > .actions .btn-default:hover, .portlet.box.red-intense > .portlet-title > .actions .btn-default:focus, .portlet.box.red-intense > .portlet-title > .actions .btn-default:active, .portlet.box.red-intense > .portlet-title > .actions .btn-default.active {
  border: 1px solid #fbe6e6;
  color: #fefbfb;
}

/* Statistic Block */
.dashboard-stat.red-intense {
  background-color: #e35b5a;
}
.dashboard-stat.red-intense.dashboard-stat-light:hover {
  background-color: #e04a49;
}
.dashboard-stat.red-intense .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.red-intense .details .number {
  color: #FFFFFF;
}
.dashboard-stat.red-intense .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.red-intense .more {
  color: #FFFFFF;
  background-color: #e04a49;
}

/* General Element */
.bg-red-intense {
  border-color: #e35b5a !important;
  background-image: none !important;
  background-color: #e35b5a !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-red-intense {
  color: #e35b5a !important;
}

.progress-bar.red-intense {
  background: #e35b5a !important;
  color: #FFFFFF !important;
}

/***
Red Thunderbird
***/
/* Button */
.red-thunderbird.btn {
  color: #FFFFFF;
  background-color: #D91E18;
}
.red-thunderbird.btn:hover, .red-thunderbird.btn:focus, .red-thunderbird.btn:active, .red-thunderbird.btn.active {
  color: #FFFFFF;
  background-color: #b91a14;
}
.open .red-thunderbird.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #b91a14;
}
.red-thunderbird.btn:active, .red-thunderbird.btn.active {
  background-image: none;
  background-color: #a21612;
}
.red-thunderbird.btn:active:hover, .red-thunderbird.btn.active:hover {
  background-color: #ab1813;
}
.open .red-thunderbird.btn.dropdown-toggle {
  background-image: none;
}
.red-thunderbird.btn.disabled, .red-thunderbird.btn.disabled:hover, .red-thunderbird.btn.disabled:focus, .red-thunderbird.btn.disabled:active, .red-thunderbird.btn.disabled.active, .red-thunderbird.btn[disabled], .red-thunderbird.btn[disabled]:hover, .red-thunderbird.btn[disabled]:focus, .red-thunderbird.btn[disabled]:active, .red-thunderbird.btn[disabled].active, fieldset[disabled] .red-thunderbird.btn, fieldset[disabled] .red-thunderbird.btn:hover, fieldset[disabled] .red-thunderbird.btn:focus, fieldset[disabled] .red-thunderbird.btn:active, fieldset[disabled] .red-thunderbird.btn.active {
  background-color: #D91E18;
}
.red-thunderbird.btn .badge {
  color: #D91E18;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.red-thunderbird-stripe {
  border-left: 3px solid #D91E18;
}

/* Portlet */
.portlet > .portlet-body.red-thunderbird,
.portlet.red-thunderbird {
  background-color: #D91E18;
}

.portlet.solid.red-thunderbird > .portlet-title,
.portlet.solid.red-thunderbird > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.red-thunderbird > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.red-thunderbird > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.red-thunderbird > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.red-thunderbird > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.red-thunderbird > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.red-thunderbird > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.red-thunderbird > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.red-thunderbird > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.red-thunderbird {
  border: 1px solid #e9403b;
  border-top: 0;
}
.portlet.box.red-thunderbird > .portlet-title {
  background-color: #D91E18;
}
.portlet.box.red-thunderbird > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.red-thunderbird > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.red-thunderbird > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #ef7672;
  color: #f28c89;
}
.portlet.box.red-thunderbird > .portlet-title > .actions .btn-default > i {
  color: #f39997;
}
.portlet.box.red-thunderbird > .portlet-title > .actions .btn-default:hover, .portlet.box.red-thunderbird > .portlet-title > .actions .btn-default:focus, .portlet.box.red-thunderbird > .portlet-title > .actions .btn-default:active, .portlet.box.red-thunderbird > .portlet-title > .actions .btn-default.active {
  border: 1px solid #f4a2a0;
  color: #f7b9b7;
}

/* Statistic Block */
.dashboard-stat.red-thunderbird {
  background-color: #D91E18;
}
.dashboard-stat.red-thunderbird.dashboard-stat-light:hover {
  background-color: #c71b16;
}
.dashboard-stat.red-thunderbird .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.red-thunderbird .details .number {
  color: #FFFFFF;
}
.dashboard-stat.red-thunderbird .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.red-thunderbird .more {
  color: #FFFFFF;
  background-color: #c71b16;
}

/* General Element */
.bg-red-thunderbird {
  border-color: #D91E18 !important;
  background-image: none !important;
  background-color: #D91E18 !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-red-thunderbird {
  color: #D91E18 !important;
}

.progress-bar.red-thunderbird {
  background: #D91E18 !important;
  color: #FFFFFF !important;
}

/***
Red Flamingo
***/
/* Button */
.red-flamingo.btn {
  color: #FFFFFF;
  background-color: #EF4836;
}
.red-flamingo.btn:hover, .red-flamingo.btn:focus, .red-flamingo.btn:active, .red-flamingo.btn.active {
  color: #FFFFFF;
  background-color: #ec2a15;
}
.open .red-flamingo.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #ec2a15;
}
.red-flamingo.btn:active, .red-flamingo.btn.active {
  background-image: none;
  background-color: #d72411;
}
.red-flamingo.btn:active:hover, .red-flamingo.btn.active:hover {
  background-color: #e02612;
}
.open .red-flamingo.btn.dropdown-toggle {
  background-image: none;
}
.red-flamingo.btn.disabled, .red-flamingo.btn.disabled:hover, .red-flamingo.btn.disabled:focus, .red-flamingo.btn.disabled:active, .red-flamingo.btn.disabled.active, .red-flamingo.btn[disabled], .red-flamingo.btn[disabled]:hover, .red-flamingo.btn[disabled]:focus, .red-flamingo.btn[disabled]:active, .red-flamingo.btn[disabled].active, fieldset[disabled] .red-flamingo.btn, fieldset[disabled] .red-flamingo.btn:hover, fieldset[disabled] .red-flamingo.btn:focus, fieldset[disabled] .red-flamingo.btn:active, fieldset[disabled] .red-flamingo.btn.active {
  background-color: #EF4836;
}
.red-flamingo.btn .badge {
  color: #EF4836;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.red-flamingo-stripe {
  border-left: 3px solid #EF4836;
}

/* Portlet */
.portlet > .portlet-body.red-flamingo,
.portlet.red-flamingo {
  background-color: #EF4836;
}

.portlet.solid.red-flamingo > .portlet-title,
.portlet.solid.red-flamingo > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.red-flamingo > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.red-flamingo > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.red-flamingo > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.red-flamingo > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.red-flamingo > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.red-flamingo > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.red-flamingo > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.red-flamingo > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.red-flamingo {
  border: 1px solid #f37365;
  border-top: 0;
}
.portlet.box.red-flamingo > .portlet-title {
  background-color: #EF4836;
}
.portlet.box.red-flamingo > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.red-flamingo > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.red-flamingo > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #f7a79e;
  color: #f9bcb6;
}
.portlet.box.red-flamingo > .portlet-title > .actions .btn-default > i {
  color: #fac9c4;
}
.portlet.box.red-flamingo > .portlet-title > .actions .btn-default:hover, .portlet.box.red-flamingo > .portlet-title > .actions .btn-default:focus, .portlet.box.red-flamingo > .portlet-title > .actions .btn-default:active, .portlet.box.red-flamingo > .portlet-title > .actions .btn-default.active {
  border: 1px solid #fbd2cd;
  color: #fde7e5;
}

/* Statistic Block */
.dashboard-stat.red-flamingo {
  background-color: #EF4836;
}
.dashboard-stat.red-flamingo.dashboard-stat-light:hover {
  background-color: #ed3723;
}
.dashboard-stat.red-flamingo .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.red-flamingo .details .number {
  color: #FFFFFF;
}
.dashboard-stat.red-flamingo .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.red-flamingo .more {
  color: #FFFFFF;
  background-color: #ed3723;
}

/* General Element */
.bg-red-flamingo {
  border-color: #EF4836 !important;
  background-image: none !important;
  background-color: #EF4836 !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-red-flamingo {
  color: #EF4836 !important;
}

.progress-bar.red-flamingo {
  background: #EF4836 !important;
  color: #FFFFFF !important;
}

/***
Red Flamingo
***/
/* Button */
.red-soft.btn {
  color: #FFFFFF;
  background-color: #d05454;
}
.red-soft.btn:hover, .red-soft.btn:focus, .red-soft.btn:active, .red-soft.btn.active {
  color: #FFFFFF;
  background-color: #c83838;
}
.open .red-soft.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #c83838;
}
.red-soft.btn:active, .red-soft.btn.active {
  background-image: none;
  background-color: #b53232;
}
.red-soft.btn:active:hover, .red-soft.btn.active:hover {
  background-color: #bd3434;
}
.open .red-soft.btn.dropdown-toggle {
  background-image: none;
}
.red-soft.btn.disabled, .red-soft.btn.disabled:hover, .red-soft.btn.disabled:focus, .red-soft.btn.disabled:active, .red-soft.btn.disabled.active, .red-soft.btn[disabled], .red-soft.btn[disabled]:hover, .red-soft.btn[disabled]:focus, .red-soft.btn[disabled]:active, .red-soft.btn[disabled].active, fieldset[disabled] .red-soft.btn, fieldset[disabled] .red-soft.btn:hover, fieldset[disabled] .red-soft.btn:focus, fieldset[disabled] .red-soft.btn:active, fieldset[disabled] .red-soft.btn.active {
  background-color: #d05454;
}
.red-soft.btn .badge {
  color: #d05454;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.red-soft-stripe {
  border-left: 3px solid #d05454;
}

/* Portlet */
.portlet > .portlet-body.red-soft,
.portlet.red-soft {
  background-color: #d05454;
}

.portlet.solid.red-soft > .portlet-title,
.portlet.solid.red-soft > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.red-soft > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.red-soft > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.red-soft > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.red-soft > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.red-soft > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.red-soft > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.red-soft > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.red-soft > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.red-soft {
  border: 1px solid #db7c7c;
  border-top: 0;
}
.portlet.box.red-soft > .portlet-title {
  background-color: #d05454;
}
.portlet.box.red-soft > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.red-soft > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.red-soft > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #e8acac;
  color: #eec0c0;
}
.portlet.box.red-soft > .portlet-title > .actions .btn-default > i {
  color: #f1cccc;
}
.portlet.box.red-soft > .portlet-title > .actions .btn-default:hover, .portlet.box.red-soft > .portlet-title > .actions .btn-default:focus, .portlet.box.red-soft > .portlet-title > .actions .btn-default:active, .portlet.box.red-soft > .portlet-title > .actions .btn-default.active {
  border: 1px solid #f3d4d4;
  color: #f9e8e8;
}

/* Statistic Block */
.dashboard-stat.red-soft {
  background-color: #d05454;
}
.dashboard-stat.red-soft.dashboard-stat-light:hover {
  background-color: #cc4444;
}
.dashboard-stat.red-soft .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.red-soft .details .number {
  color: #FFFFFF;
}
.dashboard-stat.red-soft .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.red-soft .more {
  color: #FFFFFF;
  background-color: #cc4444;
}

/* General Element */
.bg-red-soft {
  border-color: #d05454 !important;
  background-image: none !important;
  background-color: #d05454 !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-red-soft {
  color: #d05454 !important;
}

.progress-bar.red-soft {
  background: #d05454 !important;
  color: #FFFFFF !important;
}

/***
Red Haze
***/
/* Button */
.red-haze.btn {
  color: #FFFFFF;
  background-color: #f36a5a;
}
.red-haze.btn:hover, .red-haze.btn:focus, .red-haze.btn:active, .red-haze.btn.active {
  color: #FFFFFF;
  background-color: #f14c39;
}
.open .red-haze.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #f14c39;
}
.red-haze.btn:active, .red-haze.btn.active {
  background-image: none;
  background-color: #ef3621;
}
.red-haze.btn:active:hover, .red-haze.btn.active:hover {
  background-color: #f03f2a;
}
.open .red-haze.btn.dropdown-toggle {
  background-image: none;
}
.red-haze.btn.disabled, .red-haze.btn.disabled:hover, .red-haze.btn.disabled:focus, .red-haze.btn.disabled:active, .red-haze.btn.disabled.active, .red-haze.btn[disabled], .red-haze.btn[disabled]:hover, .red-haze.btn[disabled]:focus, .red-haze.btn[disabled]:active, .red-haze.btn[disabled].active, fieldset[disabled] .red-haze.btn, fieldset[disabled] .red-haze.btn:hover, fieldset[disabled] .red-haze.btn:focus, fieldset[disabled] .red-haze.btn:active, fieldset[disabled] .red-haze.btn.active {
  background-color: #f36a5a;
}
.red-haze.btn .badge {
  color: #f36a5a;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.red-haze-stripe {
  border-left: 3px solid #f36a5a;
}

/* Portlet */
.portlet > .portlet-body.red-haze,
.portlet.red-haze {
  background-color: #f36a5a;
}

.portlet.solid.red-haze > .portlet-title,
.portlet.solid.red-haze > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.red-haze > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.red-haze > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.red-haze > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.red-haze > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.red-haze > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.red-haze > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.red-haze > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.red-haze > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.red-haze {
  border: 1px solid #f6958a;
  border-top: 0;
}
.portlet.box.red-haze > .portlet-title {
  background-color: #f36a5a;
}
.portlet.box.red-haze > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.red-haze > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.red-haze > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #fbc8c3;
  color: #fcdeda;
}
.portlet.box.red-haze > .portlet-title > .actions .btn-default > i {
  color: #fdebe9;
}
.portlet.box.red-haze > .portlet-title > .actions .btn-default:hover, .portlet.box.red-haze > .portlet-title > .actions .btn-default:focus, .portlet.box.red-haze > .portlet-title > .actions .btn-default:active, .portlet.box.red-haze > .portlet-title > .actions .btn-default.active {
  border: 1px solid #fef3f2;
  color: white;
}

/* Statistic Block */
.dashboard-stat.red-haze {
  background-color: #f36a5a;
}
.dashboard-stat.red-haze.dashboard-stat-light:hover {
  background-color: #f25947;
}
.dashboard-stat.red-haze .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.red-haze .details .number {
  color: #FFFFFF;
}
.dashboard-stat.red-haze .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.red-haze .more {
  color: #FFFFFF;
  background-color: #f25947;
}

/* General Element */
.bg-red-haze {
  border-color: #f36a5a !important;
  background-image: none !important;
  background-color: #f36a5a !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-red-haze {
  color: #f36a5a !important;
}

.progress-bar.red-haze {
  background: #f36a5a !important;
  color: #FFFFFF !important;
}

/***********
Yellow Colors
************/
/***
Yellow Default
***/
/* Button */
.yellow.btn {
  color: #FFFFFF;
  background-color: #c49f47;
}
.yellow.btn:hover, .yellow.btn:focus, .yellow.btn:active, .yellow.btn.active {
  color: #FFFFFF;
  background-color: #af8c38;
}
.open .yellow.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #af8c38;
}
.yellow.btn:active, .yellow.btn.active {
  background-image: none;
  background-color: #9c7c32;
}
.yellow.btn:active:hover, .yellow.btn.active:hover {
  background-color: #a48334;
}
.open .yellow.btn.dropdown-toggle {
  background-image: none;
}
.yellow.btn.disabled, .yellow.btn.disabled:hover, .yellow.btn.disabled:focus, .yellow.btn.disabled:active, .yellow.btn.disabled.active, .yellow.btn[disabled], .yellow.btn[disabled]:hover, .yellow.btn[disabled]:focus, .yellow.btn[disabled]:active, .yellow.btn[disabled].active, fieldset[disabled] .yellow.btn, fieldset[disabled] .yellow.btn:hover, fieldset[disabled] .yellow.btn:focus, fieldset[disabled] .yellow.btn:active, fieldset[disabled] .yellow.btn.active {
  background-color: #c49f47;
}
.yellow.btn .badge {
  color: #c49f47;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.yellow-stripe {
  border-left: 3px solid #c49f47;
}

/* Portlet */
.portlet > .portlet-body.yellow,
.portlet.yellow {
  background-color: #c49f47;
}

.portlet.solid.yellow > .portlet-title,
.portlet.solid.yellow > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.yellow > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.yellow > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.yellow > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.yellow > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.yellow > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.yellow > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.yellow > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.yellow > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.yellow {
  border: 1px solid #d0b36e;
  border-top: 0;
}
.portlet.box.yellow > .portlet-title {
  background-color: #c49f47;
}
.portlet.box.yellow > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.yellow > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.yellow > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #dfcb9c;
  color: #e5d5af;
}
.portlet.box.yellow > .portlet-title > .actions .btn-default > i {
  color: #e9dbbb;
}
.portlet.box.yellow > .portlet-title > .actions .btn-default:hover, .portlet.box.yellow > .portlet-title > .actions .btn-default:focus, .portlet.box.yellow > .portlet-title > .actions .btn-default:active, .portlet.box.yellow > .portlet-title > .actions .btn-default.active {
  border: 1px solid #ecdfc3;
  color: #f2ead6;
}

/* Statistic Block */
.dashboard-stat.yellow {
  background-color: #c49f47;
}
.dashboard-stat.yellow.dashboard-stat-light:hover {
  background-color: #bb953c;
}
.dashboard-stat.yellow .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.yellow .details .number {
  color: #FFFFFF;
}
.dashboard-stat.yellow .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.yellow .more {
  color: #FFFFFF;
  background-color: #bb953c;
}

/* General Element */
.bg-yellow {
  border-color: #c49f47 !important;
  background-image: none !important;
  background-color: #c49f47 !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-yellow {
  color: #c49f47 !important;
}

.progress-bar.yellow {
  background: #c49f47 !important;
  color: #FFFFFF !important;
}

/***
Yellow Gold
***/
/* Button */
.yellow-gold.btn {
  color: #FFFFFF;
  background-color: #E87E04;
}
.yellow-gold.btn:hover, .yellow-gold.btn:focus, .yellow-gold.btn:active, .yellow-gold.btn.active {
  color: #FFFFFF;
  background-color: #c56b03;
}
.open .yellow-gold.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #c56b03;
}
.yellow-gold.btn:active, .yellow-gold.btn.active {
  background-image: none;
  background-color: #ac5d03;
}
.yellow-gold.btn:active:hover, .yellow-gold.btn.active:hover {
  background-color: #b66303;
}
.open .yellow-gold.btn.dropdown-toggle {
  background-image: none;
}
.yellow-gold.btn.disabled, .yellow-gold.btn.disabled:hover, .yellow-gold.btn.disabled:focus, .yellow-gold.btn.disabled:active, .yellow-gold.btn.disabled.active, .yellow-gold.btn[disabled], .yellow-gold.btn[disabled]:hover, .yellow-gold.btn[disabled]:focus, .yellow-gold.btn[disabled]:active, .yellow-gold.btn[disabled].active, fieldset[disabled] .yellow-gold.btn, fieldset[disabled] .yellow-gold.btn:hover, fieldset[disabled] .yellow-gold.btn:focus, fieldset[disabled] .yellow-gold.btn:active, fieldset[disabled] .yellow-gold.btn.active {
  background-color: #E87E04;
}
.yellow-gold.btn .badge {
  color: #E87E04;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.yellow-gold-stripe {
  border-left: 3px solid #E87E04;
}

/* Portlet */
.portlet > .portlet-body.yellow-gold,
.portlet.yellow-gold {
  background-color: #E87E04;
}

.portlet.solid.yellow-gold > .portlet-title,
.portlet.solid.yellow-gold > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.yellow-gold > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.yellow-gold > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.yellow-gold > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.yellow-gold > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.yellow-gold > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.yellow-gold > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.yellow-gold > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.yellow-gold > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.yellow-gold {
  border: 1px solid #fb9724;
  border-top: 0;
}
.portlet.box.yellow-gold > .portlet-title {
  background-color: #E87E04;
}
.portlet.box.yellow-gold > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.yellow-gold > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.yellow-gold > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #fcb460;
  color: #fdbf79;
}
.portlet.box.yellow-gold > .portlet-title > .actions .btn-default > i {
  color: #fdc788;
}
.portlet.box.yellow-gold > .portlet-title > .actions .btn-default:hover, .portlet.box.yellow-gold > .portlet-title > .actions .btn-default:focus, .portlet.box.yellow-gold > .portlet-title > .actions .btn-default:active, .portlet.box.yellow-gold > .portlet-title > .actions .btn-default.active {
  border: 1px solid #fdcb92;
  color: #fed7ab;
}

/* Statistic Block */
.dashboard-stat.yellow-gold {
  background-color: #E87E04;
}
.dashboard-stat.yellow-gold.dashboard-stat-light:hover {
  background-color: #d47304;
}
.dashboard-stat.yellow-gold .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.yellow-gold .details .number {
  color: #FFFFFF;
}
.dashboard-stat.yellow-gold .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.yellow-gold .more {
  color: #FFFFFF;
  background-color: #d47304;
}

/* General Element */
.bg-yellow-gold {
  border-color: #E87E04 !important;
  background-image: none !important;
  background-color: #E87E04 !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-yellow-gold {
  color: #E87E04 !important;
}

.progress-bar.yellow-gold {
  background: #E87E04 !important;
  color: #FFFFFF !important;
}

/***
Yellow Casablanca
***/
/* Button */
.yellow-casablanca.btn {
  color: #FFFFFF;
  background-color: #f2784b;
}
.yellow-casablanca.btn:hover, .yellow-casablanca.btn:focus, .yellow-casablanca.btn:active, .yellow-casablanca.btn.active {
  color: #FFFFFF;
  background-color: #f05f2a;
}
.open .yellow-casablanca.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #f05f2a;
}
.yellow-casablanca.btn:active, .yellow-casablanca.btn.active {
  background-image: none;
  background-color: #ee4d12;
}
.yellow-casablanca.btn:active:hover, .yellow-casablanca.btn.active:hover {
  background-color: #ef541b;
}
.open .yellow-casablanca.btn.dropdown-toggle {
  background-image: none;
}
.yellow-casablanca.btn.disabled, .yellow-casablanca.btn.disabled:hover, .yellow-casablanca.btn.disabled:focus, .yellow-casablanca.btn.disabled:active, .yellow-casablanca.btn.disabled.active, .yellow-casablanca.btn[disabled], .yellow-casablanca.btn[disabled]:hover, .yellow-casablanca.btn[disabled]:focus, .yellow-casablanca.btn[disabled]:active, .yellow-casablanca.btn[disabled].active, fieldset[disabled] .yellow-casablanca.btn, fieldset[disabled] .yellow-casablanca.btn:hover, fieldset[disabled] .yellow-casablanca.btn:focus, fieldset[disabled] .yellow-casablanca.btn:active, fieldset[disabled] .yellow-casablanca.btn.active {
  background-color: #f2784b;
}
.yellow-casablanca.btn .badge {
  color: #f2784b;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.yellow-casablanca-stripe {
  border-left: 3px solid #f2784b;
}

/* Portlet */
.portlet > .portlet-body.yellow-casablanca,
.portlet.yellow-casablanca {
  background-color: #f2784b;
}

.portlet.solid.yellow-casablanca > .portlet-title,
.portlet.solid.yellow-casablanca > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.yellow-casablanca > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.yellow-casablanca > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.yellow-casablanca > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.yellow-casablanca > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.yellow-casablanca > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.yellow-casablanca > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.yellow-casablanca > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.yellow-casablanca > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.yellow-casablanca {
  border: 1px solid #f59c7b;
  border-top: 0;
}
.portlet.box.yellow-casablanca > .portlet-title {
  background-color: #f2784b;
}
.portlet.box.yellow-casablanca > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.yellow-casablanca > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.yellow-casablanca > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #fac6b4;
  color: #fbd8cb;
}
.portlet.box.yellow-casablanca > .portlet-title > .actions .btn-default > i {
  color: #fce3da;
}
.portlet.box.yellow-casablanca > .portlet-title > .actions .btn-default:hover, .portlet.box.yellow-casablanca > .portlet-title > .actions .btn-default:focus, .portlet.box.yellow-casablanca > .portlet-title > .actions .btn-default:active, .portlet.box.yellow-casablanca > .portlet-title > .actions .btn-default.active {
  border: 1px solid #fdeae3;
  color: #fffcfb;
}

/* Statistic Block */
.dashboard-stat.yellow-casablanca {
  background-color: #f2784b;
}
.dashboard-stat.yellow-casablanca.dashboard-stat-light:hover {
  background-color: #f16a38;
}
.dashboard-stat.yellow-casablanca .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.yellow-casablanca .details .number {
  color: #FFFFFF;
}
.dashboard-stat.yellow-casablanca .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.yellow-casablanca .more {
  color: #FFFFFF;
  background-color: #f16a38;
}

/* General Element */
.bg-yellow-casablanca {
  border-color: #f2784b !important;
  background-image: none !important;
  background-color: #f2784b !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-yellow-casablanca {
  color: #f2784b !important;
}

.progress-bar.yellow-casablanca {
  background: #f2784b !important;
  color: #FFFFFF !important;
}

/***
Yellow Crusta
***/
/* Button */
.yellow-crusta.btn {
  color: #FFFFFF;
  background-color: #f3c200;
}
.yellow-crusta.btn:hover, .yellow-crusta.btn:focus, .yellow-crusta.btn:active, .yellow-crusta.btn.active {
  color: #FFFFFF;
  background-color: #cfa500;
}
.open .yellow-crusta.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #cfa500;
}
.yellow-crusta.btn:active, .yellow-crusta.btn.active {
  background-image: none;
  background-color: #b69100;
}
.yellow-crusta.btn:active:hover, .yellow-crusta.btn.active:hover {
  background-color: #c09900;
}
.open .yellow-crusta.btn.dropdown-toggle {
  background-image: none;
}
.yellow-crusta.btn.disabled, .yellow-crusta.btn.disabled:hover, .yellow-crusta.btn.disabled:focus, .yellow-crusta.btn.disabled:active, .yellow-crusta.btn.disabled.active, .yellow-crusta.btn[disabled], .yellow-crusta.btn[disabled]:hover, .yellow-crusta.btn[disabled]:focus, .yellow-crusta.btn[disabled]:active, .yellow-crusta.btn[disabled].active, fieldset[disabled] .yellow-crusta.btn, fieldset[disabled] .yellow-crusta.btn:hover, fieldset[disabled] .yellow-crusta.btn:focus, fieldset[disabled] .yellow-crusta.btn:active, fieldset[disabled] .yellow-crusta.btn.active {
  background-color: #f3c200;
}
.yellow-crusta.btn .badge {
  color: #f3c200;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.yellow-crusta-stripe {
  border-left: 3px solid #f3c200;
}

/* Portlet */
.portlet > .portlet-body.yellow-crusta,
.portlet.yellow-crusta {
  background-color: #f3c200;
}

.portlet.solid.yellow-crusta > .portlet-title,
.portlet.solid.yellow-crusta > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.yellow-crusta > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.yellow-crusta > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.yellow-crusta > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.yellow-crusta > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.yellow-crusta > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.yellow-crusta > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.yellow-crusta > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.yellow-crusta > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.yellow-crusta {
  border: 1px solid #ffd327;
  border-top: 0;
}
.portlet.box.yellow-crusta > .portlet-title {
  background-color: #f3c200;
}
.portlet.box.yellow-crusta > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.yellow-crusta > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.yellow-crusta > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #ffe064;
  color: #ffe57e;
}
.portlet.box.yellow-crusta > .portlet-title > .actions .btn-default > i {
  color: #ffe88d;
}
.portlet.box.yellow-crusta > .portlet-title > .actions .btn-default:hover, .portlet.box.yellow-crusta > .portlet-title > .actions .btn-default:focus, .portlet.box.yellow-crusta > .portlet-title > .actions .btn-default:active, .portlet.box.yellow-crusta > .portlet-title > .actions .btn-default.active {
  border: 1px solid #ffea97;
  color: #ffefb1;
}

/* Statistic Block */
.dashboard-stat.yellow-crusta {
  background-color: #f3c200;
}
.dashboard-stat.yellow-crusta.dashboard-stat-light:hover {
  background-color: #dfb200;
}
.dashboard-stat.yellow-crusta .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.yellow-crusta .details .number {
  color: #FFFFFF;
}
.dashboard-stat.yellow-crusta .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.yellow-crusta .more {
  color: #FFFFFF;
  background-color: #dfb200;
}

/* General Element */
.bg-yellow-crusta {
  border-color: #f3c200 !important;
  background-image: none !important;
  background-color: #f3c200 !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-yellow-crusta {
  color: #f3c200 !important;
}

.progress-bar.yellow-crusta {
  background: #f3c200 !important;
  color: #FFFFFF !important;
}

/***
Yellow Lemon
***/
/* Button */
.yellow-lemon.btn {
  color: #FFFFFF;
  background-color: #F7CA18;
}
.yellow-lemon.btn:hover, .yellow-lemon.btn:focus, .yellow-lemon.btn:active, .yellow-lemon.btn.active {
  color: #FFFFFF;
  background-color: #e3b708;
}
.open .yellow-lemon.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #e3b708;
}
.yellow-lemon.btn:active, .yellow-lemon.btn.active {
  background-image: none;
  background-color: #cba307;
}
.yellow-lemon.btn:active:hover, .yellow-lemon.btn.active:hover {
  background-color: #d5ab07;
}
.open .yellow-lemon.btn.dropdown-toggle {
  background-image: none;
}
.yellow-lemon.btn.disabled, .yellow-lemon.btn.disabled:hover, .yellow-lemon.btn.disabled:focus, .yellow-lemon.btn.disabled:active, .yellow-lemon.btn.disabled.active, .yellow-lemon.btn[disabled], .yellow-lemon.btn[disabled]:hover, .yellow-lemon.btn[disabled]:focus, .yellow-lemon.btn[disabled]:active, .yellow-lemon.btn[disabled].active, fieldset[disabled] .yellow-lemon.btn, fieldset[disabled] .yellow-lemon.btn:hover, fieldset[disabled] .yellow-lemon.btn:focus, fieldset[disabled] .yellow-lemon.btn:active, fieldset[disabled] .yellow-lemon.btn.active {
  background-color: #F7CA18;
}
.yellow-lemon.btn .badge {
  color: #F7CA18;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.yellow-lemon-stripe {
  border-left: 3px solid #F7CA18;
}

/* Portlet */
.portlet > .portlet-body.yellow-lemon,
.portlet.yellow-lemon {
  background-color: #F7CA18;
}

.portlet.solid.yellow-lemon > .portlet-title,
.portlet.solid.yellow-lemon > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.yellow-lemon > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.yellow-lemon > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.yellow-lemon > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.yellow-lemon > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.yellow-lemon > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.yellow-lemon > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.yellow-lemon > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.yellow-lemon > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.yellow-lemon {
  border: 1px solid #f9d549;
  border-top: 0;
}
.portlet.box.yellow-lemon > .portlet-title {
  background-color: #F7CA18;
}
.portlet.box.yellow-lemon > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.yellow-lemon > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.yellow-lemon > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #fbe384;
  color: #fce99d;
}
.portlet.box.yellow-lemon > .portlet-title > .actions .btn-default > i {
  color: #fcecac;
}
.portlet.box.yellow-lemon > .portlet-title > .actions .btn-default:hover, .portlet.box.yellow-lemon > .portlet-title > .actions .btn-default:focus, .portlet.box.yellow-lemon > .portlet-title > .actions .btn-default:active, .portlet.box.yellow-lemon > .portlet-title > .actions .btn-default.active {
  border: 1px solid #fceeb6;
  color: #fdf4ce;
}

/* Statistic Block */
.dashboard-stat.yellow-lemon {
  background-color: #F7CA18;
}
.dashboard-stat.yellow-lemon.dashboard-stat-light:hover {
  background-color: #f2c308;
}
.dashboard-stat.yellow-lemon .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.yellow-lemon .details .number {
  color: #FFFFFF;
}
.dashboard-stat.yellow-lemon .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.yellow-lemon .more {
  color: #FFFFFF;
  background-color: #f2c308;
}

/* General Element */
.bg-yellow-lemon {
  border-color: #F7CA18 !important;
  background-image: none !important;
  background-color: #F7CA18 !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-yellow-lemon {
  color: #F7CA18 !important;
}

.progress-bar.yellow-lemon {
  background: #F7CA18 !important;
  color: #FFFFFF !important;
}

/***
Yellow Saffron
***/
/* Button */
.yellow-saffron.btn {
  color: #FFFFFF;
  background-color: #F4D03F;
}
.yellow-saffron.btn:hover, .yellow-saffron.btn:focus, .yellow-saffron.btn:active, .yellow-saffron.btn.active {
  color: #FFFFFF;
  background-color: #f2c81d;
}
.open .yellow-saffron.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #f2c81d;
}
.yellow-saffron.btn:active, .yellow-saffron.btn.active {
  background-image: none;
  background-color: #e8bd0d;
}
.yellow-saffron.btn:active:hover, .yellow-saffron.btn.active:hover {
  background-color: #f1c40f;
}
.open .yellow-saffron.btn.dropdown-toggle {
  background-image: none;
}
.yellow-saffron.btn.disabled, .yellow-saffron.btn.disabled:hover, .yellow-saffron.btn.disabled:focus, .yellow-saffron.btn.disabled:active, .yellow-saffron.btn.disabled.active, .yellow-saffron.btn[disabled], .yellow-saffron.btn[disabled]:hover, .yellow-saffron.btn[disabled]:focus, .yellow-saffron.btn[disabled]:active, .yellow-saffron.btn[disabled].active, fieldset[disabled] .yellow-saffron.btn, fieldset[disabled] .yellow-saffron.btn:hover, fieldset[disabled] .yellow-saffron.btn:focus, fieldset[disabled] .yellow-saffron.btn:active, fieldset[disabled] .yellow-saffron.btn.active {
  background-color: #F4D03F;
}
.yellow-saffron.btn .badge {
  color: #F4D03F;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.yellow-saffron-stripe {
  border-left: 3px solid #F4D03F;
}

/* Portlet */
.portlet > .portlet-body.yellow-saffron,
.portlet.yellow-saffron {
  background-color: #F4D03F;
}

.portlet.solid.yellow-saffron > .portlet-title,
.portlet.solid.yellow-saffron > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.yellow-saffron > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.yellow-saffron > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.yellow-saffron > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.yellow-saffron > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.yellow-saffron > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.yellow-saffron > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.yellow-saffron > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.yellow-saffron > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.yellow-saffron {
  border: 1px solid #f7dc6f;
  border-top: 0;
}
.portlet.box.yellow-saffron > .portlet-title {
  background-color: #F4D03F;
}
.portlet.box.yellow-saffron > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.yellow-saffron > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.yellow-saffron > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #faeaa9;
  color: #fbf0c1;
}
.portlet.box.yellow-saffron > .portlet-title > .actions .btn-default > i {
  color: #fcf3d0;
}
.portlet.box.yellow-saffron > .portlet-title > .actions .btn-default:hover, .portlet.box.yellow-saffron > .portlet-title > .actions .btn-default:focus, .portlet.box.yellow-saffron > .portlet-title > .actions .btn-default:active, .portlet.box.yellow-saffron > .portlet-title > .actions .btn-default.active {
  border: 1px solid #fdf6d9;
  color: #fefcf1;
}

/* Statistic Block */
.dashboard-stat.yellow-saffron {
  background-color: #F4D03F;
}
.dashboard-stat.yellow-saffron.dashboard-stat-light:hover {
  background-color: #f3cb2c;
}
.dashboard-stat.yellow-saffron .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.yellow-saffron .details .number {
  color: #FFFFFF;
}
.dashboard-stat.yellow-saffron .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.yellow-saffron .more {
  color: #FFFFFF;
  background-color: #f3cb2c;
}

/* General Element */
.bg-yellow-saffron {
  border-color: #F4D03F !important;
  background-image: none !important;
  background-color: #F4D03F !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-yellow-saffron {
  color: #F4D03F !important;
}

.progress-bar.yellow-saffron {
  background: #F4D03F !important;
  color: #FFFFFF !important;
}

/***********
Purple Colors
************/
/***
Purple Default
***/
/* Button */
.purple.btn {
  color: #FFFFFF;
  background-color: #8e5fa2;
}
.purple.btn:hover, .purple.btn:focus, .purple.btn:active, .purple.btn.active {
  color: #FFFFFF;
  background-color: #7a518c;
}
.open .purple.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #7a518c;
}
.purple.btn:active, .purple.btn.active {
  background-image: none;
  background-color: #6c487c;
}
.purple.btn:active:hover, .purple.btn.active:hover {
  background-color: #724c82;
}
.open .purple.btn.dropdown-toggle {
  background-image: none;
}
.purple.btn.disabled, .purple.btn.disabled:hover, .purple.btn.disabled:focus, .purple.btn.disabled:active, .purple.btn.disabled.active, .purple.btn[disabled], .purple.btn[disabled]:hover, .purple.btn[disabled]:focus, .purple.btn[disabled]:active, .purple.btn[disabled].active, fieldset[disabled] .purple.btn, fieldset[disabled] .purple.btn:hover, fieldset[disabled] .purple.btn:focus, fieldset[disabled] .purple.btn:active, fieldset[disabled] .purple.btn.active {
  background-color: #8e5fa2;
}
.purple.btn .badge {
  color: #8e5fa2;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.purple-stripe {
  border-left: 3px solid #8e5fa2;
}

/* Portlet */
.portlet > .portlet-body.purple,
.portlet.purple {
  background-color: #8e5fa2;
}

.portlet.solid.purple > .portlet-title,
.portlet.solid.purple > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.purple > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.purple > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.purple > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.purple > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.purple > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.purple > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.purple > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.purple > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.purple {
  border: 1px solid #a57fb5;
  border-top: 0;
}
.portlet.box.purple > .portlet-title {
  background-color: #8e5fa2;
}
.portlet.box.purple > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.purple > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.purple > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #c0a6cb;
  color: #ccb6d5;
}
.portlet.box.purple > .portlet-title > .actions .btn-default > i {
  color: #d2c0da;
}
.portlet.box.purple > .portlet-title > .actions .btn-default:hover, .portlet.box.purple > .portlet-title > .actions .btn-default:focus, .portlet.box.purple > .portlet-title > .actions .btn-default:active, .portlet.box.purple > .portlet-title > .actions .btn-default.active {
  border: 1px solid #d7c6de;
  color: #e2d6e7;
}

/* Statistic Block */
.dashboard-stat.purple {
  background-color: #8e5fa2;
}
.dashboard-stat.purple.dashboard-stat-light:hover {
  background-color: #835796;
}
.dashboard-stat.purple .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.purple .details .number {
  color: #FFFFFF;
}
.dashboard-stat.purple .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.purple .more {
  color: #FFFFFF;
  background-color: #835796;
}

/* General Element */
.bg-purple {
  border-color: #8e5fa2 !important;
  background-image: none !important;
  background-color: #8e5fa2 !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-purple {
  color: #8e5fa2 !important;
}

.progress-bar.purple {
  background: #8e5fa2 !important;
  color: #FFFFFF !important;
}

/***
Purple Plum
***/
/* Button */
.purple-plum.btn {
  color: #FFFFFF;
  background-color: #8775a7;
}
.purple-plum.btn:hover, .purple-plum.btn:focus, .purple-plum.btn:active, .purple-plum.btn.active {
  color: #FFFFFF;
  background-color: #746198;
}
.open .purple-plum.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #746198;
}
.purple-plum.btn:active, .purple-plum.btn.active {
  background-image: none;
  background-color: #685788;
}
.purple-plum.btn:active:hover, .purple-plum.btn.active:hover {
  background-color: #6d5b8e;
}
.open .purple-plum.btn.dropdown-toggle {
  background-image: none;
}
.purple-plum.btn.disabled, .purple-plum.btn.disabled:hover, .purple-plum.btn.disabled:focus, .purple-plum.btn.disabled:active, .purple-plum.btn.disabled.active, .purple-plum.btn[disabled], .purple-plum.btn[disabled]:hover, .purple-plum.btn[disabled]:focus, .purple-plum.btn[disabled]:active, .purple-plum.btn[disabled].active, fieldset[disabled] .purple-plum.btn, fieldset[disabled] .purple-plum.btn:hover, fieldset[disabled] .purple-plum.btn:focus, fieldset[disabled] .purple-plum.btn:active, fieldset[disabled] .purple-plum.btn.active {
  background-color: #8775a7;
}
.purple-plum.btn .badge {
  color: #8775a7;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.purple-plum-stripe {
  border-left: 3px solid #8775a7;
}

/* Portlet */
.portlet > .portlet-body.purple-plum,
.portlet.purple-plum {
  background-color: #8775a7;
}

.portlet.solid.purple-plum > .portlet-title,
.portlet.solid.purple-plum > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.purple-plum > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.purple-plum > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.purple-plum > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.purple-plum > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.purple-plum > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.purple-plum > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.purple-plum > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.purple-plum > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.purple-plum {
  border: 1px solid #a294bb;
  border-top: 0;
}
.portlet.box.purple-plum > .portlet-title {
  background-color: #8775a7;
}
.portlet.box.purple-plum > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.purple-plum > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.purple-plum > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #c3bad3;
  color: #d0c9dd;
}
.portlet.box.purple-plum > .portlet-title > .actions .btn-default > i {
  color: #d8d2e3;
}
.portlet.box.purple-plum > .portlet-title > .actions .btn-default:hover, .portlet.box.purple-plum > .portlet-title > .actions .btn-default:focus, .portlet.box.purple-plum > .portlet-title > .actions .btn-default:active, .portlet.box.purple-plum > .portlet-title > .actions .btn-default.active {
  border: 1px solid #ded9e7;
  color: #ebe8f0;
}

/* Statistic Block */
.dashboard-stat.purple-plum {
  background-color: #8775a7;
}
.dashboard-stat.purple-plum.dashboard-stat-light:hover {
  background-color: #7c699f;
}
.dashboard-stat.purple-plum .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.purple-plum .details .number {
  color: #FFFFFF;
}
.dashboard-stat.purple-plum .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.purple-plum .more {
  color: #FFFFFF;
  background-color: #7c699f;
}

/* General Element */
.bg-purple-plum {
  border-color: #8775a7 !important;
  background-image: none !important;
  background-color: #8775a7 !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-purple-plum {
  color: #8775a7 !important;
}

.progress-bar.purple-plum {
  background: #8775a7 !important;
  color: #FFFFFF !important;
}

/***
Purple Medium
***/
/* Button */
.purple-medium.btn {
  color: #FFFFFF;
  background-color: #BF55EC;
}
.purple-medium.btn:hover, .purple-medium.btn:focus, .purple-medium.btn:active, .purple-medium.btn.active {
  color: #FFFFFF;
  background-color: #b335e8;
}
.open .purple-medium.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #b335e8;
}
.purple-medium.btn:active, .purple-medium.btn.active {
  background-image: none;
  background-color: #aa1ee6;
}
.purple-medium.btn:active:hover, .purple-medium.btn.active:hover {
  background-color: #ae27e7;
}
.open .purple-medium.btn.dropdown-toggle {
  background-image: none;
}
.purple-medium.btn.disabled, .purple-medium.btn.disabled:hover, .purple-medium.btn.disabled:focus, .purple-medium.btn.disabled:active, .purple-medium.btn.disabled.active, .purple-medium.btn[disabled], .purple-medium.btn[disabled]:hover, .purple-medium.btn[disabled]:focus, .purple-medium.btn[disabled]:active, .purple-medium.btn[disabled].active, fieldset[disabled] .purple-medium.btn, fieldset[disabled] .purple-medium.btn:hover, fieldset[disabled] .purple-medium.btn:focus, fieldset[disabled] .purple-medium.btn:active, fieldset[disabled] .purple-medium.btn.active {
  background-color: #BF55EC;
}
.purple-medium.btn .badge {
  color: #BF55EC;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.purple-medium-stripe {
  border-left: 3px solid #BF55EC;
}

/* Portlet */
.portlet > .portlet-body.purple-medium,
.portlet.purple-medium {
  background-color: #BF55EC;
}

.portlet.solid.purple-medium > .portlet-title,
.portlet.solid.purple-medium > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.purple-medium > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.purple-medium > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.purple-medium > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.purple-medium > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.purple-medium > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.purple-medium > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.purple-medium > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.purple-medium > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.purple-medium {
  border: 1px solid #d083f1;
  border-top: 0;
}
.portlet.box.purple-medium > .portlet-title {
  background-color: #BF55EC;
}
.portlet.box.purple-medium > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.purple-medium > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.purple-medium > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #e5baf7;
  color: #eed1fa;
}
.portlet.box.purple-medium > .portlet-title > .actions .btn-default > i {
  color: #f3dffb;
}
.portlet.box.purple-medium > .portlet-title > .actions .btn-default:hover, .portlet.box.purple-medium > .portlet-title > .actions .btn-default:focus, .portlet.box.purple-medium > .portlet-title > .actions .btn-default:active, .portlet.box.purple-medium > .portlet-title > .actions .btn-default.active {
  border: 1px solid #f6e8fc;
  color: white;
}

/* Statistic Block */
.dashboard-stat.purple-medium {
  background-color: #BF55EC;
}
.dashboard-stat.purple-medium.dashboard-stat-light:hover {
  background-color: #b843ea;
}
.dashboard-stat.purple-medium .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.purple-medium .details .number {
  color: #FFFFFF;
}
.dashboard-stat.purple-medium .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.purple-medium .more {
  color: #FFFFFF;
  background-color: #b843ea;
}

/* General Element */
.bg-purple-medium {
  border-color: #BF55EC !important;
  background-image: none !important;
  background-color: #BF55EC !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-purple-medium {
  color: #BF55EC !important;
}

.progress-bar.purple-medium {
  background: #BF55EC !important;
  color: #FFFFFF !important;
}

/***
Purple Studio
***/
/* Button */
.purple-studio.btn {
  color: #FFFFFF;
  background-color: #8E44AD;
}
.purple-studio.btn:hover, .purple-studio.btn:focus, .purple-studio.btn:active, .purple-studio.btn.active {
  color: #FFFFFF;
  background-color: #793a93;
}
.open .purple-studio.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #793a93;
}
.purple-studio.btn:active, .purple-studio.btn.active {
  background-image: none;
  background-color: #6a3381;
}
.purple-studio.btn:active:hover, .purple-studio.btn.active:hover {
  background-color: #703688;
}
.open .purple-studio.btn.dropdown-toggle {
  background-image: none;
}
.purple-studio.btn.disabled, .purple-studio.btn.disabled:hover, .purple-studio.btn.disabled:focus, .purple-studio.btn.disabled:active, .purple-studio.btn.disabled.active, .purple-studio.btn[disabled], .purple-studio.btn[disabled]:hover, .purple-studio.btn[disabled]:focus, .purple-studio.btn[disabled]:active, .purple-studio.btn[disabled].active, fieldset[disabled] .purple-studio.btn, fieldset[disabled] .purple-studio.btn:hover, fieldset[disabled] .purple-studio.btn:focus, fieldset[disabled] .purple-studio.btn:active, fieldset[disabled] .purple-studio.btn.active {
  background-color: #8E44AD;
}
.purple-studio.btn .badge {
  color: #8E44AD;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.purple-studio-stripe {
  border-left: 3px solid #8E44AD;
}

/* Portlet */
.portlet > .portlet-body.purple-studio,
.portlet.purple-studio {
  background-color: #8E44AD;
}

.portlet.solid.purple-studio > .portlet-title,
.portlet.solid.purple-studio > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.purple-studio > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.purple-studio > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.purple-studio > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.purple-studio > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.purple-studio > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.purple-studio > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.purple-studio > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.purple-studio > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.purple-studio {
  border: 1px solid #a563c1;
  border-top: 0;
}
.portlet.box.purple-studio > .portlet-title {
  background-color: #8E44AD;
}
.portlet.box.purple-studio > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.purple-studio > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.purple-studio > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #bf8ed3;
  color: #c9a1da;
}
.portlet.box.purple-studio > .portlet-title > .actions .btn-default > i {
  color: #cfacde;
}
.portlet.box.purple-studio > .portlet-title > .actions .btn-default:hover, .portlet.box.purple-studio > .portlet-title > .actions .btn-default:focus, .portlet.box.purple-studio > .portlet-title > .actions .btn-default:active, .portlet.box.purple-studio > .portlet-title > .actions .btn-default.active {
  border: 1px solid #d4b3e1;
  color: #dec5e8;
}

/* Statistic Block */
.dashboard-stat.purple-studio {
  background-color: #8E44AD;
}
.dashboard-stat.purple-studio.dashboard-stat-light:hover {
  background-color: #823e9e;
}
.dashboard-stat.purple-studio .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.purple-studio .details .number {
  color: #FFFFFF;
}
.dashboard-stat.purple-studio .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.purple-studio .more {
  color: #FFFFFF;
  background-color: #823e9e;
}

/* General Element */
.bg-purple-studio {
  border-color: #8E44AD !important;
  background-image: none !important;
  background-color: #8E44AD !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-purple-studio {
  color: #8E44AD !important;
}

.progress-bar.purple-studio {
  background: #8E44AD !important;
  color: #FFFFFF !important;
}

/***
Purple Wisteria
***/
/* Button */
.purple-wisteria.btn {
  color: #FFFFFF;
  background-color: #9B59B6;
}
.purple-wisteria.btn:hover, .purple-wisteria.btn:focus, .purple-wisteria.btn:active, .purple-wisteria.btn.active {
  color: #FFFFFF;
  background-color: #8948a3;
}
.open .purple-wisteria.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #8948a3;
}
.purple-wisteria.btn:active, .purple-wisteria.btn.active {
  background-image: none;
  background-color: #7a4092;
}
.purple-wisteria.btn:active:hover, .purple-wisteria.btn.active:hover {
  background-color: #804399;
}
.open .purple-wisteria.btn.dropdown-toggle {
  background-image: none;
}
.purple-wisteria.btn.disabled, .purple-wisteria.btn.disabled:hover, .purple-wisteria.btn.disabled:focus, .purple-wisteria.btn.disabled:active, .purple-wisteria.btn.disabled.active, .purple-wisteria.btn[disabled], .purple-wisteria.btn[disabled]:hover, .purple-wisteria.btn[disabled]:focus, .purple-wisteria.btn[disabled]:active, .purple-wisteria.btn[disabled].active, fieldset[disabled] .purple-wisteria.btn, fieldset[disabled] .purple-wisteria.btn:hover, fieldset[disabled] .purple-wisteria.btn:focus, fieldset[disabled] .purple-wisteria.btn:active, fieldset[disabled] .purple-wisteria.btn.active {
  background-color: #9B59B6;
}
.purple-wisteria.btn .badge {
  color: #9B59B6;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.purple-wisteria-stripe {
  border-left: 3px solid #9B59B6;
}

/* Portlet */
.portlet > .portlet-body.purple-wisteria,
.portlet.purple-wisteria {
  background-color: #9B59B6;
}

.portlet.solid.purple-wisteria > .portlet-title,
.portlet.solid.purple-wisteria > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.purple-wisteria > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.purple-wisteria > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.purple-wisteria > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.purple-wisteria > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.purple-wisteria > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.purple-wisteria > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.purple-wisteria > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.purple-wisteria > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.purple-wisteria {
  border: 1px solid #b07cc6;
  border-top: 0;
}
.portlet.box.purple-wisteria > .portlet-title {
  background-color: #9B59B6;
}
.portlet.box.purple-wisteria > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.purple-wisteria > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.purple-wisteria > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #caa7d8;
  color: #d5b9e0;
}
.portlet.box.purple-wisteria > .portlet-title > .actions .btn-default > i {
  color: #dbc3e5;
}
.portlet.box.purple-wisteria > .portlet-title > .actions .btn-default:hover, .portlet.box.purple-wisteria > .portlet-title > .actions .btn-default:focus, .portlet.box.purple-wisteria > .portlet-title > .actions .btn-default:active, .portlet.box.purple-wisteria > .portlet-title > .actions .btn-default.active {
  border: 1px solid #dfcae8;
  color: #eadcf0;
}

/* Statistic Block */
.dashboard-stat.purple-wisteria {
  background-color: #9B59B6;
}
.dashboard-stat.purple-wisteria.dashboard-stat-light:hover {
  background-color: #924dae;
}
.dashboard-stat.purple-wisteria .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.purple-wisteria .details .number {
  color: #FFFFFF;
}
.dashboard-stat.purple-wisteria .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.purple-wisteria .more {
  color: #FFFFFF;
  background-color: #924dae;
}

/* General Element */
.bg-purple-wisteria {
  border-color: #9B59B6 !important;
  background-image: none !important;
  background-color: #9B59B6 !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-purple-wisteria {
  color: #9B59B6 !important;
}

.progress-bar.purple-wisteria {
  background: #9B59B6 !important;
  color: #FFFFFF !important;
}

/***
Purple Seance
***/
/* Button */
.purple-seance.btn {
  color: #FFFFFF;
  background-color: #9A12B3;
}
.purple-seance.btn:hover, .purple-seance.btn:focus, .purple-seance.btn:active, .purple-seance.btn.active {
  color: #FFFFFF;
  background-color: #7e0f93;
}
.open .purple-seance.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #7e0f93;
}
.purple-seance.btn:active, .purple-seance.btn.active {
  background-image: none;
  background-color: #6a0c7b;
}
.purple-seance.btn:active:hover, .purple-seance.btn.active:hover {
  background-color: #720d85;
}
.open .purple-seance.btn.dropdown-toggle {
  background-image: none;
}
.purple-seance.btn.disabled, .purple-seance.btn.disabled:hover, .purple-seance.btn.disabled:focus, .purple-seance.btn.disabled:active, .purple-seance.btn.disabled.active, .purple-seance.btn[disabled], .purple-seance.btn[disabled]:hover, .purple-seance.btn[disabled]:focus, .purple-seance.btn[disabled]:active, .purple-seance.btn[disabled].active, fieldset[disabled] .purple-seance.btn, fieldset[disabled] .purple-seance.btn:hover, fieldset[disabled] .purple-seance.btn:focus, fieldset[disabled] .purple-seance.btn:active, fieldset[disabled] .purple-seance.btn.active {
  background-color: #9A12B3;
}
.purple-seance.btn .badge {
  color: #9A12B3;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.purple-seance-stripe {
  border-left: 3px solid #9A12B3;
}

/* Portlet */
.portlet > .portlet-body.purple-seance,
.portlet.purple-seance {
  background-color: #9A12B3;
}

.portlet.solid.purple-seance > .portlet-title,
.portlet.solid.purple-seance > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.purple-seance > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.purple-seance > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.purple-seance > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.purple-seance > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.purple-seance > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.purple-seance > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.purple-seance > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.purple-seance > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.purple-seance {
  border: 1px solid #c217e1;
  border-top: 0;
}
.portlet.box.purple-seance > .portlet-title {
  background-color: #9A12B3;
}
.portlet.box.purple-seance > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.purple-seance > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.purple-seance > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #d349ed;
  color: #d960ef;
}
.portlet.box.purple-seance > .portlet-title > .actions .btn-default > i {
  color: #dc6ef0;
}
.portlet.box.purple-seance > .portlet-title > .actions .btn-default:hover, .portlet.box.purple-seance > .portlet-title > .actions .btn-default:focus, .portlet.box.purple-seance > .portlet-title > .actions .btn-default:active, .portlet.box.purple-seance > .portlet-title > .actions .btn-default.active {
  border: 1px solid #de77f1;
  color: #e48ef4;
}

/* Statistic Block */
.dashboard-stat.purple-seance {
  background-color: #9A12B3;
}
.dashboard-stat.purple-seance.dashboard-stat-light:hover {
  background-color: #8a10a0;
}
.dashboard-stat.purple-seance .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.purple-seance .details .number {
  color: #FFFFFF;
}
.dashboard-stat.purple-seance .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.purple-seance .more {
  color: #FFFFFF;
  background-color: #8a10a0;
}

/* General Element */
.bg-purple-seance {
  border-color: #9A12B3 !important;
  background-image: none !important;
  background-color: #9A12B3 !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-purple-seance {
  color: #9A12B3 !important;
}

.progress-bar.purple-seance {
  background: #9A12B3 !important;
  color: #FFFFFF !important;
}

/***
Purple Intense
***/
/* Button */
.purple-intense.btn {
  color: #FFFFFF;
  background-color: #8775a7;
}
.purple-intense.btn:hover, .purple-intense.btn:focus, .purple-intense.btn:active, .purple-intense.btn.active {
  color: #FFFFFF;
  background-color: #746198;
}
.open .purple-intense.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #746198;
}
.purple-intense.btn:active, .purple-intense.btn.active {
  background-image: none;
  background-color: #685788;
}
.purple-intense.btn:active:hover, .purple-intense.btn.active:hover {
  background-color: #6d5b8e;
}
.open .purple-intense.btn.dropdown-toggle {
  background-image: none;
}
.purple-intense.btn.disabled, .purple-intense.btn.disabled:hover, .purple-intense.btn.disabled:focus, .purple-intense.btn.disabled:active, .purple-intense.btn.disabled.active, .purple-intense.btn[disabled], .purple-intense.btn[disabled]:hover, .purple-intense.btn[disabled]:focus, .purple-intense.btn[disabled]:active, .purple-intense.btn[disabled].active, fieldset[disabled] .purple-intense.btn, fieldset[disabled] .purple-intense.btn:hover, fieldset[disabled] .purple-intense.btn:focus, fieldset[disabled] .purple-intense.btn:active, fieldset[disabled] .purple-intense.btn.active {
  background-color: #8775a7;
}
.purple-intense.btn .badge {
  color: #8775a7;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.purple-intense-stripe {
  border-left: 3px solid #8775a7;
}

/* Portlet */
.portlet > .portlet-body.purple-intense,
.portlet.purple-intense {
  background-color: #8775a7;
}

.portlet.solid.purple-intense > .portlet-title,
.portlet.solid.purple-intense > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.purple-intense > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.purple-intense > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.purple-intense > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.purple-intense > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.purple-intense > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.purple-intense > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.purple-intense > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.purple-intense > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.purple-intense {
  border: 1px solid #a294bb;
  border-top: 0;
}
.portlet.box.purple-intense > .portlet-title {
  background-color: #8775a7;
}
.portlet.box.purple-intense > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.purple-intense > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.purple-intense > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #c3bad3;
  color: #d0c9dd;
}
.portlet.box.purple-intense > .portlet-title > .actions .btn-default > i {
  color: #d8d2e3;
}
.portlet.box.purple-intense > .portlet-title > .actions .btn-default:hover, .portlet.box.purple-intense > .portlet-title > .actions .btn-default:focus, .portlet.box.purple-intense > .portlet-title > .actions .btn-default:active, .portlet.box.purple-intense > .portlet-title > .actions .btn-default.active {
  border: 1px solid #ded9e7;
  color: #ebe8f0;
}

/* Statistic Block */
.dashboard-stat.purple-intense {
  background-color: #8775a7;
}
.dashboard-stat.purple-intense.dashboard-stat-light:hover {
  background-color: #7c699f;
}
.dashboard-stat.purple-intense .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.purple-intense .details .number {
  color: #FFFFFF;
}
.dashboard-stat.purple-intense .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.purple-intense .more {
  color: #FFFFFF;
  background-color: #7c699f;
}

/* General Element */
.bg-purple-intense {
  border-color: #8775a7 !important;
  background-image: none !important;
  background-color: #8775a7 !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-purple-intense {
  color: #8775a7 !important;
}

.progress-bar.purple-intense {
  background: #8775a7 !important;
  color: #FFFFFF !important;
}

/***
Purple Sharp
***/
/* Button */
.purple-sharp.btn {
  color: #FFFFFF;
  background-color: #796799;
}
.purple-sharp.btn:hover, .purple-sharp.btn:focus, .purple-sharp.btn:active, .purple-sharp.btn.active {
  color: #FFFFFF;
  background-color: #685884;
}
.open .purple-sharp.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #685884;
}
.purple-sharp.btn:active, .purple-sharp.btn.active {
  background-image: none;
  background-color: #5c4e75;
}
.purple-sharp.btn:active:hover, .purple-sharp.btn.active:hover {
  background-color: #61527b;
}
.open .purple-sharp.btn.dropdown-toggle {
  background-image: none;
}
.purple-sharp.btn.disabled, .purple-sharp.btn.disabled:hover, .purple-sharp.btn.disabled:focus, .purple-sharp.btn.disabled:active, .purple-sharp.btn.disabled.active, .purple-sharp.btn[disabled], .purple-sharp.btn[disabled]:hover, .purple-sharp.btn[disabled]:focus, .purple-sharp.btn[disabled]:active, .purple-sharp.btn[disabled].active, fieldset[disabled] .purple-sharp.btn, fieldset[disabled] .purple-sharp.btn:hover, fieldset[disabled] .purple-sharp.btn:focus, fieldset[disabled] .purple-sharp.btn:active, fieldset[disabled] .purple-sharp.btn.active {
  background-color: #796799;
}
.purple-sharp.btn .badge {
  color: #796799;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.purple-sharp-stripe {
  border-left: 3px solid #796799;
}

/* Portlet */
.portlet > .portlet-body.purple-sharp,
.portlet.purple-sharp {
  background-color: #796799;
}

.portlet.solid.purple-sharp > .portlet-title,
.portlet.solid.purple-sharp > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.purple-sharp > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.purple-sharp > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.purple-sharp > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.purple-sharp > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.purple-sharp > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.purple-sharp > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.purple-sharp > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.purple-sharp > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.purple-sharp {
  border: 1px solid #9486ad;
  border-top: 0;
}
.portlet.box.purple-sharp > .portlet-title {
  background-color: #796799;
}
.portlet.box.purple-sharp > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.purple-sharp > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.purple-sharp > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #b4aac6;
  color: #c2b9d0;
}
.portlet.box.purple-sharp > .portlet-title > .actions .btn-default > i {
  color: #cac3d6;
}
.portlet.box.purple-sharp > .portlet-title > .actions .btn-default:hover, .portlet.box.purple-sharp > .portlet-title > .actions .btn-default:focus, .portlet.box.purple-sharp > .portlet-title > .actions .btn-default:active, .portlet.box.purple-sharp > .portlet-title > .actions .btn-default.active {
  border: 1px solid #cfc9db;
  color: #ddd8e5;
}

/* Statistic Block */
.dashboard-stat.purple-sharp {
  background-color: #796799;
}
.dashboard-stat.purple-sharp.dashboard-stat-light:hover {
  background-color: #6f5f8d;
}
.dashboard-stat.purple-sharp .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.purple-sharp .details .number {
  color: #FFFFFF;
}
.dashboard-stat.purple-sharp .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.purple-sharp .more {
  color: #FFFFFF;
  background-color: #6f5f8d;
}

/* General Element */
.bg-purple-sharp {
  border-color: #796799 !important;
  background-image: none !important;
  background-color: #796799 !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-purple-sharp {
  color: #796799 !important;
}

.progress-bar.purple-sharp {
  background: #796799 !important;
  color: #FFFFFF !important;
}

/***
Purple Soft
***/
/* Button */
.purple-soft.btn {
  color: #FFFFFF;
  background-color: #8877a9;
}
.purple-soft.btn:hover, .purple-soft.btn:focus, .purple-soft.btn:active, .purple-soft.btn.active {
  color: #FFFFFF;
  background-color: #75629b;
}
.open .purple-soft.btn.dropdown-toggle {
  color: #FFFFFF;
  background-color: #75629b;
}
.purple-soft.btn:active, .purple-soft.btn.active {
  background-image: none;
  background-color: #69588b;
}
.purple-soft.btn:active:hover, .purple-soft.btn.active:hover {
  background-color: #6e5c91;
}
.open .purple-soft.btn.dropdown-toggle {
  background-image: none;
}
.purple-soft.btn.disabled, .purple-soft.btn.disabled:hover, .purple-soft.btn.disabled:focus, .purple-soft.btn.disabled:active, .purple-soft.btn.disabled.active, .purple-soft.btn[disabled], .purple-soft.btn[disabled]:hover, .purple-soft.btn[disabled]:focus, .purple-soft.btn[disabled]:active, .purple-soft.btn[disabled].active, fieldset[disabled] .purple-soft.btn, fieldset[disabled] .purple-soft.btn:hover, fieldset[disabled] .purple-soft.btn:focus, fieldset[disabled] .purple-soft.btn:active, fieldset[disabled] .purple-soft.btn.active {
  background-color: #8877a9;
}
.purple-soft.btn .badge {
  color: #8877a9;
  background-color: #FFFFFF;
}

/* Button Strip */
.btn.purple-soft-stripe {
  border-left: 3px solid #8877a9;
}

/* Portlet */
.portlet > .portlet-body.purple-soft,
.portlet.purple-soft {
  background-color: #8877a9;
}

.portlet.solid.purple-soft > .portlet-title,
.portlet.solid.purple-soft > .portlet-body {
  border: 0;
  color: #FFFFFF;
}
.portlet.solid.purple-soft > .portlet-title > .caption {
  font-weight: 400;
}
.portlet.solid.purple-soft > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.solid.purple-soft > .portlet-title > .tools > a.remove {
  background-image: url(/public/resources/images/icons/portlet-remove-icon-white.png);
}
.portlet.solid.purple-soft > .portlet-title > .tools > a.config {
  background-image: url(/public/resources/images/icons/portlet-config-icon-white.png);
}
.portlet.solid.purple-soft > .portlet-title > .tools > a.reload {
  background-image: url(/public/resources/images/icons/portlet-reload-icon-white.png);
}
.portlet.solid.purple-soft > .portlet-title > .tools > a.expand {
  background-image: url(/public/resources/images/icons/portlet-expand-icon-white.png);
}
.portlet.solid.purple-soft > .portlet-title > .tools > a.collapse {
  background-image: url(/public/resources/images/icons/portlet-collapse-icon-white.png);
}
.portlet.solid.purple-soft > .portlet-title > .tools > a.fullscreen {
  color: #fdfdfd;
}

.portlet.box.purple-soft {
  border: 1px solid #a396bd;
  border-top: 0;
}
.portlet.box.purple-soft > .portlet-title {
  background-color: #8877a9;
}
.portlet.box.purple-soft > .portlet-title > .caption {
  color: #FFFFFF;
}
.portlet.box.purple-soft > .portlet-title > .caption > i {
  color: #FFFFFF;
}
.portlet.box.purple-soft > .portlet-title > .actions .btn-default {
  background: transparent !important;
  background-color: transparent !important;
  border: 1px solid #c4bcd4;
  color: #d2cbde;
}
.portlet.box.purple-soft > .portlet-title > .actions .btn-default > i {
  color: #dad5e4;
}
.portlet.box.purple-soft > .portlet-title > .actions .btn-default:hover, .portlet.box.purple-soft > .portlet-title > .actions .btn-default:focus, .portlet.box.purple-soft > .portlet-title > .actions .btn-default:active, .portlet.box.purple-soft > .portlet-title > .actions .btn-default.active {
  border: 1px solid #dfdbe8;
  color: #edebf2;
}

/* Statistic Block */
.dashboard-stat.purple-soft {
  background-color: #8877a9;
}
.dashboard-stat.purple-soft.dashboard-stat-light:hover {
  background-color: #7d6ba1;
}
.dashboard-stat.purple-soft .visual > i {
  color: #FFFFFF;
  opacity: 0.1;
  filter: alpha(opacity=10);
}
.dashboard-stat.purple-soft .details .number {
  color: #FFFFFF;
}
.dashboard-stat.purple-soft .details .desc {
  color: #FFFFFF;
  opacity: 1;
  filter: alpha(opacity=100);
}
.dashboard-stat.purple-soft .more {
  color: #FFFFFF;
  background-color: #7d6ba1;
}

/* General Element */
.bg-purple-soft {
  border-color: #8877a9 !important;
  background-image: none !important;
  background-color: #8877a9 !important;
  color: #FFFFFF !important;
}

/* General Text & Icon Font Colors */
.font-purple-soft {
  color: #8877a9 !important;
}

.progress-bar.purple-soft {
  background: #8877a9 !important;
  color: #FFFFFF !important;
}

/***
Transparent Button
***/
.btn-transparent.btn {
  background: transparent;
  color: #888;
}
